import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthAdapter } from '../../core/auth/auth.adapter';
import { AuthSession } from '../../core/auth/types';
import firebase from 'firebase/app';
import { ColdObservable } from '../../core/types';
import FirebaseUser = firebase.User;


export class FirebaseAuthAdapter implements AuthAdapter {
  constructor(protected auth: firebase.auth.Auth) {}

  sessionChange(): ColdObservable<AuthSession> {
    return new Observable((subscriber) => {
      this.auth.onAuthStateChanged(
        (next) => subscriber.next(next),
        (err) => subscriber.error(err),
        () => subscriber.complete()
      );
    }).pipe(
      switchMap((firebaseUser: firebase.User) => this.convertFirebaseUserToSession(firebaseUser))
    );
  }

  async checkToken(token:string): Promise<AuthSession> {
    const userCredential = await this.auth.signInWithCustomToken(token);

    return this.convertFirebaseUserToSession(userCredential.user);
  }

  async signUpWithEmailAndPassword(email: string, password: string): Promise<AuthSession> {
    const userCredential = await this.auth.createUserWithEmailAndPassword(email, password);

    return this.convertFirebaseUserToSession(userCredential.user);
  }

  async loginWithEmailAndPassword(email: string, password: string): Promise<AuthSession> {
    const userCredential = await this.auth.signInWithEmailAndPassword(email, password);

    return this.convertFirebaseUserToSession(userCredential.user);
  }

  logout(): Promise<void> {
    return this.auth.signOut();
  }

  sendPasswordResetEmail(email: string): Promise<void> {
    return this.auth.sendPasswordResetEmail(email);
  }

  async reauthenticateWithPassword(email: string, password: string): Promise<AuthSession> {
    const credential = firebase.auth.EmailAuthProvider.credential(email, password);

    const userCredential = await this.auth.currentUser.reauthenticateWithCredential(credential);

    return this.convertFirebaseUserToSession(userCredential.user);
  }

  updatePassword(newPassword: string): Promise<void> {
    return this.auth.currentUser.updatePassword(newPassword);
  }

  withdraw(): Promise<void> {
    return this.auth.currentUser.delete();
  }

  private async convertFirebaseUserToSession(firebaseUser: FirebaseUser): Promise<AuthSession> {
    if (firebaseUser) {
      const idTokenResult = await firebaseUser.getIdTokenResult();

      return {
        expireAt: new Date(idTokenResult.expirationTime),
        accessToken: idTokenResult.token,
        refreshToken: firebaseUser.refreshToken,
        userId: firebaseUser.uid
      };
    } else {
      return null;
    }
  }
}
