import { BaseEntity } from '../base-entity';


export interface DbEntity extends BaseEntity {
  createdAt: Date;
  modifiedAt: Date;
  deletedAt?: Date;
}

export type DbFilterComparison = '>' | '>=' | '==' | '<=' | '<' | '!=' | 'array-contains' | 'text';

export interface DbQuery<E = any> {
  filters?: DbFilter<E>[];
  sorts?: DbSort<E>[];
  lt?: any;
  gt?: any;
  limit?: number;
}

export interface DbSort<E = any> {
  field: DbField<E>;
  direction: DbSortDirection;
}

export enum DbSortDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export interface DbFilter<E = any> {
  field: DbField<E>;
  comparison: DbFilterComparison;
  logical?: 'and' | 'or';
  value: any;
}

export type DbField<E> = keyof E & string;

export interface DbListResponse<E> {
  totalCount: number;
  count: number;
  size: number;
  cursor: string;
  items: E[];
  lastDoc?: any;
}

export interface DbFirestoreListResponse<E> {
  items: E[];
  totalCount: number;
  count: number;
  firstDoc: any;
  lastDoc: any;
}

export interface DbOptions {
  parentIds?: string[];
}

export interface DbBatchItems<E = any> {
  set?: DbBatchSet<E>[];
  update?: DbBatchUpdate<E>[];
  delete?: DbBatchDelete[];
}

export interface DbBatchSet<E> {
  id: string;
  data: E;
}

export interface DbBatchUpdate<E> {
  id: string;
  data: Partial<E>;
}

export interface DbBatchDelete {
  id: string;
}

export interface DbListRequest {
  size?: number;
  cursor?: string;
}
