import { Pipe, PipeTransform } from '@angular/core';
import { ItemPaymentType } from '../../../../../../src/entities/item/types';
import { RatioQuery } from '../../../../../web/src/app/modules/ntf/state/ratio.query';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'amountRatio'
})
export class AmountRatioPipe implements PipeTransform {
  constructor(private ratioQuery: RatioQuery) {}

  transform(value: number, paymentType: ItemPaymentType): Observable<number> {
    if (paymentType === ItemPaymentType.Won) {
      return this.ratioQuery.wonUsd$.pipe(
        map((ratio) => {
          return parseFloat((value / ratio).toFixed(2));
        })
      );
    } else if (paymentType === ItemPaymentType.Nik) {
      return this.ratioQuery.usdNik$.pipe(map((ratio) => ratio * value));
    } else {
      return of(value);
    }
  }
}
