import { DbQuery, DbListResponse, DbListRequest } from '../../core/db/types';
import { FirestoreDbAdapter } from '../../modules/firebase/firestore-db.adapter';
import { ItemService } from '../item/item.service';
import { Item } from '../item/types';
import { Wish } from './types';
import { PaginationList } from '../../core/types';
import { makeDbPaginationList } from '../../core/db/utils';
import firebaseAdapter from '../../modules/firebase/firebase.adapter';

export class WishService {
  constructor(protected wishDb: FirestoreDbAdapter<Wish>, protected itemService: ItemService) {}

  private static makeWishId(userId: string, itemId: string): string {
    return `${userId}_${itemId}`;
  }

  async checkWish(userId: string, itemId: string): Promise<boolean> {
    const wish = await this.wishDb.get(WishService.makeWishId(userId, itemId));

    return Boolean(wish);
  }

  wish(userId: string, itemId: string): Promise<boolean> {
    return this.wishDb.transaction((db) => async (transaction) => {
      const wishRef = db.doc(`wishes/${WishService.makeWishId(userId, itemId)}`);
      const itemRef = db.doc(`items/${itemId}`);

      const [wishSnap, itemSnap] = await Promise.all([
        transaction.get(wishRef),
        transaction.get(itemRef)
      ]);

      const currentCount = itemSnap.data().wishCount || 0;
      const series = itemSnap.data().series;
      let seriesRef;
      if (series) {
        seriesRef = db.doc(`itemSeriesCounts/${encodeURIComponent(series)}`);
      }

      const group = itemSnap.data().group;
      let itemGroupCountsRef;
      if (group) {
        itemGroupCountsRef = db.doc(`itemGroupCounts/${encodeURIComponent(group)}`);
      }

      if (wishSnap.exists) {
        transaction.delete(wishRef);
        transaction.update(itemRef, { wishCount: currentCount - 1 });

        if (seriesRef) {
          transaction.update(seriesRef, {
            order: firebaseAdapter.FieldValue.increment(-1),
            orderCount: firebaseAdapter.FieldValue.increment(-1)
          });
        }

        if (itemGroupCountsRef) {
          transaction.update(itemGroupCountsRef, {
            wishCount: firebaseAdapter.FieldValue.increment(-1)
          });
        }

        return false;
      } else {
        transaction.set(wishRef, { userId, itemId });
        transaction.update(itemRef, { wishCount: currentCount + 1 });

        if (seriesRef) {
          transaction.update(seriesRef, {
            order: firebaseAdapter.FieldValue.increment(1),
            orderCount: firebaseAdapter.FieldValue.increment(1)
          });
        }

        if (itemGroupCountsRef) {
          transaction.update(itemGroupCountsRef, {
            wishCount: firebaseAdapter.FieldValue.increment(1)
          });
        }

        return true;
      }
    });
  }

  async wishList(userId: string, request: DbListRequest = {}): Promise<DbListResponse<Item>> {
    const query: DbQuery = {
      filters: [{ field: 'userId', comparison: '==', value: userId }],
      // sorts: [{ field: 'createdAt', direction: DbSortDirection.Desc }],
      limit: request.size ? +request.size : null
    };

    if (request.cursor) {
      query.gt = await this.wishDb.getSnapshot(request.cursor);
    }

    const response = await this.wishDb.list(query);

    const items = await this.itemService.getMany(response.items.map((doc) => doc.itemId));

    return { ...response, items };
  }

  listWishedPaginationList(userId: string, request?: DbListRequest): PaginationList<Wish> {
    const query: DbQuery = {
      filters: [{ field: 'userId', comparison: '==', value: userId }],
      // sorts: [{ field: 'createdAt', direction: DbSortDirection.Desc }],
      limit: request.size ? +request.size : null
    };

    return makeDbPaginationList(this.wishDb, query);

    // const items = await this.itemService.getMany(response.items.map((doc) => doc.itemId));
    //
    // return { ...response, items };
  }
}
