import { Component, OnInit } from '@angular/core';
import { NgNotificationService } from '../../../../../../../nikplace/src/lib/modules/notification/services/notification.service';
import { SubscriptionBaseComponent } from '../../../../../../../nikplace/src/lib/core/base-components/subscription-base.component';
import { InfinityList } from '../../../../../../../../src/core/types';
import { tap } from 'rxjs/operators';
import {
  Notification,
  NotificationType,
} from '../../../../../../../../src/entities/notification/types';
import { NgAuthService } from '../../../../../../../nikplace/src/lib/core/auth/services/auth.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { Router } from '@angular/router';
import { SaleType } from '../../../../../../../../src/entities/item/types';

@Component({
  selector: 'nik-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
})
export class NotificationListComponent extends SubscriptionBaseComponent implements OnInit {
  infinityList: InfinityList<Notification>;
  notifications: Notification[];
  hasMore: boolean;
  isLoading = true;

  type = NotificationType;
  saleType = SaleType;

  constructor(
    private notificationService: NgNotificationService,
    private authService: NgAuthService,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscription.add(this.initAuth());
  }

  movePage(notification: Notification) {
    let url: string;
    switch (notification.type) {
      case NotificationType.Bid:
        url = `/my/bid`;
        break;

      case NotificationType.WinningBid:
        url = `/my/nft`;
        break;

      case NotificationType.Wish:
        url = `/item/${notification.info.itemId}`;
        break;

      case NotificationType.Ordered:
        url = `/my/nft`;
        break;

      case NotificationType.Transferred:
        url = `/my/charge-history`;
        break;

      case NotificationType.Charged:
        url = `/my/charge-history`;
        break;

      case NotificationType.Paypal:
        url = `/my/charge-history`;
        break;
    }

    this.router.navigate([url]).then();
  }

  private initAuth(): Subscription {
    return this.authService.userOnce.subscribe((user) => this.initDocs(user.id));
  }

  private initDocs(userId: string): void {
    this.infinityList = this.notificationService.myList(userId);

    this.setObservable('has-more', 'hasMore', this.infinityList.hasMoreChange);
    this.setObservable(
      'notifications',
      'notifications',
      this.infinityList.valueChange.pipe(
        tap((data) => {
          this.isLoading = false;
        })
      )
    );
  }
}
