<footer id="footer" [class.isMain]="isMainPage$ | async">

  <div class="inner-box">

    <div 
      class="main_footer_area"
      fxLayout="row"
      fxLayoutAlign="space-between stretch">

      <div class="company_area">
        <div class="company_logo">NIKPROTOCOL</div>
        <div class="company_info">
          <a href="mailto:'nikprotocols@gmail.com'">nikprotocols@gmail.com</a>
        </div>
      </div>
    </div>

    <div 
      class="etc_footer_area"
      fxLayout="row"
      fxLayoutAlign="space-between center">

      <div class="license">
        <i class="xi-copyright"></i>
        All Rights Reserved, NIKPROTOCOL 
      </div>

      <!-- <div>nikprotocol@gmail.com</div> -->

    </div>
 
  </div>

</footer>
