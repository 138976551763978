import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { BlurOnClickDirective } from './directives/blur-on-click.directive';
import { StopPropagationDirective } from './directives/stop-propagation.directive';
import { NikEllipsisPipe } from './pipes/ellipsis.pipe';
import { NikSrcDirective } from './directives/src.directive';
import { NikCoverDirective } from './directives/cover.directive';
import { BackDirective } from './directives/back.directive';
import { FromNowPipe } from './pipes/from-now.pipe';
import { DepositDateLimitPipe } from './pipes/deposit-date-limit.pipe';
import { AmountRatioPipe } from './pipes/amount-ratio.pipe';
import {AmountMultiRatioPipe} from "./pipes/amount-multi-ratio.pipe";
import { StripTagPipe } from './pipes/strip-tag.pipe';
import { StyleBackgroundImagePipe } from './pipes/style-background-image.pipe';
import { EncodeUrlPipe } from './pipes/encode-url.pipe';

@NgModule({
  declarations: [
    SafeHtmlPipe,
    BlurOnClickDirective,
    StopPropagationDirective,
    NikEllipsisPipe,
    NikSrcDirective,
    NikCoverDirective,
    BackDirective,
    FromNowPipe,
    DepositDateLimitPipe,
    AmountRatioPipe,
    AmountMultiRatioPipe,
    StripTagPipe,
    StyleBackgroundImagePipe,
    EncodeUrlPipe
  ],
  imports: [CommonModule, ReactiveFormsModule, FlexModule],
  exports: [
    SafeHtmlPipe,
    BlurOnClickDirective,
    StopPropagationDirective,
    NikEllipsisPipe,
    NikSrcDirective,
    NikCoverDirective,
    BackDirective,
    FromNowPipe,
    DepositDateLimitPipe,
    AmountRatioPipe,
    AmountMultiRatioPipe,
    StripTagPipe,
    StyleBackgroundImagePipe,
    EncodeUrlPipe
  ]
})
export class NikSharedModule {}
