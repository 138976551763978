import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { RatioStore, RatioState } from './ratio.store';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RatioQuery extends Query<RatioState> {
  usdNik$: Observable<number> = this.store._select((state) => state.usdNik);
  wonUsd$: Observable<number> = this.store._select((state) => state.wonUsd);
  wonNik$: Observable<number> = this.store._select((state) => state.wonNik);

  constructor(protected store: RatioStore) {
    super(store);
  }
}
