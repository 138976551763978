import firebase from 'firebase/app';
import { from, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ApiAdapter } from '../../core/api/api.adapter';
import { ApiOptions, ApiResponse } from '../../core/api/types';

export class FunctionsApiAdapter implements ApiAdapter {
  constructor(protected functions: firebase.functions.Functions) {}

  get<T = any>(path: string, options?: ApiOptions): Promise<ApiResponse<T>> {
    throw new Error('사용할 수 없습니다');
  }

  post<T = any, D = any>(path: string, data: D, options?: ApiOptions): Promise<ApiResponse<T>> {
    return from(this.functions.httpsCallable(path)(data))
      .pipe(
        map((response) => {
          return {
            statusCode: 200,
            data: response.data,
          };
        }),
        catchError((err) => {
          return throwError({
            statusCode: 500,
            message: err.message,
            data: null,
          });
        })
      )
      .toPromise();
  }

  put<T = any, D = any>(path: string, data: D, options?: ApiOptions): Promise<ApiResponse<T>> {
    throw new Error('사용할 수 없습니다');
  }

  delete<T = any>(path: string, options?: ApiOptions): Promise<ApiResponse<T>> {
    throw new Error('사용할 수 없습니다');
  }
}
