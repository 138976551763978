import { Pipe, PipeTransform } from '@angular/core';
import { ItemStatus, ItemStatusClass } from '../../../../../../src/entities/item/types';

@Pipe({
  name: 'itemStatusClass',
})
export class ItemStatusClassPipe implements PipeTransform {
  transform(status: ItemStatus): string {
    if (!status) {
      return '';
    }
    return ItemStatusClass[status];
  }
}
