import { Component, OnInit } from '@angular/core';
import {
  SubscriptionBaseComponent
} from '../../../../../../nikplace/src/lib/core/base-components/subscription-base.component';
import { User } from '../../../../../../../src/core/user/types';
import { NgAuthService } from '../../../../../../nikplace/src/lib/core/auth/services/auth.service';
import qrcode from 'qrcode';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';
import { makeUserLevelText } from '../../../../../../../src/core/user/utils';

@Component({
  selector: 'nik-nikholder-dialog',
  templateUrl: './nikholder-dialog.component.html',
  styleUrls: ['./nikholder-dialog.component.scss']
})
export class NikholderDialogComponent extends SubscriptionBaseComponent implements OnInit {
  user: User;
  qrCodeDataUrl: string;

  makeUserLevelText = makeUserLevelText;

  constructor(
    private authService: NgAuthService,
    private clipboard: Clipboard,
    private snackBar: MatSnackBar
  ) {
    super();
  }

  ngOnInit(): void {
    this.addSubscription(this.initUser());
    this.initObservable('user', this.authService.user$);
  }

  makeProfilePictureUrl(profilePictureUrl: string) {
    if (profilePictureUrl) {
      return `url(${profilePictureUrl})`;
    } else {
      return `url(/assets/icons/no-user.png)`;
    }
  }

  copyAddress() {
    this.clipboard.copy(this.user.klaytnAccount.address);

    this.snackBar.open('QR 복사 완료', '', { duration: 3000, panelClass: 'info' });
  }

  copyId() {
    this.clipboard.copy(this.user.id);

    alert('클립보드에 복사되었습니다.');
  }

  private setQrCode(address: string): void {
    qrcode.toDataURL(address).then((result) => {
      this.qrCodeDataUrl = result;
    });
  }

  private initUser() {
    return this.authService.user$.subscribe(user => {
      this.user = user;
      this.setQrCode(user.klaytnAccount.address);
    });
  }
}
