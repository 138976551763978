import { Injectable } from '@angular/core';
import { BidService } from '../../../../../../../src/entities/bid/bid.service';
import { NgItemService } from '../../item/services/item.service';
import { NgBidDb } from './bid.db';
import { NgUserService } from '../../../core/user/services/user.service';
import { NgKlaytnService } from '../../klaytn/services/klaytn.service';

@Injectable({
  providedIn: 'root'
})
export class NgBidService extends BidService {
  constructor(
    protected bidDb: NgBidDb,
    protected itemService: NgItemService,
    protected userService: NgUserService,
    protected klaytnService: NgKlaytnService
  ) {
    super(bidDb, itemService, userService, klaytnService);
  }
}
