import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationListComponent } from './components/notification-list/notification-list.component';
import { NikSharedModule } from '../../../../../nikplace/src/lib/shared/shared.module';
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  declarations: [NotificationListComponent],
  exports: [NotificationListComponent],
    imports: [CommonModule, NikSharedModule, TranslateModule],
})
export class NotificationModule {}
