import { Injectable } from '@angular/core';
import { NgNotificationDb } from './notification.db';
import { NotificationService } from '../../../../../../../src/entities/notification/notification.service';

@Injectable({
  providedIn: 'root',
})
export class NgNotificationService extends NotificationService {
  constructor(protected notificationDb: NgNotificationDb) {
    super(notificationDb);
  }
}
