import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableAkitaProdMode } from '@datorama/akita';
import firebaseAdapter from '../../../src/modules/firebase/firebase.adapter';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  enableAkitaProdMode();
}

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/analytics';

firebase.initializeApp(environment.firebase);

firebaseAdapter.init({
  auth: firebase.auth() as any,
  firestore: firebase.firestore() as any,
  analytics: firebase.analytics(),
  FieldValue: firebase.firestore.FieldValue,
  Timestamp: firebase.firestore.Timestamp,
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
