import { convertEnumToArray, convertUtf8ToHex, pad } from '../../core/utils';
import {
  Item,
  ItemFilterType,
  ItemGroupCount,
  ItemNikSeries,
  ItemPaymentType,
  ItemSort,
  ItemStatus,
  SaleType
} from './types';
import { SearchFilter } from '../../core/search/types';
import { WonExchange, WonExchangeStatus } from '../won-exchange/types';
import { HashMap } from '../../core/types';

export function makeTokenId(itemId: string): string {
  return `0x${convertUtf8ToHex(itemId)}`;
}

export function makeMultiTokenId(item: Item): string {
  return `0x${convertUtf8ToHex(`${item.id}${item.createdAt.getMilliseconds()}`)}`;
}

export function checkEdition(item: Item): boolean {
  return !item.hasOwnership && item.isEdition;
}

export function checkPart(item: Item): boolean {
  return item.hasOwnership && item.isEdition && item.totalPieces > 1;
}

export function checkSellingPart(item: Item): boolean {
  return item.saleByNikplace && item.isEdition && item.totalPieces > 1;
}

export function makeTimer(endDate: Date): string {
  const now = Date.now();
  const diff = endDate.getTime() - now;

  if (diff > 0) {
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diff % (1000 * 60)) / 1000);

    if (days > 0) {
      return `${days} ${pad(hours, 2)}:${pad(minutes, 2)}:${pad(seconds, 2)}`;
    } else {
      return `${pad(hours, 2)}:${pad(minutes, 2)}:${pad(seconds, 2)}`;
    }
  } else {
    return null;
  }
}

export function makeItemName(item: Item): string {
  if (item) {
    if (item.totalPieces > 1 && item.lastSoldAt && item.auctionStartAt) {
      return `${item.name} #${item.currentPieceNumber}/${item.totalPieces}`;
    } else {
      return item.name;
    }
  }

  return null;
}

export function makeItemStatusText(item: Item): string {
  switch (item.status) {
    case ItemStatus.Ready:
      return '준비';
    case ItemStatus.Bidding:
      return '경매 중';
    case ItemStatus.OnSale:
      return '판매 중';
    case ItemStatus.WinningBid:
      return '낙찰';
    case ItemStatus.FailureBid:
      return '유찰';
    case ItemStatus.DepositWaiting:
      return '입금 대기 중';
    case ItemStatus.Disabled:
      return '비공개';
  }
  return '';
}

export function makeWonExchangeStatusText(wonExchange: WonExchange): string {
  switch (wonExchange.status) {
    case WonExchangeStatus.Requested:
      return '요청';
    case WonExchangeStatus.Completed:
      return '완료';
    case WonExchangeStatus.Rejected:
      return '거절';
    case WonExchangeStatus.Canceled:
      return '취소';
  }
  return '';
}

export function convertTypeToFilter(
  filterType: ItemFilterType,
  seriesArray: string[],
  isGold?: boolean
): {
  filterType: ItemFilterType;
  filters: SearchFilter[];
  isDistinct: boolean;
  query: string;
} {
  const { type, category, product, subProduct, piece, status, series, currency, id, query } = filterType;

  const filters: SearchFilter[] = [
    { field: 'hidden', comparison: '!=', value: true },
    { field: 'isDeleted', comparison: '!=', value: true }
  ];

  let isDistinct = false;

  if (type === 'item') {
    filterType.type = 'item';
  } else if (type === 'collection') {
    filterType.type = 'collection';

    filters.push({ field: 'series', value: id });
  } else if (type === 'gallery') {
    filterType.type = type;

    filters.push({ field: 'galleryId', value: id });
  } else if (type === 'artist') {
    filterType.type = type;

    filters.push({ field: 'artistId', value: id });
  } else if (type === 'vip') {
    filterType.type = type;

    filters.push({ field: 'isForVip', value: true });
  }

  if (type !== 'vip' && !isGold) {
    filters.push({ field: 'isForVip', comparison: '!=', value: true });
  }

  if (category === 'buy') {
    filterType.category = 'buy';
    isDistinct = true;

    filters.push({ field: 'saleByNikplace', value: true });
  } else if (category === 'auction') {
    filterType.category = 'auction';
    isDistinct = true;

    filters.push({ field: 'saleByNikplace', value: false });
  }

  const splitProduct = product ? product.split(',') : [];
  const splitSubProduct = subProduct ? subProduct.split(',') : [];
  const splitStatus = status ? status.split(',') : [];
  const splitPiece = piece ? piece.split(',') : [];
  const splitSeries = series ? series.split(',') : [];
  const splitCurrency = currency ? currency.split(',') : [];

  if (splitStatus.length > 0) {
    if (splitStatus.length === 2) {
    } else if (splitStatus.indexOf('on-sale') > -1) {
      if (category === 'auction' || filterType.category === 'auction') {
        filters.push({ field: 'status', value: ItemStatus.Bidding });
      } else {
        filters.push({ field: 'status', value: ItemStatus.OnSale });
      }
    } else if (splitStatus.indexOf('end-of-sale') > -1) {
      filters.push({ field: 'status', value: ItemStatus.Ready });

      if (category === 'buy' && splitPiece.length === 1 && splitPiece.indexOf('edition') > -1) {
        filters.push({ field: 'allSoldOut', value: true });
      }
    }
  } else {
  }

  if (splitProduct.length > 0) {
    const innerMap: HashMap<SearchFilter> = {
    
      'real-asset': { field: 'hasOwnership', value: true  },
      'digital-asset': { field: 'hasOwnership', value: false },
      'want-card': { logical: 'or', field: 'isWantCard', value: true },
      'participation': { logical: 'or', field: 'isParticipatory', value: true }
/*    
      digital: { logical: 'or', field: 'hasOwnership', value: false },
      'real-painting': { logical: 'or', field: 'hasOwnership', value: true },
      'want-card': { logical: 'or', field: 'isWantCard', value: true },
      participation: { logical: 'or', field: 'isParticipatory', value: true }
*/    
    };

    const innerMap2: HashMap<SearchFilter> = {
      'real-painting': { },
      'real-figure': { logical: 'and',  field: 'assetType', value: 'real-figure' },
      'real-goods': { logical: 'and', field: 'assetType', value: 'real-goods' },
      'real-etc': { logical: 'and', field: 'assetType', value: 'real-etc' },
      'digital-collection': { },
      'digital-etc': { logical: 'and', field: 'assetType', value: 'digital-etc' },
    };

    const innerFilters: SearchFilter[] = [{ parenthesis: 'start' }];

    for (const s of splitProduct) {
      innerFilters.push(innerMap[s]);
    }

    if (splitSubProduct.length > 0) {
      for (const k of splitSubProduct) {
        if(k == 'real-painting'){ // assetType이 지정되지 않은 real-painting 
          innerFilters.push({ parenthesis: 'start' });
          innerFilters.push({ comparison:'!=', logical: 'and', field: 'assetType', value: 'real-figure'  });
          innerFilters.push({ comparison:'!=', logical: 'and', field: 'assetType', value: 'real-goods'  });
          innerFilters.push({ comparison:'!=', logical: 'and', field: 'assetType', value: 'real-etc'  });
          innerFilters.push({ parenthesis: 'end' });
        }
        if(k == 'digital-collection'){  // assetType이 지정되지 않은 digital
          innerFilters.push({ parenthesis: 'start' });
          innerFilters.push({ comparison:'!=', logical: 'and', field: 'assetType', value: 'digital-etc'  });
          innerFilters.push({ parenthesis: 'end' });
        }
        innerFilters.push(innerMap2[k]);
      }
    }

    innerFilters.push({ parenthesis: 'end' });

    filters.push(...innerFilters);

    if (splitProduct.indexOf('participation') === -1) {
      filters.push({ field: 'isParticipatory', value: false });
    }
  }

  if (splitPiece.length > 0) {
    if (splitPiece.length === 2) {
    } else if (splitPiece.indexOf('only-one') > -1) {
      filters.push({ field: 'totalPieces', value: 1 });
    } else if (splitPiece.indexOf('edition') > -1) {
      filters.push({ field: 'totalPieces', comparison: '>=', value: 2 });
    }
  } else {
  }

  if (splitProduct.indexOf('digital') > -1) {
    if (splitSeries.length > 0 && splitSeries.length !== seriesArray.length) {
      const seriesMap: HashMap<SearchFilter> = {};

      for (const s of seriesArray) {
        seriesMap[s] = { logical: 'or', field: 'series', value: s };
      }

      const seriesFilters: SearchFilter[] = [{ parenthesis: 'start' }];

      for (const s of splitSeries) {
        seriesFilters.push(seriesMap[s]);
      }

      seriesFilters.push({ parenthesis: 'end' });

      filters.push(...seriesFilters);
    }
  }


  if (splitCurrency.length > 0) {
    const innerMap: HashMap<SearchFilter[]> = {
      nik: [{ logical: 'or', field: 'paymentType', value: 'nik' }],
      won: [
        { logical: 'or', field: 'paymentType', value: 'won' },
        { logical: 'or', field: 'paymentType', value: 'inquiry' }
      ],
      usd: [{ logical: 'or', field: 'paymentType', value: 'usd' }]
    };

    const innerFilters: SearchFilter[] = [{ parenthesis: 'start' }];

    for (const s of splitCurrency) {
      innerFilters.push(...innerMap[s]);
    }

    innerFilters.push({ parenthesis: 'end' });

    filters.push(...innerFilters);
  }

  return { filterType, filters, isDistinct, query };
}

export function makePaymentTypeIcon(paymentType: ItemPaymentType) {
  if (paymentType === ItemPaymentType.Won) {
    return '₩';
  } else if (paymentType === ItemPaymentType.Usd) {
    return '$';
  } else if (paymentType === ItemPaymentType.Nik) {
    return '<i class="nik-amount-icon"></i>';
  }

  return null;
}

export function makeItemAmount(item: Item): number {
  if (item) {
    if (item.status === ItemStatus.Bidding) {
      return +(item.highestBidAmount || item.auctionStartAmount || item.amount);
    }

    return +item.amount;
  }

  return 0;
}

export function makeItemGroupCountAmount(item: ItemGroupCount): number {
  if (item) {
    return +item.amount;
  }

  return 0;
}

export function getImmediateAmount(item: Item): number {
  if (item) {
    if (item.saleType === SaleType.Auction) {
      return +item.auctionImmediateAmount;
    } else {
      return +item.amount;
    }
  }

  return 0;
}

export function makeThumbImageUrl(item: Item): string {
  if (item) {
    if (item.thumbImageUrl) {
      return item.thumbImageUrl.replace("'", "\\'");
    } else {
      return item.imageUrl.replace("'", "\\'");
    }
  } else {
    return '/assets/images/nft-card-default.jpg';
  }
}

export function makeThumbUrlGroup(item: ItemGroupCount): string {
  if (item) {
    if (item.thumbImageUrl) {
      return item.thumbImageUrl.replace("'", "\\'");
    }
  }
  return '/assets/images/nft-card-default.jpg';
}

export function convertQueryParamsSortToItemSort(field: string, direction: string): ItemSort {
  if (
    (field === 'createdAt' && direction === 'desc') ||
    (field === 'auctionStartAt' && direction === 'desc')
  ) {
    return ItemSort.New;
  } else if (
    (field === 'createdAt' && direction === 'asc') ||
    (field === 'auctionStartAt' && direction === 'asc')
  ) {
    return ItemSort.Old;
  } else if (
    (field === 'amount' && direction === 'asc') ||
    (field === 'highestBidAmount' && direction === 'asc')
  ) {
    return ItemSort.Low;
  } else if (
    (field === 'amount' && direction === 'desc') ||
    (field === 'highestBidAmount' && direction === 'desc')
  ) {
    return ItemSort.High;
  } else if (field === 'sold' && direction === 'desc') {
    return ItemSort.Sold;
  } else if (field === null || field === 'null') {
    return ItemSort.Wish;
  }

  return null;
}

export function makeItemSeriesText(series: ItemNikSeries) {
  switch (series) {
    case ItemNikSeries.PAUL_NIK:
      return 'PAULNIK';
    case ItemNikSeries.HOL_NIK:
      return 'HOLNIK';
    case ItemNikSeries.BEAR_NIK:
      return 'BEARNIK';
    case ItemNikSeries.MONKEY_NIK:
      return 'MONKEYNIK';
    case ItemNikSeries.EXCLUSIVE_ARTIST:
      return 'EXCLUSIVE ARTIST';
    case ItemNikSeries.BELLYGOM:
      return 'BELLYGOM';
    case ItemNikSeries.MR_BLANK:
      return 'MR.BLANK';
    case ItemNikSeries.HONG_GILDONG_CAT:
      return 'HONG GILDONG CAT';
    case ItemNikSeries.BBIGGU:
      return 'BBIGGU';
    case ItemNikSeries.RUN_HANY:
      return 'RUN HANY';
  }
}

export function getItemSeries(): ItemNikSeries[] {
  return convertEnumToArray(ItemNikSeries).filter((s) => s !== ItemNikSeries.PAUL_NIK);
}
