import { Injectable } from '@angular/core';
import { Bid } from '../../../../../../../src/entities/bid/types';
import { FirestoreDbAdapter } from '../../../../../../../src/modules/firebase/firestore-db.adapter';

@Injectable({
  providedIn: 'root'
})
export class NgBidDb extends FirestoreDbAdapter<Bid> {
  constructor() {
    super('bids');
  }
}
