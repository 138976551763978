import { Injectable } from '@angular/core';
import { WishService } from '../../../../../../../src/entities/wish/wish.service';
import { NgItemService } from '../../item/services/item.service';
import { NgWishDb } from './wish.db';

@Injectable({
  providedIn: 'root'
})
export class NgWishService extends WishService {
  constructor(
    protected wishDb: NgWishDb,
    protected itemService: NgItemService
  ) {
    super(wishDb, itemService);
  }
}
