export interface KlaytnAccount {
  address: string;
  chainId: number;
  createdAt: number; // unix timestamp
  keyId: string;
  krn: string;
  publicKey: string;
  updatedAt: number; // unix timestamp
  multiSigKeys?: KlaytnMultisigKey[];
  threshold?: string;
}

export interface KlaytnMultisigKey {
  publicKey: string;
  weight: number;
}

export interface KlaytnKip17TransactionStatusResponse {
  status: string;
  transactionHash: string;
}

export interface KlaytnGetKip17TokenResponse {
  createdAt: number;
  owner: string;
  previousOwner: string;
  tokenId: string;
  tokenUri: string;
  transactionHash: string;
  updatedAt: number;
}

export interface KlaytnGetOwnerKip17TokensResponse {
  cursor: string;
  items: KlaytnGetOwnerKip17TokensResponseItem[];
}

export interface KlaytnGetOwnerKip17TokensResponseItem {
  createdAt: number; // unix timestamp
  owner: string;
  previousOwner: string;
  tokenId: string;
  tokenUri: string;
  transactionHash: string;
  updatedAt: number; // unix timestamp
}

export interface KlaytnGetKip17TokenHistoryResponse {
  cursor: string;
  items: KlaytnGetKip17TokenHistoryResponseItem[];
}

export interface KlaytnGetKip17TokenHistoryResponseItem {
  from: string;
  timestamp: number; // unix timestamp
  to: string;
}

export interface KlaytnKip17QueryOptions {
  size?: number;
  cursor?: string;
}

export interface KlaytnKip7TransactionStatusResponse {
  status: string;
  transactionHash: string;
}

export interface KlaytnKip7TokenBalanceResponse {
  balance: string;
  decimals: number;
}


export interface KlaytnKip37TransactionStatusResponse {
  status: string;
  transactionHash: string;
  transactionId: string;
}


export interface KlaytnInput {
  type: KlaytnInputType;
  name: string;
}

export enum KlaytnInputType {
  Address = 'address',
  Uint256 = 'uint256',
  Uint32 = 'uint32',
  String = 'string'
}
