import { Inject, Injectable } from '@angular/core';
import { FrontendBigquerySearchAdapter } from '../../../../../../../src/modules/google/frontend-bigquery-search.adapter';
import { Item } from '../../../../../../../src/entities/item/types';
import { Environment } from '../../../../../../../src/core/environment';
import { NgItemDb } from './item.db';
import { NgFrontendApiService } from '../../../core/services/frontend-api.service';
import { ENVIRONMENT } from '../../../core/tokens';

@Injectable({
  providedIn: 'root',
})
export class NgItemBigSearch extends FrontendBigquerySearchAdapter<Item> {
  constructor(
    @Inject(ENVIRONMENT) private environment: Environment,
    protected frontendApiService: NgFrontendApiService,
    protected itemDb: NgItemDb
  ) {
    super(
      `${environment.firebase.projectId}.firestore_export.items_schema_schema_20220726_1_latest`, frontendApiService, itemDb
    );
  }
}
