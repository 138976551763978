import { Component, OnInit, Inject, Injector } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DmDialogComponentOptions, NIK_DIALOG_DATA } from '../../types';

@Component({
  selector: 'nik-message-dialog',
  templateUrl: './custom-component-dialog.component.html',
  styleUrls: ['./custom-component-dialog.component.scss']
})
export class CustomComponentDialogComponent implements OnInit {
  component = this.data.component;
  confirmButtonText = this.data.confirmButtonText;
  cancelButtonText = this.data.cancelButtonText;

  dataInject;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: DmDialogComponentOptions,
    private injector: Injector
  ) {
    this.dataInject = Injector.create({
      providers: [
        {
          provide: NIK_DIALOG_DATA, useValue: this.data.data
        }
      ], parent: this.injector
    });
  }

  ngOnInit(): void {
  }

}
