import { Injectable } from '@angular/core';
import { UserService } from '../../../../../../../src/core/user/user.service';
import { NgUserDb } from './user.db';
import { NgUserBigSearch } from './user.big-search';
import { NgUserSearch } from './user.search';

@Injectable({
  providedIn: 'root',
})
export class NgUserService extends UserService {
  constructor(
    protected userDb: NgUserDb,
    protected userSearch: NgUserBigSearch,
    protected userSearchByAlgolia: NgUserSearch
  ) {
    super(userDb, userSearch, userSearchByAlgolia);
  }
}
