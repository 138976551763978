import { Pipe, PipeTransform } from '@angular/core';
import {
  NikExchangeStatus,
  NikExchangeStatusText,
} from '../../../../../../src/entities/nik-exchange/types';

@Pipe({
  name: 'nikExchangeStatusFormat',
})
export class NikExchangeStatusFormatPipe implements PipeTransform {
  transform(status: NikExchangeStatus): string {
    if (!status) {
      return '';
    }
    return NikExchangeStatusText[status];
  }
}
