import { DbAdapter } from '../../core/db/db.adapter';
import { DbFilter, DbQuery, DbSortDirection } from '../../core/db/types';
import { Notification, NotificationType } from './types';
import { InfinityList } from '../../core/types';
import { makeDbInfinityList } from '../../core/utils';
import { Item } from '../item/types';

export class NotificationService {
  constructor(protected notificationDb: DbAdapter<Notification>) {}

  add(userId: string, type: any, info: Notification['info']): Promise<Notification> {
    return this.notificationDb.add(
      { type, info: info as any, unread: true },
      { parentIds: [userId] }
    );
  }

  myList(userId: string): InfinityList<Notification> {
    return makeDbInfinityList(
      this.notificationDb,
      {
        sorts: [{ field: 'createdAt', direction: DbSortDirection.Desc }],
      },
      { parentIds: [userId] }
    ) as any;
  }

  async countNotificationUnread(userId: string): Promise<number> {
    return this.notificationDb.count(
      {
        filters: [{ field: 'unread', comparison: '==', value: true }],
      },
      { parentIds: [userId] }
    );
  }

  async list(userId: string, onlyUnread = false): Promise<Notification[]> {
    const query: DbQuery = {
      sorts: [{ field: 'createdAt', direction: DbSortDirection.Desc }],
      limit: 10,
    };

    if (onlyUnread) {
      query.filters.push({ field: 'unread', comparison: '==', value: true });
    }

    const response = await this.notificationDb.list(query, { parentIds: [userId] });

    return response.items;
  }

  readMany(userId: string, notificationIds: string[]): Promise<void> {
    return this.notificationDb.batch(
      { update: notificationIds.map((id) => ({ id, data: { unread: false } })) },
      { parentIds: [userId] }
    );
  }

  async readAll(userId: string): Promise<void> {
    const filters: DbFilter[] = [{ field: 'unread', comparison: '==', value: true }];

    const response = await this.notificationDb.list({ filters }, { parentIds: [userId] });

    return this.readMany(
      userId,
      response.items.map((doc) => doc.id)
    );
  }

  addWish(
    ownerId: string,
    userId: string,
    name: string,
    nickname: string,
    itemId: string
  ): Promise<any> {
    return this.add(ownerId, NotificationType.Wish, {
      userId,
      name,
      nickname: nickname || '',
      itemId,
    });
  }

  addCharged(userId: string, nikAmount: number): Promise<any> {
    return this.add(userId, NotificationType.Charged, { userId, nikAmount });
  }

  addExchanged(userId: string, nikAmount: number): Promise<any> {
    return this.add(userId, NotificationType.Exchanged, { userId, nikAmount });
  }

  addTransferred(userId: string, endDate: Date): Promise<any> {
    return this.add(userId, NotificationType.Transferred, {
      endDate,
    });
  }

  addOrdered(userId: string, items: Item[]): Promise<any> {
    return this.add(userId, NotificationType.Ordered, {
      items,
    });
  }

  addPaypal(userId: string, amount: string): Promise<any> {
    return this.add(userId, NotificationType.Paypal, {
      amount,
    });
  }

  addBid(userId: string, item: Item, amount: number, userName: string): Promise<any> {
    return this.add(userId, NotificationType.Bid, {
      item,
      amount,
      userName,
    });
  }

  addWinningBid(userId: string, item: Item, amount: number, userName: string): Promise<any> {
    return this.add(userId, NotificationType.WinningBid, {
      item,
      amount,
      userName,
    });
  }
}
