import { Injectable, NgModule } from '@angular/core';
import { PreloadingStrategy, Route, RouterModule, Routes } from '@angular/router';
import { LayoutPageComponent } from './core/components/layout-page/layout-page.component';
import { AuthGuard } from '../../../../src/core/guard/auth.guard';
import { Observable, of } from 'rxjs';
import { RoadMapPageModule } from './pages/road-map-page/road-map-page.module';

const routes: Routes = [
  {
    path: '',
    component: LayoutPageComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'main' },
      {
        path: 'main',
        loadChildren: () =>
          import('./pages/main-page/main-page.module').then((m) => m.MainPageModule)
      }
    ]
  }
];

@Injectable({
  providedIn: 'root'
})
export class CustomPreloadingStrategy implements PreloadingStrategy {
  preload(route: Route, load: () => Observable<any>): Observable<any> {
    if (route.data && route.data.preload) {
      return load();
    } else {
      return of(null);
    }
  }
}

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: CustomPreloadingStrategy
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
