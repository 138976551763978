<section class='custom-component-dialog-section'>
  <div class='custom-component-dialog-box'>
    <ng-container *ngComponentOutlet='component; injector: dataInject'
    ></ng-container>
  </div>

  <div
    *ngIf='confirmButtonText || cancelButtonText'
    class='dialog-button-box'
  >
<!--    <bm-button-->
<!--      *ngIf='cancelButtonText'-->
<!--      class='dialogs-button'-->
<!--      type='button'-->
<!--      [matDialogClose]='false'-->
<!--    >{{ cancelButtonText }}</bm-button>-->

<!--    <bm-button-->
<!--      *ngIf='confirmButtonText'-->
<!--      class='dialogs-button confirm-button'-->
<!--      type='button'-->
<!--      [matDialogClose]='true'-->
<!--    >-->
<!--      {{ confirmButtonText }}-->
<!--    </bm-button>-->
  </div>
</section>
