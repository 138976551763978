import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { SubscriptionBaseComponent } from '../../../../../../nikplace/src/lib/core/base-components/subscription-base.component';
import { checkApp } from '../../../../../../../src/core/utils';
import { Router } from '@angular/router';

@Component({
  selector: 'nik-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends SubscriptionBaseComponent implements OnInit {

  isMainPage$ = new BehaviorSubject<boolean>(false);
  
  languageControl = this.createLanguageControl();
  isApp = checkApp();

  constructor(
    private fb: FormBuilder, 
    private translateService: TranslateService,
    private router: Router
    ) {
    super();
    this.checkMainPage(this.router.url);
  }

  ngOnInit(): void {
    this.setSubscription('initLanguageChange', this.initLanguageChange());
    this.checkMainPage(this.router.url);
  }

  private initLanguageChange(): Subscription {
    return this.languageControl.valueChanges.subscribe((value) => {
      this.translateService.use(value);
      window.scrollTo(0,0);
    });
  }

  private createLanguageControl(): FormControl {
    return this.fb.control('en', [Validators.required]);
  }

  private checkMainPage(url: string) {
    this.isMainPage$.next(url.indexOf('/main') !== -1);
  }
}
