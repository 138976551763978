import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface RatioState {
  usdNik: number;
  wonUsd: number;
  wonNik: number;
}

export function createInitialState(): RatioState {
  return {
    usdNik: 0,
    wonUsd: 0,
    wonNik: 0
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'ratio' })
export class RatioStore extends Store<RatioState> {
  constructor() {
    super(createInitialState());
  }
}
