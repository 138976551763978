import { ComponentType } from '@angular/cdk/overlay';
import { Injectable, Type } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { CustomComponentDialogComponent } from '../components/custom-component-dialog/custom-component-dialog.component';
import { MessageDialogComponent } from '../components/message-dialog/message-dialog.component';
import { DmDialogMessageOptions, DmDialogComponentOptions, NikDialogOptions } from '../types';
import { PostcodeData } from '../../../../../../nikplace/src/lib/modules/postcode/services/postcode.service';
import { DaumPostcodeDialogComponent } from '../components/daum-postcode-dialog/daum-postcode-dialog.component';
import { BottomUpDialogComponent } from '../components/bottom-up-dialog/bottom-up-dialog.component';
import { ConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class NgDialogService {
  constructor(
    private dialog: MatDialog,
    private translateService: TranslateService
  ) {}

  message: string;

  open<T, D = any, R = any>(
    component: ComponentType<T>,
    config?: MatDialogConfig<D>
  ): MatDialogRef<T, R> {
    return this.dialog.open<T, D, R>(component, config);
  }

  openProcessing(): MatDialogRef<MessageDialogComponent, string | boolean> {
   
    if(this.translateService.currentLang == "ko") this.message = "처리중입니다.....";
    else this.message = "in progress.....";

    return this.openMessage({
      title: '',
      message: this.message,
      closeDisabled: true
    });
  }

  openMessage(
    options: DmDialogMessageOptions
  ): MatDialogRef<MessageDialogComponent, string | boolean> {
    return this.dialog.open(MessageDialogComponent, {
      panelClass: ['message-dialog', options.panelClass],
      data: options,
      autoFocus: false,
      disableClose: true
    });
  }

  openComponent(
    options: DmDialogComponentOptions,
    panelClass?: string
  ): MatDialogRef<CustomComponentDialogComponent, any> {
    return this.dialog.open(CustomComponentDialogComponent, {
      panelClass: ['message-dialog', panelClass],
      data: options,
      autoFocus: false,
      maxWidth: '100vw'
    });
  }

  openPostcode(): MatDialogRef<DaumPostcodeDialogComponent, PostcodeData> {
    return this.dialog.open(DaumPostcodeDialogComponent, {
      panelClass: ['postcode-dialog-panel'],
      autoFocus: false
    });
  }

  openBottomUp<R = any>(
    component: Type<any>,
    options?: NikDialogOptions
  ): MatDialogRef<BottomUpDialogComponent, R> {
    return this.dialog.open(BottomUpDialogComponent, {
      panelClass: ['bottom-up-dialog', 'animate__animated', 'animate__fadeInUp'],
      maxWidth: '100vw',
      position: { bottom: '0' },
      data: { component, options },
      disableClose: options.disableClose !== false
    });
  }

  openConfirmDialog(options: DmDialogMessageOptions): MatDialogRef<ConfirmDialogComponent> {
    return this.dialog.open(ConfirmDialogComponent, {
      panelClass: ['confirm-dialog-panel'],
      data: options
    });
  }
}
