import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';


@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  transform(timestamp: any, format: string = 'YYYY-MM-DD'): any {
    if (!timestamp) {
      return '';
    }

    if (timestamp instanceof Date) {
      return moment(timestamp).format(format);
    }

    return moment(timestamp.toDate()).format(format);
  }
}
