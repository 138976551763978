import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DmDialogMessageOptions } from '../../types';

@Component({
  selector: 'nik-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  title: string;
  message: string;
  confirmButtonText: string;
  cancelButtonText: string;

  constructor(
    private dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: DmDialogMessageOptions
  ) {
    this.title = this.data.title;
    this.message = this.data.message;

    this.confirmButtonText = this.data.confirmButtonText || 'CONFIRM.CONFIRM';
    this.cancelButtonText = this.data.cancelButtonText || 'CONFIRM.CANCEL';
  }

  ngOnInit(): void {}

  cancel(): void {
    this.dialogRef.close('cancel');
  }

  confirm(): void {
    this.dialogRef.close('confirm');
  }
}
