<div
  class="handle-bar"
  cdkDrag
  cdkDragLockAxis="y"
  cdkDragRootElement=".cdk-global-overlay-wrapper"
  (cdkDragEnded)="onDragEnded($event)"
>
  <div class="bar"></div>
</div>

<ng-template #container></ng-template>
