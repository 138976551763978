import { Pipe, PipeTransform } from '@angular/core';
import {
  NikExchangeMethod,
  NikExchangeMethodText,
} from '../../../../../../src/entities/nik-exchange/types';

@Pipe({
  name: 'nikExchangeMethodFormat',
})
export class NikExchangeMethodFormatPipe implements PipeTransform {
  transform(method: NikExchangeMethod): string {
    if (!method) {
      return '';
    }
    return NikExchangeMethodText[method];
  }
}
