<header id="header">
  <div class="menu_wrap inner" [class.show]="isShow">
    <div class="gnb_wrap">
      <!-- s: PC HEADER -->

      <!-- e: PC HEADER -->
      <!-- s: MOBILE HEADER -->
      <div class="mobile-menu-wrapper">
        <div class="background-box" (click)="close()"></div>

        <ul class="gnb gnb_m mobile-menu-list-box">
          <a class="close-icon" (click)="close()">
            <img nikSrc="/assets/icons/28-4.png" alt="close icon" />
          </a>

          <div class="">
            <ul class="gnb gnb_m">
              <li class="icon1">
                <a routerLink="/" (click)="close()">
                  <p>HOME</p>
                </a>
              </li>

              <li class="icon2" [class.open]="mobileDigitalVisibility">
                <a
                  routerLink="/nft"
                  [queryParams]="{ type: 'item', category: 'buy' }"
                  (click)="close()"
                >
                  <p>{{ 'TOOLBAR.NAV.BUY_NOW' | translate }}</p>
                </a>
              </li>

              <li class="icon3" [class.open]="mobileRealVisibility">
                <a
                  routerLink="/nft"
                  [queryParams]="{ type: 'item', category: 'auction' }"
                  (click)="close()"
                >
                  <p>{{ 'TOOLBAR.NAV.AUCTION' | translate }}</p>
                </a>
              </li>

              <li class="icon6 depth" [class.open]="mobileSupportVisibility">
                <a (click)="mobileSupportVisibility = !mobileSupportVisibility"><p>SUPPORT</p></a>

                <ul *ngIf="mobileSupportVisibility" class="snb">
                  <li>
                    <a routerLink="/about-us" (click)="close()"
                    >ABOUT US</a
                    >
                  </li>

                  <li>
                    <a routerLink="/customer/notice" (click)="close()">NOTICE</a>
                  </li>

                  <li>
                    <a routerLink="/customer/faq" (click)="close()">FAQ</a>
                  </li>

                  <li>
                    <a
                      href="/assets/pdf/NIKPLACE_사용설명서_웹.pdf"
                      target="_blank"
                      (click)="close()"
                    >USER'S GUIDE</a
                    >
                  </li>

                  <li>
                    <a href="http://pf.kakao.com/_fcFxis" target="_blank" (click)="close()"
                    >CUSTOMER CENTER</a
                    >
                  </li>
                </ul>
              </li>

              <li *ngIf="chargeEnabled" class="icon4">
                <a routerLink="/charge" (click)="close()"
                ><p>{{ 'TOOLBAR.NAV.CHARGE' | translate }}</p></a
                >
              </li>

              <ng-container *ngIf="!isLoggedIn; else mobileLoggedIn">
                <li class="icon7">
                  <a routerLink="/auth/sign" (click)="close()">{{
                    'TOOLBAR.MY_PAGE.SIGN_UP' | translate
                    }}</a>
                </li>

                <li class="icon8">
                  <a routerLink="/auth/login" (click)="close()">{{
                    'TOOLBAR.MY_PAGE.LOGIN' | translate
                    }}</a>
                </li>
              </ng-container>

              <ng-template #mobileLoggedIn>
                <li class="icon7 depth" [class.open]="mobileMyVisibility">
                  <a (click)="mobileMyVisibility = !mobileMyVisibility">
                    <p>{{ 'TOOLBAR.MY_PAGE.MY' | translate }}</p>
                  </a>

                  <ul *ngIf="mobileMyVisibility" class="snb">
                    <li>
                      <a routerLink="/my" (click)="close()">{{
                        'TOOLBAR.MY_PAGE.MY_PAGE' | translate
                        }}</a>
                    </li>
                    <li>
                      <a routerLink="/shop/cart" (click)="close()">{{
                        'TOOLBAR.MY_PAGE.CART' | translate
                        }}</a>
                    </li>

                    <li *ngIf="attendanceCheckEnabled">
                      <a routerLink="/attendance" (click)="close()">{{
                        'TOOLBAR.MY_PAGE.ATTENDANCE' | translate
                        }}</a>
                    </li>

                    <!--                    <li>-->
                    <!--                      <a routerLink="/shop/exchange" (click)="close()">{{-->
                    <!--                        'TOOLBAR.MY_PAGE.EXCHANGE' | translate-->
                    <!--                        }}</a>-->
                    <!--                    </li>-->
                    <li>
                      <a (click)="logout()">{{ 'TOOLBAR.MY_PAGE.LOGOUT' | translate }}</a>
                    </li>
                  </ul>
                </li>
              </ng-template>
            </ul>
          </div>
        </ul>
      </div>
      <!-- e: MOBILE HEADER -->
    </div>

    <h1 class="logo">
      <a routerLink="/"><img src="/assets/icons/logo.png" alt="logo" /></a>
    </h1>

    <div
      class="q_box"
      *ngIf="isLoggedIn"
      [class.active]="isHoverQR"
      (mouseenter)="isHoverQR = true"
      (mouseleave)="isHoverQR = false"
    >
      <a class="author" (click)="copyUserId()">
        <div class="profile_img">
          <img *ngIf="user?.profilePictureUrl" [src]="user.profilePictureUrl" alt="profile_img" />
          <img
            *ngIf="!user?.profilePictureUrl"
            src="/assets/images/default_profile@2x.png"
            alt="profile_img"
          />
        </div>
        <strong>{{ (user$ | async)?.name }}</strong>
      </a>
      <div class="notosans">
        <img [src]="qrCodeDataUrl" alt="" class="img_qr" />

        <p class="info_text">
          {{ 'TOOLBAR.NIKHOLDER.INFO' | translate }}
        </p>
        <div class="copy">
          <p>{{ 'TOOLBAR.NIKHOLDER.COPY_INFO' | translate }}</p>
          <a [cdkCopyToClipboard]="user?.klaytnAccount.address" (click)="copied()"
          >{{ user?.klaytnAccount.address
            }}<img src="/assets/icons/copy@2x.png" alt="" class="img_copy"
            /></a>
        </div>
        <a
          class="link"
          [cdkCopyToClipboard]="user?.klaytnAccount.address"
          (click)="isHoverQR = false"
        >지갑 주소 복사</a
        >
      </div>
    </div>
  </div>

  <div class="">
    <div class="mobile_layout">
      <div class="toolbar-back-box">
        <a *ngIf="!isMain" nikBack>
          <img
            class="toolbar-back-icon"
            nikSrc="/assets/icons/ic-left-arrow.png"
            alt="arrow icon"
          />
        </a>
      </div>

      <div class="right-icon-box">
        <a *ngIf="isLoggedIn" class="mobile-notification" routerLink="/notification">
          <img class="vertical-top" src="/assets/icons/ic-bell@2x.png" alt="알람" />
        </a>

        <span class="qr_m" (click)="toggleIsHoverQr()"
        ><img src="/assets/icons/qr@2x.png" alt=""
        /></span>
      </div>
    </div>
  </div>
</header>

<div class="bottom-tap">
  <nav class="bottom-nav">
    <ul class="bottom-nav-list">
      <li class="bottom-nav-item">
        <a>
          <div id="hamburger" class="ham" [class.on]="isShow" (click)="toggleIsShow()">
            <span></span>
            <span></span>
            <span></span>
          </div>

          <p class="bottom-nav-name">MENU</p>
        </a>
      </li>

      <li class="bottom-nav-item">
        <a
          routerLink="/nft"
          [queryParams]="{ type: 'item', category: 'buy' }"
          routerLinkActive="active"
          #digitalLink="routerLinkActive"
        >
          <img
            *ngIf="!digitalLink.isActive"
            src="/assets/icons/digital-nft-de@2x.png"
            alt="menu icon"
          />
          <img
            *ngIf="digitalLink.isActive"
            src="/assets/icons/digital-nft-on@2x.png"
            alt="menu icon"
          />

          <p class="bottom-nav-name">BUY NOW</p>
        </a>
      </li>

      <li class="bottom-nav-item">
        <a
          routerLink="/nft"
          [queryParams]="{ type: 'item', category: 'auction' }"
          routerLinkActive="active"
          #realLink="routerLinkActive"
        >
          <img *ngIf="!realLink.isActive" src="/assets/icons/real-de@2x.png" alt="menu icon" />
          <img *ngIf="realLink.isActive" src="/assets/icons/real-on@2x.png" alt="menu icon" />

          <p class="bottom-nav-name">AUCTION</p>
        </a>
      </li>

      <li
        class="bottom-nav-item"
        #supportLink="routerLinkActive"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <a routerLink="/customer/faq">
          <img *ngIf="!supportLink.isActive" src="/assets/icons/chat@2x.png" alt="menu icon" />
          <img *ngIf="supportLink.isActive" src="/assets/icons/chat-on@2x.png" alt="menu icon" />

          <p class="bottom-nav-name">SUPPORT</p>
        </a>

        <a routerLink="/customer/user-guide"></a>
        <a routerLink="/customer/notice"></a>
        <a [routerLink]="['/customer/notice', noticeId]"></a>
      </li>

      <li
        class="bottom-nav-item"
        routerLinkActive="active"
        #myLink="routerLinkActive"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <a routerLink="/my/nft">
          <img *ngIf="!myLink.isActive" src="/assets/icons/wallet@2x.png" alt="menu icon" />
          <img *ngIf="myLink.isActive" src="/assets/icons/wallet-on@2x.png" alt="menu icon" />

          <p class="bottom-nav-name">MY</p>
        </a>

        <a routerLink="/my/auction"></a>
        <a routerLink="/my/bid"></a>
        <a routerLink="/my/order"></a>
        <a routerLink="/my/favorite"></a>
      </li>
    </ul>
  </nav>
</div>
