import { DbOptions, DbQuery, DbSortDirection } from './types';
import { BaseEntity } from '../base-entity';
import { DbAdapter } from './db.adapter';
import { HotObservableOnce, PaginationList } from '../types';
import { AsyncSubject, BehaviorSubject } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';


export function reverseDirection(direction: DbSortDirection) {
  return direction === DbSortDirection.Asc ? DbSortDirection.Desc : DbSortDirection.Asc;
}


export function makeDbPaginationList<T extends BaseEntity>(
  dbAdapter: DbAdapter<T>,
  query: DbQuery = {},
  options?: DbOptions
): PaginationList<T> {
  query.limit = query.limit || 20;
  const emitSubject = new BehaviorSubject<'prev' | 'next'>(null);
  const pageSubject = new BehaviorSubject<number>(0);
  const totalCountSubject = new AsyncSubject<number>();

  let lt: any;
  let gt: any;
  let page = 0;

  return {
    valueChange: emitSubject.asObservable().pipe(
      switchMap(emit => {
        let doc;

        if (emit === 'prev') {
          doc = { lt };
        } else if (emit === 'next') {
          doc = { gt };
        }

        if (emit === 'prev') {
          pageSubject.next(page - 1);
          page--;
        } else if (emit === 'next') {
          pageSubject.next(page + 1);
          page++;
        }

        return dbAdapter.listChange({ ...query, ...doc }, options).pipe(
          tap(response => {
            if (!totalCountSubject.closed) {
              totalCountSubject.next(response.totalCount);
              totalCountSubject.complete();
            }
          })
        );
      }),
      tap(response => {
        lt = response.firstDoc;
        gt = response.lastDoc;
      }),
      map(response => response.items)
    ),
    totalCountChange: totalCountSubject.asObservable(),
    pageChange: pageSubject.asObservable(),
    prev(): HotObservableOnce<number> {
      emitSubject.next('prev');

      return pageSubject.asObservable().pipe(take(1));
    },
    next(): HotObservableOnce<number> {
      emitSubject.next('next');

      return pageSubject.asObservable().pipe(take(1));
    }
  };
}
