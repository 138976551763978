import { BreakPoint } from '@angular/flex-layout';

export const BREAKPOINTS: BreakPoint[] = [
  {
    alias: 'lt-lg',
    mediaQuery: 'screen and (max-width: 1299px)',
  },
  {
    alias: 'gt-sm',
    mediaQuery: 'screen and (min-width: 768px)',
  },
  {
    alias: 'sm',
    mediaQuery: 'screen and (max-width: 767px)',
  },
  {
    alias: 'md',
    mediaQuery: 'screen and (min-width: 768px) and (max-width: 1299px)',
  },
  {
    alias: 'lg',
    mediaQuery: 'screen and (min-width: 1300px)',
  },
];
