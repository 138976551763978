<nik-toolbar></nik-toolbar>
<!--*ngIf="media.isActive('gt-sm')"-->
<!--<nik-mobile-toolbar *ngIf="media.isActive('sm')"></nik-mobile-toolbar>-->

<div class="floating-box">
  <div class="router-outlet-box">
    <router-outlet></router-outlet>
  </div>

  <div class="floating-button-sticky" [class.my-page]="isMyPage$ | async">
    <div class="floating-button-relative">
      <div class="floating-button-absolute">

        <div class="floating-button2" (click)="openSNS('telegram')">
          <div class="img_area">
            <i class="xi-telegram"></i>
          </div>
          <div class="text_area">TELEGRAM</div>
        </div>

        <div class="floating-button2" (click)="openSNS('twitter')">
          <div class="img_area">
            <i class="xi-twitter"></i>
          </div>
          <div class="text_area">TWITTER</div>
        </div>

      </div>
    </div>
  </div>

</div>

<nik-footer></nik-footer>
