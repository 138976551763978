import { Injectable } from '@angular/core';
import { ApiService } from '../../../../../../src/core/api/api.service';
import { makeFunctionsService } from '../../../../../../src/modules/firebase/utils';

@Injectable({
  providedIn: 'root',
})
export class NgApiService extends makeFunctionsService(ApiService, 'ApiService') {
  constructor() {
    super();
  }
}
