import { Component } from '@angular/core';
import { NgAuthService } from '../../../nikplace/src/lib/core/auth/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { NavigationEnd, Router } from '@angular/router';
import FirebaseAdapter from '../../../../src/modules/firebase/firebase.adapter';
import { NgApiRatioService } from '../../../nikplace/src/lib/modules/config/services/api-ratio.service';
import { RatioService } from './modules/ntf/state/ratio.service';

@Component({
  selector: 'nik-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    private authService: NgAuthService,
    private translate: TranslateService,
    private router: Router,
    private ratioService: NgApiRatioService,
    private ratioStateService: RatioService
  ) {
    this.initServices();
    this.initPageAnalytics();
    this.initRatio();
  }

  private initServices(): void {
    this.authService.init();
    this.translate.setDefaultLang('en');
    this.translate.use('en');
  }

  private initPageAnalytics(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        FirebaseAdapter.analytics.logEvent('page_view', { page_title: event.urlAfterRedirects });
      }
    });
  }

  private initRatio(): void {
    this.ratioService.getUsdNikRatio().then((ratio) => {
      this.ratioStateService.updateUsdNik(ratio.ratio);
    });

    this.ratioService.getWonUsdRatio().then((ratio) => {
      this.ratioStateService.updateWonUsd(ratio.ratio);
    });

    this.ratioService.getWonNikRatio().then((ratio) => {
      this.ratioStateService.updateWonNik(ratio.ratio);
    });
  }
}
