import { BaseEntity } from '../../core/base-entity';
import { Item } from '../item/types';
import { User } from '../../core/user/types';


export interface Bid extends BaseEntity {
  bid: any;
  userId: string;
  itemId: string;
  amount: number;
  item?: Item;
  user?: User;
  status: BidStatus;
}

export enum BidStatus {
  Bidding = 1,
  Winning = 31,
  Failure = 61,
  Canceled = 91
}
