import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateFormatPipe } from './date-format.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { OrderStatusFormatPipe } from './order-status-format.pipe';
import { OrderStatusClassPipe } from './order-status-class.pipe';
import { ItemStatusClassPipe } from './item-status-class.pipe';
import { ItemStatusFormatPipe } from './item-status-format.pipe';
import { NikExchangeMethodFormatPipe } from './nik-exchange-method-format.pipe';
import { NikExchangeStatusFormatPipe } from './nik-exchange-status-format.pipe';
import { NikExchangeStatusClassPipe } from './nik-exchange-status-class.pipe';

@NgModule({
  declarations: [
    DateFormatPipe,
    SafeHtmlPipe,
    OrderStatusFormatPipe,
    OrderStatusClassPipe,
    ItemStatusClassPipe,
    ItemStatusFormatPipe,
    NikExchangeMethodFormatPipe,
    NikExchangeStatusFormatPipe,
    NikExchangeStatusClassPipe,
  ],
  exports: [
    DateFormatPipe,
    SafeHtmlPipe,
    OrderStatusFormatPipe,
    OrderStatusClassPipe,
    ItemStatusClassPipe,
    ItemStatusFormatPipe,
    NikExchangeMethodFormatPipe,
    NikExchangeStatusFormatPipe,
    NikExchangeStatusClassPipe,
  ],
  imports: [CommonModule],
})
export class PipesModule {}
