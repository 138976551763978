import { Pipe, PipeTransform } from '@angular/core';
import { Order, OrderStatus, OrderStatusText, OrderType } from '../../../../../../src/entities/order/types';

@Pipe({
  name: 'orderStatusFormat',
})
export class OrderStatusFormatPipe implements PipeTransform {
  transform(order: Order): string {
    if (!order) {
      return '';
    }

    if (order.type === OrderType.Gift && order.status === OrderStatus.Completed) {
      return 'Gift Complete';
    } else {
      return OrderStatusText[order.status];
    }
  }
}
