import { BaseEntity } from '../../core/base-entity';
import { SearchAdapter } from '../../core/search/search.adapter';
import { SearchQuery, SearchResponse, SearchFacetResponse } from '../../core/search/types';
import * as algoliasearch from 'algoliasearch';
import { HashMap } from '../../core/types';


export class AlgoliaSearchAdapter<E extends BaseEntity = any> implements SearchAdapter<E> {
  indexes: HashMap<algoliasearch.SearchIndex> = {};

  constructor(
    private client: algoliasearch.SearchClient,
    private name: string,
    private dateFields?: string[]
  ) {}

  clearCache(): void {
    this.client.clearCache();
  }

  async search(query: SearchQuery<E>): Promise<SearchResponse<E>> {
    const response = await this.getIndex(query).search(query.query, this.convertQueryToFilters(query));

    return this.makeSearchResponse(response);
  }

  searchForFacetValues(
    facetName: string, facetQuery: string, query: SearchQuery<E> = {}
  ): Promise<SearchFacetResponse> {
    return this.getIndex(query).searchForFacetValues(
      facetName,
      facetQuery,
      { ...this.convertQueryToFilters(query), maxFacetHits: 27 }
    );
  }

  async get(idOrIds: string | string[]): Promise<E | E[]> {
    if (Array.isArray(idOrIds)) {
      const response = await this.getIndex().getObjects(idOrIds);
      return response.results.map(hit => this.convertHitToEntity(hit)) as E[];
    } else {
      const hit = await this.getIndex().getObject(idOrIds);
      return this.convertHitToEntity(hit);
    }
  }

  async add(entityOrEntities: E | E[]): Promise<string | string[]> {
    if (Array.isArray(entityOrEntities)) {
      const response = await this.getIndex().saveObjects(entityOrEntities);
      return [...response.objectIDs];
    } else {
      const response = await this.getIndex().saveObject(entityOrEntities);
      return response.objectID;
    }
  }

  update(entity: Partial<E>): Promise<string>;
  update(entities: Partial<E>[]): Promise<string[]>;
  async update(entityOrEntities: Partial<E> | Partial<E>[]): Promise<string | string[]> {
    if (Array.isArray(entityOrEntities)) {
      const response = await this.getIndex().partialUpdateObjects(entityOrEntities);
      return (response as any).objectIDs;
    } else {
      const response = await this.getIndex().partialUpdateObject(entityOrEntities);
      return response.objectID;
    }
  }

  upsert(entity: E): Promise<string>;
  upsert(entities: E[]): Promise<string[]>;
  async upsert(entityOrEntities: Partial<E> | Partial<E>[]): Promise<string | string[]> {
    if (Array.isArray(entityOrEntities)) {
      const response = await this.getIndex().saveObjects(entityOrEntities);
      return (response as any).objectIDs;
    } else {
      const response = this.getIndex().saveObject(entityOrEntities);
      return (response as any).objectID;
    }
  }

  async delete(idOrIds: string | string[]): Promise<void> {
    if (Array.isArray(idOrIds)) {
      await this.getIndex().deleteObjects(idOrIds);
    } else {
      await this.getIndex().deleteObject(idOrIds);
    }
  }

  private makeSearchResponse(response): SearchResponse<E> {
    return {
      items: response.hits.map(hit => this.convertHitToEntity(hit)),
      totalCount: response.nbHits,
      count: response.hits.length,
      page: response.page,
      size: response.hitsPerPage
    };
  }

  private convertHitToEntity(hit: { objectID: string }): E {
    const entity = {
      id: hit.objectID
    } as E;

    for (const key in hit) {
      if (hit.hasOwnProperty(key)) {
        if (this.dateFields && this.dateFields.indexOf(key) > -1) {
          if (hit[key]) {
            entity[key] = new Date(hit[key]);
          } else {
            entity[key] = null;
          }
        } else {
          entity[key] = hit[key];
        }
      }
    }

    return entity;
  }

  private convertQueryToFilters(query: SearchQuery<E>): any {
    const algoliaQuery: any = {};

    if (query.filters) {
      algoliaQuery.filters = '';

      for (const filter of query.filters) {
        if (filter.parenthesis) {
          if (filter.parenthesis === 'start' && algoliaQuery.filters) {
            algoliaQuery.filters += ` ${filter.logical ? filter.logical.toUpperCase() : 'AND'} `;
          }

          algoliaQuery.filters += filter.parenthesis === 'start' ? '(' : ')';
          continue;
        }

        if (
          algoliaQuery.filters &&
          algoliaQuery.filters.charAt(algoliaQuery.filters.length - 1) !== '(' &&
          (
            filter.value !== undefined || (filter.lowerValue && filter.higherValue)
          )
        ) {
          algoliaQuery.filters += ` ${filter.logical ? filter.logical.toUpperCase() : 'AND'} `;
        }

        if (filter.value !== undefined) {
          if (filter.comparison) {
            if (filter.comparison === '==') {
              if (typeof filter.value === 'string') {
                algoliaQuery.filters += `${filter.field}:"${filter.value}"`;
              } else {
                algoliaQuery.filters += `${filter.field} = ${filter.value}`;
              }
            } else if (filter.comparison === '!=') {
              if (typeof filter.value === 'string') {
                algoliaQuery.filters += `NOT ${filter.field}:"${filter.value}"`;
              } else {
                algoliaQuery.filters += `NOT ${filter.field}:${filter.value}`;
              }
            } else {
              algoliaQuery.filters += `${filter.field} ${filter.comparison} ${filter.value}`;
            }
          } else {
            if (typeof filter.value === 'string') {
              algoliaQuery.filters += `${filter.field}:"${filter.value}"`;
            } else {
              algoliaQuery.filters += `${filter.field}:${filter.value}`;
            }
          }
        } else {
          if (filter.lowerValue && filter.higherValue) {
            algoliaQuery.filters += `${filter.field}:${filter.lowerValue} TO ${filter.higherValue}`;
          }
        }
      }
    }

    if (query.page >= 0) {
      algoliaQuery.page = query.page;
    }

    if (query.size) {
      algoliaQuery.hitsPerPage = query.size;
    }

    if (query.distinct) {
      algoliaQuery.distinct = true;
    }

    return algoliaQuery;
  }

  private getIndex(query: SearchQuery = {}): algoliasearch.SearchIndex {
    let name = this.name;

    if (query.sorts && query.sorts[0] && query.sorts[0].field) {
      const sort = query.sorts[0];

      name = `${this.name}_${sort.field}_${sort.direction}`;
    }

    if (!this.indexes[name]) {
      this.indexes[name] = this.client.initIndex(name);
    }

    return this.indexes[name];
  }
}
