import { BaseEntity } from '../../core/base-entity';
import { DbAdapter } from '../../core/db/db.adapter';
import { SearchQuery, SearchResponse, SearchFilter } from '../../core/search/types';
import { HashMap } from '../../core/types';
import { SearchAdapter } from '../../core/search/search.adapter';
import { FrontendApiService } from '../../core/api/frontend-api.service';
import { DbQuery } from '../../core/db/types';


export class FrontendBigquerySearchAdapter<E extends BaseEntity> implements SearchAdapter<E> {
  private basePerPage = 20;

  constructor(
    protected tableName: string,
    protected apiService: FrontendApiService,
    protected dbAdapter: DbAdapter<E>
  ) {
  }

  async search(query: SearchQuery<E>): Promise<SearchResponse<E>> {
    const response = await this.apiService.bigquery(this.convertQueryToCountQuery(query), this.convertQueryForBigquery(query));

    return this.makeResponse(query, response.data);
  }

  async query(query: string): Promise<E[]> {
    const response = await this.apiService.bigqueryOne(query);

    return this.makeQueryResponse(response.data);
  }

  private convertQueryToCountQuery(query: SearchQuery<E>): string {
    let q = '';

    if (query.distinct) {
      q += `WITH
    basicTable AS (
        SELECT distinct document_id`;

      if (query.filters && query.filters.length) {
        for (const filter of query.filters) {
          if (!filter.field || filter.field === 'document_id') {
            continue;
          }

          q += `, ${filter.field}`;
        }
      }

      if (query.distinct) {
        q += `, ROW_NUMBER() OVER(PARTITION BY ${query.distinct}) AS rn`;
      }

      q += ` FROM \`${this.tableName}\``;

      if (query.filters && query.filters.length) {
        q += this.makeSqlWhere(query.filters);
      }

      q += `)
SELECT COUNT(document_id) FROM basicTable`;

    } else {
      q += `SELECT COUNT(DISTINCT document_id) FROM \`${this.tableName}\``;

    }

    if (query.filters && query.filters.length) {
      if (query.distinct) {
        q += ` WHERE rn = 1`;
      } else {
        q += this.makeSqlWhere(query.filters);
      }
    } else {
      if (query.distinct) {
        q += ` WHERE rn = 1`;
      }
    }

    return q;
  }

  private convertQueryForBigquery(query: SearchQuery<E>): string {
    let q = '';

    if (query.distinct) {
      q += `WITH
    basicTable AS (
        SELECT distinct document_id`;

      const fields = [];

      if (query.filters && query.filters.length) {
        for (const filter of query.filters) {
          if (!filter.field || filter.field === 'document_id') {
            continue;
          }

          if (fields.indexOf(filter.field) === -1) {
            fields.push(filter.field);
          }
        }
      }

      if (query.sorts && query.sorts.length) {
        for (const sort of query.sorts) {
          if (fields.indexOf(sort.field) === -1) {
            fields.push(sort.field);
          }
        }
      }

      for (const field of fields) {
        q += `, ${field}`;
      }

      if (query.distinct) {
        q += `, ROW_NUMBER() OVER(PARTITION BY ${query.distinct}) AS rn`;
      }

      q += ` FROM \`${this.tableName}\``;

      if (query.filters && query.filters.length) {
        q += this.makeSqlWhere(query.filters);
      }

      q += `)
SELECT document_id`;

      if (query.sorts && query.sorts.length) {
        for (const sort of query.sorts) {
          q += `, ${sort.field}`;
        }
      }

      q += ` FROM basicTable`;

    } else {
      q += `SELECT DISTINCT document_id`;

      if (query.sorts && query.sorts.length) {
        for (const sort of query.sorts) {
          q += `, ${sort.field}`;
        }
      }

      q += ` FROM \`${this.tableName}\``;
    }

    if (query.filters && query.filters.length) {
      if (query.distinct) {
        q += ` WHERE rn = 1`;
      } else {
        q += this.makeSqlWhere(query.filters);
      }
    } else {
      if (query.distinct) {
        q += ` WHERE rn = 1`;
      }
    }

    if (query.sorts && query.sorts.length) {
      for (let i = 0; i < query.sorts.length; i++) {
        const sort = query.sorts[i];

        if (i === 0) {
          q += ` ORDER BY `;
        } else {
          q += `, `;
        }

        q += `${sort.field} ${sort.direction}`;
      }
    }

    const perPage = query.size || this.basePerPage;

    q += ` Limit ${perPage}`;

    if (query.page > 0) {
      q += ` OFFSET ${query.page * perPage}`;
    }

    return q;
  }

  private makeQueryResponse(docs: any[]): E[] {
    return docs.filter(Boolean);
  }

  private async makeResponse(query: SearchQuery<E>, { count, docs }: { count: number, docs: any[] }): Promise<SearchResponse<E>> {
    let items;

    if (docs.length === 0) {
      items = [];
    } else {
      if (docs.length > 100) {
        const dbQuery: DbQuery = {
          filters: query.filters[0] as any,
          limit: 10000
        };

        const firestoreDocs = await this.listFromFirestore([], dbQuery);
        const results = [];
        const docMap = {};
        const getPromises = [];

        for (const doc of firestoreDocs) {
          docMap[doc.id] = doc;
        }

        for (let i = 0; i < docs.length; i++) {
          const docId = docs[i].document_id;

          if (docMap[docId]) {
            results[i] = docMap[docId];
          } else {
            getPromises.push(this.dbAdapter.get(docId));
          }
        }

        const getResults = (await Promise.all(getPromises)).filter(Boolean);

        if (getResults.length > 0) {
          const innerDocMap = {};

          for (const doc of getResults) {
            innerDocMap[(doc as any).id] = doc;
          }

          for (let i = 0; i < docs.length; i++) {
            const docId = docs[i].document_id;

            if (innerDocMap[docId]) {
              results[i] = innerDocMap[docId];
            }
          }
        }

        items = results;
      } else {
        items = await Promise.all(
          docs.map(doc => this.dbAdapter.get(doc.document_id))
        );
      }
    }

    return {
      items: items.filter(Boolean),
      totalCount: count,
      count: docs.length,
      page: query.page || 0,
      size: query.size || this.basePerPage
    };
  }

  private async listFromFirestore(docs: E[], query: DbQuery, lastDoc?: any): Promise<E[]> {
    if (lastDoc) {
      query.gt = lastDoc;
    }

    const response = await this.dbAdapter.list(query);

    docs = docs.concat(response.items);

    if (response.lastDoc) {
      return this.listFromFirestore(docs, query, response.lastDoc);
    } else {
      return docs;
    }
  }

  private convertDocToEntity(doc: HashMap<any>): BaseEntity {
    const id = doc.document_id;

    delete doc.document_id;
    delete doc.document_name;
    delete doc.operation;
    delete doc.timestamp;

    return {
      ...this.convertFlatToObject(this.convertTimestampToDate(doc)),
      id
    };
  }

  private convertTimestampToDate(documentData: any): any {
    if (documentData && documentData.value) {
      return new Date(documentData.value);
    } else if (Array.isArray(documentData)) {
      const node = [];

      for (const data of documentData) {
        node.push(this.convertTimestampToDate(data));
      }

      return node;
    } else if (documentData === null) {
      return null;
    } else if (typeof documentData === 'object') {
      const data = { ...documentData };

      if (Object.keys(data).length > 0) {
        for (const key in data) {
          if (data.hasOwnProperty(key) && data[key]) {
            data[key] = this.convertTimestampToDate(data[key]);
          }
        }

        return data;
      }
    } else {
      return documentData;
    }
  }

  private convertFlatToObject(doc: HashMap<any>): E {
    const entity = {} as E;

    for (const key in doc) {
      if (doc.hasOwnProperty(key)) {
        const split = key.split('_');

        for (const k of split) {
          // entity[k]
        }
      }
    }

    return doc as E;
  }

  private makeSqlWhere(filters: SearchFilter[]): string {
    let where = '';

    for (let i = 0; i < filters.length; i++) {
      const filter = filters[i];

      if (i === 0) {
        where += ` WHERE `;
      }

      if (
        i !== 0 &&
        where.charAt(where.length - 1) !== '(' &&
        (
          filter.value !== undefined || (filter.lowerValue && filter.higherValue)
        )
      ) {
        where += ` ${filter.logical ? filter.logical.toUpperCase() : 'AND'} `;
      }

      if (filter.parenthesis) {
        if (filter.parenthesis === 'start' && i !== 0) {
          where += ` ${filter.logical ? filter.logical.toUpperCase() : 'AND'} `;
        }

        where += filter.parenthesis === 'start' ? '(' : ')';
        continue;
      }

      where += this.makeSqlWhereCond(filter);
    }

    return where;
  }

  private makeSqlWhereCond(filter: SearchFilter): string {
    if (filter.parenthesis) {
      return '';
    }

    let comparison = filter.comparison === '!=' ? '<>' : filter.comparison;
    comparison = filter.comparison === 'text' ? 'LIKE' : comparison;
    comparison = filter.comparison === '==' ? '=' : comparison;
    comparison = !filter.comparison ? '=' : comparison;

    let cond: string;
    if (filter.comparison === 'text') {
      cond = `LOWER(${filter.field}) ${comparison} `;
    } else {
      cond = `${filter.field} ${comparison} `;
    }

    const value = filter.value;

    if (typeof filter.value === 'string') {
      if (filter.comparison === 'text') {
        cond += `'%${value.toLowerCase()}%'`;
      } else {
        cond += `'${value}'`;
      }
    } else if (typeof value === 'number') {
      cond += value;
    } else if (value instanceof Date) {
      cond += `TIMESTAMP("${filter.value.toISOString()}")`;
    } else {
      cond += value;
    }

    return cond;
  }
}

