<div class="nikholder-box">
  <div class="close-box" fxHide.lt-lg>
    <a class="close-button" matDialogClose>
      <img class="close-icon" nikSrc="/assets/icons/dialog-close-white.png">
    </a>
  </div>

  <div class="content-box">
    <div class="profile-box" (click)="copyId()" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="11px">
      <div class="profile-picture" [style.background-image]="makeProfilePictureUrl(user?.profilePictureUrl)" fxFlex="none"></div>

      <div class="profile-info">
        <p class="profile-name">{{ user?.nickname || user?.name }}</p>

        <p class="profile-level">{{ makeUserLevelText(user?.level) }}</p>
      </div>
    </div>

    <div class="qr-box" fxLayout="row" fxLayoutGap="26px" fxLayout.lt-lg="column" fxLayoutAlign.lt-lg="start center">
      <img class="qr-image" [src]="qrCodeDataUrl" fxFlex="none">

      <p class="qr-text" fxFlex="auto">
        To deposit NIKOIN, please copy and paste the address below or scan the QR code.
      </p>
    </div>

    <div class="copy-address-box">
      <p class="copy-address-text">Click to copy NIK deposit-only address</p>

      <a class="copy-address" (click)="copyAddress()" matDialogClose>{{ user?.klaytnAccount.address }}</a>
    </div>

    <div class="button-box">
      <button type="button" (click)="copyAddress()" matDialogClose>DEPOSIT</button>
      <button type="button" routerLink="/withdrawal" matDialogClose>TRANSPER</button>
      <button type="button" routerLink="/my-asset" matDialogClose>My NIKX</button>
    </div>
  </div>
</div>
