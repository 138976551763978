import { BehaviorSubject, AsyncSubject, of } from 'rxjs';
import { switchMap, tap, take, map } from 'rxjs/operators';
import { BaseEntity } from '../base-entity';
import { InfinityList, HotObservableOnce, PaginationList, ColdObservableOnce } from '../types';
import { SearchAdapter } from './search.adapter';
import { SearchQuery } from './types';


export function makeSearchInfinityList<T>(
  searchAdapter: SearchAdapter,
  query: SearchQuery<T> = {}
): InfinityList<T> {
  let values = [];

  const hasMoreSubject = new BehaviorSubject<boolean>(false);
  const pageSubject = new BehaviorSubject<number>(query.page || 0);
  const totalCountSubject = new AsyncSubject<number>();

  let moreProcessing = false;

  return {
    valueChange: pageSubject.asObservable().pipe(
      switchMap(p => searchAdapter.search({ ...query, page: p })),
      tap(response => {
        hasMoreSubject.next((response.page + 1) * response.size < response.totalCount);

        if (!totalCountSubject.closed) {
          totalCountSubject.next(response.totalCount);
          totalCountSubject.complete();
        }

        moreProcessing = false;
      }),
      map(response => {
        values = values.concat(response.items);
        return values;
      }),
    ),
    hasMoreChange: hasMoreSubject.asObservable(),
    totalCount: totalCountSubject.asObservable(),
    more(): HotObservableOnce<void> {
      if (moreProcessing || !hasMoreSubject.getValue()) {
        return of(null);
      }

      moreProcessing = true;
      pageSubject.next(pageSubject.getValue() + 1);

      return hasMoreSubject.asObservable().pipe(
        take(1),
        map(() => {})
      );
    }
  };
}

export function makeEmptySearchInfinityList(): InfinityList {
  return {
    valueChange: of([]),
    hasMoreChange: of(false),
    totalCount: of(0),
    more(): HotObservableOnce<void> {
      return of(null);
    }
  };
}

export function makeSearchPaginationList<T extends BaseEntity>(
  searchAdapter: SearchAdapter<T>,
  query: SearchQuery = {}
): PaginationList<T> {
  const pageSubject = new BehaviorSubject<number>(0);
  const totalCountSubject = new BehaviorSubject<number>(0);

  return {
    valueChange: pageSubject.asObservable().pipe(
      switchMap(async page => {
        const response = await searchAdapter.search({ ...query, page });
        totalCountSubject.next(response.totalCount);
        return response;
      }),
      map(response => response.items)
    ),
    totalCountChange: totalCountSubject.asObservable(),
    pageChange: pageSubject.asObservable(),
    setPage(page: number): void {
      pageSubject.next(page);
    },
    prev(): ColdObservableOnce<number> {
      const page = pageSubject.getValue();

      pageSubject.next(page - 1);

      return of(page - 1);
    },
    next(): ColdObservableOnce<number> {
      const page = pageSubject.getValue();

      pageSubject.next(page + 1);

      return of(page + 1);
    },
    refresh(): void {
      searchAdapter.clearCache();

      const page = pageSubject.getValue();

      pageSubject.next(page);
    }
  };
}
