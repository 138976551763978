import { DbAdapter } from '../../core/db/db.adapter';
import {
  DbBatchUpdate,
  DbListRequest,
  DbListResponse,
  DbQuery,
  DbSortDirection
} from '../../core/db/types';
import { SearchAdapter } from '../../core/search/search.adapter';
import { SearchQuery, SearchResponse } from '../../core/search/types';
import { makeSearchInfinityList, makeSearchPaginationList } from '../../core/search/utils';
import { UserService } from '../../core/user/user.service';
import { FirestoreDbAdapter } from '../../modules/firebase/firestore-db.adapter';
import { KlaytnService } from '../../modules/klaytn/klaytn.service';
import { roundNik } from '../../modules/klaytn/utils';
import {
  DiamondChain,
  DiamondChainType,
  Item,
  ItemHistory,
  ItemNikSeries,
  ItemPaymentType,
  ItemStatus,
  SaleType
} from './types';
import { getImmediateAmount, makeMultiTokenId, makeTokenId } from './utils';
import { delay, makeArrayPaginationList, makeDbInfinityList } from '../../core/utils';
import { InfinityList, PaginationList } from '../../core/types';
import { makeDbPaginationList } from '../../core/db/utils';
import { map } from 'rxjs/operators';
import { ConfigService } from '../config/config.service';
import { User, UserLevel } from '../../core/user/types';
import { KlaytnInputType } from '../../modules/klaytn/types';

export class ItemService {
  constructor(
    protected itemDb: FirestoreDbAdapter<Item>,
    protected itemHistoryDb: DbAdapter<ItemHistory>,
    protected itemSearch: SearchAdapter<Item>,
    protected userService: UserService,
    protected klaytnService: KlaytnService,
    protected itemBigSearch: SearchAdapter<Item>,
    protected configService: ConfigService
  ) {}

  createId(): string {
    return this.itemDb.createId();
  }

  distinctSeriesByUserId(field: 'galleryId' | 'artistId', userId: string) {
    const query: SearchQuery = {
      filters: [{ field, comparison: '==', value: userId }]
    };

    return this.itemSearch.searchForFacetValues('series', '', query);
  }

  get(id: string): Promise<Item> {
    return this.itemDb.get(id);
  }

  getMany(itemIds: string[]): Promise<Item[]> {
    return this.itemDb.getMany(itemIds);
  }

  search(query: SearchQuery): Promise<SearchResponse<Item>> {
    return this.itemSearch.search(query);
  }

  async findOnSaleItemByGroup(group: string): Promise<Item> {
    console.log(group);
    const query: DbQuery = {
      filters: [
        { field: 'group', comparison: '==', value: group },
        { field: 'status', comparison: '==', value: ItemStatus.OnSale }
      ],
      limit: 1
    };

    const response = await this.itemDb.list(query);
    console.log(response);

    return response.items[0];
  }

  searchFromBig(query: SearchQuery): Promise<SearchResponse<Item>> {
    return this.itemBigSearch.search(query);
  }

  searchInfinityList(query: SearchQuery): InfinityList<Item> {
    return makeSearchInfinityList(this.itemSearch, query);
  }

  searchPaginationList(query: SearchQuery): PaginationList<Item> {
    return makeSearchPaginationList(this.itemSearch, query);
  }

  searchPaginationListFromBigquery(query: SearchQuery): PaginationList<Item> {
    return makeSearchPaginationList(this.itemBigSearch, query);
  }

  dbPaginationList(query: DbQuery): PaginationList<Item> {
    return makeDbPaginationList(this.itemDb, query);
  }

  arrayPaginationList(query: DbQuery): PaginationList<Item> {
    const limit = query.limit;
    query.limit = 9999;

    return makeArrayPaginationList(
      this.itemDb.listChange(query).pipe(map((response) => response.items)),
      limit || 20
    );
  }

  async count(query: SearchQuery): Promise<number> {
    const response = await this.itemSearch.search(query);

    return response.totalCount;
  }

  async list(request: DbListRequest = {}): Promise<DbListResponse<Item>> {
    const query: DbQuery = {
      sorts: [{ field: 'createdAt', direction: DbSortDirection.Desc }],
      limit: request.size ? +request.size : null
    };

    if (request.cursor) {
      query.gt = await this.itemDb.getSnapshot(request.cursor);
    }

    return this.itemDb.list(query);
  }

  async listAuctionsByGroup(group: string): Promise<Item[]> {
    const query: DbQuery = {
      filters: [
        { field: 'group', comparison: '==', value: group },
        { field: 'status', comparison: '==', value: ItemStatus.Bidding }
      ]
    };

    const response = await this.itemDb.list(query);

    return response.items;
  }

  async getRecentList(
    request: DbListRequest = {},
    isEdition: boolean
  ): Promise<DbListResponse<Item>> {
    const query: DbQuery = {
      filters: [{ field: 'isEdition', comparison: '==', value: isEdition }],
      sorts: [{ field: 'createdAt', direction: DbSortDirection.Desc }],
      limit: request.size ? +request.size : null
    };

    if (request.cursor) {
      query.gt = await this.itemDb.getSnapshot(request.cursor);
    }

    return this.itemDb.list(query);
  }

  async searchAuctionDigitalForMain(user: User): Promise<Item[]> {
    const query: SearchQuery = {
      filters: [
        { field: 'saleByNikplace', value: false },
        { field: 'status', value: ItemStatus.Bidding },
        { field: 'isWantCard', value: false },
        { field: 'isParticipatory', value: false },
        { field: 'hasOwnership', value: false },
        { field: 'hidden', comparison: '!=', value: true },
        { field: 'isDeleted', comparison: '!=', value: true }
      ],
      sorts: [{ field: 'auctionStartAt', direction: DbSortDirection.Desc }],
      size: 6,
      distinct: 'group'
    };

    if (!user || user.level < UserLevel.Gold) {
      query.filters.push({ field: 'isForVip', comparison: '!=', value: true });
    }

    const response = await this.itemSearch.search(query);

    return response.items;
  }

  async searchAuctionRealPaintingForMain(user: User): Promise<Item[]> {
    const query: SearchQuery = {
      filters: [
        { field: 'saleByNikplace', value: false },
        { field: 'status', value: ItemStatus.Bidding },
        { field: 'isWantCard', value: false },
        { field: 'isParticipatory', value: false },
        { field: 'hasOwnership', value: true },
        { field: 'hidden', comparison: '!=', value: true },
        { field: 'isDeleted', comparison: '!=', value: true }
      ],
      sorts: [{ field: 'auctionStartAt', direction: DbSortDirection.Desc }],
      size: 6,
      distinct: 'group'
    };

    if (!user || user.level < UserLevel.Gold) {
      query.filters.push({ field: 'isForVip', comparison: '!=', value: true });
    }

    const response = await this.itemSearch.search(query);

    return response.items;
  }

  async searchBuyDigitalForMain(user: User): Promise<Item[]> {
    const query: SearchQuery = {
      filters: [
        { field: 'saleByNikplace', value: true },
        { field: 'status', value: ItemStatus.OnSale },
        { field: 'isWantCard', value: false },
        { field: 'isParticipatory', value: false },
        { field: 'hasOwnership', value: false },
        { field: 'hidden', comparison: '!=', value: true },
        { field: 'isDeleted', comparison: '!=', value: true }
      ],
      sorts: [{ field: 'createdAt', direction: DbSortDirection.Desc }],
      size: 6,
      distinct: 'group'
    };

    if (!user || user.level < UserLevel.Gold) {
      query.filters.push({ field: 'isForVip', comparison: '!=', value: true });
    }

    const response = await this.itemSearch.search(query);

    return response.items;
  }

  async searchBuyRealPaintingForMain(user: User): Promise<Item[]> {
    const query: SearchQuery = {
      filters: [
        { field: 'saleByNikplace', value: true },
        { field: 'status', value: ItemStatus.OnSale },
        { field: 'isWantCard', value: false },
        { field: 'isParticipatory', value: false },
        { field: 'hasOwnership', value: true },
        { field: 'hidden', comparison: '!=', value: true },
        { field: 'isDeleted', comparison: '!=', value: true }
      ],
      sorts: [{ field: 'createdAt', direction: DbSortDirection.Desc }],
      size: 6,
      distinct: 'group'
    };

    if (!user || user.level < UserLevel.Gold) {
      query.filters.push({ field: 'isForVip', comparison: '!=', value: true });
    }

    const response = await this.itemSearch.search(query);

    return response.items;
  }

  async searchMainItemsForMain(): Promise<Item[]> {
    const items = [];

    const responses = await Promise.all([
      this.itemDb.get('JV3SuY5ky8HhKVEBMayf'),
      this.itemDb.get('do5paUyiRjqt5BAjQWra'),
      this.itemDb.get('Ifd23EQ6TZKZOzcUfXbz'),
      this.itemDb.get('kgzaI1M6uHjpYAqxl0KW'),
      this.itemDb.get('rwNILcaAvxAPPM7i48aH')
    ]);

    if (responses[0]) {
      items.push(responses[0]);
    }

    if (responses[1]) {
      items.push(responses[1]);
    }

    if (responses[2]) {
      items.push(responses[2]);
    }

    if (responses[3]) {
      items.push(responses[3]);
    }

    if (responses[4]) {
      items.push(responses[4]);
    }

    return items;
  }

  async searchNewItemsForMain(user: User): Promise<Item[]> {
    const query: SearchQuery = {
      filters: [
        { field: 'isWantCard', value: false },
        { field: 'hidden', comparison: '!=', value: true },
        { field: 'isDeleted', comparison: '!=', value: true }
      ],
      sorts: [{ field: 'createdAt', direction: DbSortDirection.Desc }],
      size: 9,
      distinct: 'group'
    };

    if (!user || user.level < UserLevel.Gold) {
      query.filters.push({ field: 'isForVip', comparison: '!=', value: true });
    }

    const response = await this.itemSearch.search(query);

    return response.items;
  }

  async searchHolnikItemsForMain(user: User): Promise<Item[]> {
    const query: SearchQuery = {
      filters: [
        { field: 'series', value: ItemNikSeries.HOL_NIK },
        { field: 'isEdition', value: false },
        { field: 'status', value: ItemStatus.OnSale },
        { field: 'hidden', comparison: '!=', value: true }
      ],
      sorts: [{ field: 'createdAt', direction: DbSortDirection.Desc }],
      size: 9
    };

    if (!user || user.level < UserLevel.Gold) {
      query.filters.push({ field: 'isForVip', comparison: '!=', value: true });
    }

    const response = await this.itemSearch.search(query);

    return response.items;
  }

  async searchBellygomItemsForMain(): Promise<Item[]> {
    const query: SearchQuery = {
      filters: [{ field: 'series', value: ItemNikSeries.BELLYGOM }],
      sorts: [{ field: 'createdAt', direction: DbSortDirection.Desc }],
      size: 9,
      distinct: 'group'
    };

    const response = await this.itemSearch.search(query);

    return response.items;
  }

  async searchPhysicalForMain(): Promise<Item> {
    const query: SearchQuery = {
      filters: [
        { field: 'hasOwnership', value: true },
        { field: 'status', comparison: '>=', value: ItemStatus.Bidding },
        { field: 'status', comparison: '<=', value: ItemStatus.OnSale },
        { field: 'isParticipatory', value: false }
      ],
      sorts: [{ field: 'createdAt', direction: DbSortDirection.Desc }],
      size: 1,
      distinct: 'group'
    };

    const response = await this.itemSearch.search(query);

    return response.items[0];
  }

  async searchDigitalItemsForMain(): Promise<Item[]> {
    const query: SearchQuery = {
      filters: [
        { field: 'hasOwnership', value: false },
        { field: 'saleByNikplace', value: true }
      ],
      sorts: [{ field: 'createdAt', direction: DbSortDirection.Desc }],
      size: 6,
      distinct: 'group'
    };

    const response = await this.itemSearch.search(query);

    return response.items;
  }

  async searchPhysicalItemsForMain(): Promise<Item[]> {
    const query: SearchQuery = {
      filters: [
        { field: 'hasOwnership', value: true },
        { field: 'saleByNikplace', value: true }
      ],
      sorts: [{ field: 'createdAt', direction: DbSortDirection.Desc }],
      size: 6,
      distinct: 'group'
    };

    const response = await this.itemSearch.search(query);

    return response.items;
  }

  async searchAllForMain(): Promise<Item[]> {
    const query: SearchQuery = {
      filters: [
        { field: 'isParticipatory', value: false },
        { field: 'status', comparison: '>=', value: ItemStatus.Bidding },
        { field: 'status', comparison: '<=', value: ItemStatus.OnSale }
      ],
      sorts: [{ field: 'createdAt', direction: DbSortDirection.Desc }],
      size: 6,
      distinct: 'group'
    };

    const response = await this.itemSearch.search(query);

    return response.items;
  }

  getDigitalList(request: DbListRequest = {}, saleType: SaleType): InfinityList<Item> {
    const query: DbQuery = {
      filters: [
        { field: 'isEdition', comparison: '==', value: true },
        { field: 'saleType', comparison: '==', value: saleType }
      ],
      sorts: [{ field: 'createdAt', direction: DbSortDirection.Desc }],
      limit: request.size ? +request.size : null
    };

    // if (request.cursor) {
    //   query.gt = await this.itemDb.getSnapshot(request.cursor);
    // }

    return makeDbInfinityList(this.itemDb, query);
  }

  getRealList(request: DbListRequest = {}, saleType: SaleType): InfinityList<Item> {
    const query: DbQuery = {
      filters: [
        { field: 'isEdition', comparison: '==', value: false },
        { field: 'saleType', comparison: '==', value: saleType }
      ],
      sorts: [{ field: 'createdAt', direction: DbSortDirection.Desc }],
      limit: request.size ? +request.size : null
    };

    // if (request.cursor) {
    //   query.gt = await this.itemDb.getSnapshot(request.cursor);
    // }

    return makeDbInfinityList(this.itemDb, query);
  }

  async listFromBigquery(query: SearchQuery): Promise<SearchResponse<Item>> {
    return this.itemSearch.search(query);
  }

  async listFromDb(query: DbQuery): Promise<DbListResponse<Item>> {
    return this.itemDb.list(query);
  }

  async listByUserId(userId: string, request: DbListRequest = {}): Promise<DbListResponse<Item>> {
    const query: DbQuery = {
      filters: [{ field: 'ownerId', comparison: '==', value: userId }],
      sorts: [{ field: 'lastSoldAt', direction: DbSortDirection.Desc }],
      limit: request.size ? +request.size : null
    };

    if (request.cursor) {
      query.gt = await this.itemDb.getSnapshot(request.cursor);
    }

    return this.itemDb.list(query);
  }

  async listOnAuctionByUserId(
    userId: string,
    request: DbListRequest = {}
  ): Promise<DbListResponse<Item>> {
    const query: DbQuery = {
      filters: [
        { field: 'ownerId', comparison: '==', value: userId },
        { field: 'status', comparison: '==', value: ItemStatus.Bidding }
      ],
      sorts: [{ field: 'createdAt', direction: DbSortDirection.Desc }],
      limit: request.size ? +request.size : null
    };

    if (request.cursor) {
      query.gt = await this.itemDb.getSnapshot(request.cursor);
    }

    return this.itemDb.list(query);
  }

  listOnAuctionByUserIdPaginationList(
    userId: string,
    request: DbListRequest = {}
  ): PaginationList<Item> {
    const query: DbQuery = {
      filters: [
        { field: 'ownerId', comparison: '==', value: userId },
        { field: 'status', comparison: '==', value: ItemStatus.Bidding }
      ],
      sorts: [{ field: 'createdAt', direction: DbSortDirection.Desc }],
      limit: request.size ? +request.size : null
    };

    return makeDbPaginationList(this.itemDb, query);
  }

  async listBidding(request: DbListRequest = {}): Promise<DbListResponse<Item>> {
    const query: DbQuery = {
      filters: [{ field: 'status', comparison: '==', value: ItemStatus.Bidding }],
      sorts: [{ field: 'createdAt', direction: DbSortDirection.Desc }],
      limit: request.size ? +request.size : null
    };

    if (request.cursor) {
      query.gt = await this.itemDb.getSnapshot(request.cursor);
    }

    return this.itemDb.list(query);
  }

  add(item: Partial<Item>): Promise<Item> {
    if (item.lastSoldAt) {
      item.lastSoldAt = new Date(item.lastSoldAt);
    }

    if (item.salesReserveAt) {
      item.salesReserveAt = new Date(item.salesReserveAt);
    }

    if (item.auctionStartAt) {
      item.auctionStartAt = new Date(item.auctionStartAt);
    }

    if (item.auctionEndAt) {
      item.auctionEndAt = new Date(item.auctionEndAt);
    }

    return this.itemDb.add(item);
  }

  update(id: string, update: Partial<Item>): Promise<void> {
    if (update.lastSoldAt) {
      update.lastSoldAt = new Date(update.lastSoldAt);
    }

    if (update.auctionStartAt) {
      update.auctionStartAt = new Date(update.auctionStartAt);
    }

    if (update.auctionEndAt) {
      update.auctionEndAt = new Date(update.auctionEndAt);
    }

    return this.itemDb.update(id, update);
  }

  increase(id: string, field: keyof Item, increase: number): Promise<void> {
    return this.itemDb.increase(id, field, increase);
  }

  delete(id: string, user?: User): Promise<void> {
    const update: Partial<Item> = { isDeleted: true, deletedAt: new Date(), ownerId: null };

    if (user) {
      update.deletedUser = user;
    }

    return this.itemDb.update(id, update);
  }

  realDelete(id: string): Promise<void> {
    return this.itemDb.delete(id);
  }

  async refund(id: string): Promise<void> {
    const query: DbQuery = {
      sorts: [{ field: 'createdAt', direction: DbSortDirection.Desc }],
      limit: 1
    };

    const response = await this.itemHistoryDb.list(query, { parentIds: [id] });

    const history = response.items[0];

    if (history) {
      const item = await this.itemDb.get(id);

      await this.transferNft(item, history.toUserId, history.fromUserId);

      const nikplaceUser = await this.userService.get(history.fromUserId);

      await this.itemDb.update(id, {
        lastSoldAt: null,
        status: ItemStatus.OnSale,
        ownerId: history.fromUserId,
        ownerName: nikplaceUser.name
      });

      await this.itemHistoryDb.delete(history.id, { parentIds: [id] });
    }
  }

  async deleteAllOfGroup(items: Item[], users?: User[]): Promise<string[]> {
    const itemIds = items.map((item) => item.id);

    const batchUpdates = itemIds.map((id, index) => {
      const update: DbBatchUpdate<Partial<Item>> = {
        id,
        data: { isDeleted: true, deletedAt: new Date(), ownerId: null }
      };

      if (users && users[index]) {
        update.data.deletedUser = users[index];
      }

      return update;
    });

    await this.itemDb.batch({
      update: batchUpdates
    });

    return itemIds;
  }

  async listHistory(itemId: string): Promise<ItemHistory[]> {
    const query: DbQuery = {
      sorts: [{ field: 'createdAt', direction: DbSortDirection.Desc }],
      limit: 7
    };

    const response = await this.itemHistoryDb.list(query, { parentIds: [itemId] });

    return response.items.reverse();
  }

  async listHistoryForTransfer(itemId: string): Promise<ItemHistory[]> {
    const query: DbQuery = {
      sorts: [{ field: 'createdAt', direction: DbSortDirection.Desc }]
    };

    const response = await this.itemHistoryDb.list(query, { parentIds: [itemId] });

    return response.items;
  }

  async listDeletedByGroup(group: string): Promise<Item[]> {
    const query: DbQuery = {
      filters: [
        { field: 'group', comparison: '==', value: group },
        { field: 'isDeleted', comparison: '==', value: true }
      ]
    };

    const response = await this.itemDb.list(query);

    return response.items;
  }

  async addHistory(itemId: string, entity: Partial<ItemHistory>): Promise<ItemHistory> {
    // for (let i = 0; i < entity.diamondChains.length; i++) {
    //   const diamondChain = entity.diamondChains[i];
    //
    //   let fromAddress;
    //   let toAddress;
    //
    //   if (diamondChain.fromUserId) {
    //     fromAddress = await this.userService.getAddress(diamondChain.fromUserId);
    //   } else {
    //     fromAddress = this.klaytnService.getDeployerAddress();
    //   }
    //
    //   if (diamondChain.toUserId) {
    //     toAddress = await this.userService.getAddress(diamondChain.toUserId);
    //   } else {
    //     toAddress = this.klaytnService.getDeployerAddress();
    //   }
    //
    //   await this.klaytnService.execute(
    //     fromAddress,
    //     'connectingBenefit',
    //     [{
    //       type: KlaytnInputType.Uint32,
    //       name: 'amount'
    //     }, {
    //       type: KlaytnInputType.Uint32,
    //       name: 'purchaseOrder'
    //     }, {
    //       type: KlaytnInputType.String,
    //       name: 'unit'
    //     }, {
    //       type: KlaytnInputType.Uint256,
    //       name: 'tokenId'
    //     }, {
    //       type: KlaytnInputType.Address,
    //       name: 'to'
    //     }],
    //     [
    //       diamondChain.amount,
    //       entity.diamondChains.length - i,
    //       'nik',
    //       makeTokenId(itemId),
    //       toAddress
    //     ]
    //   );
    // }

    return this.itemHistoryDb.add(entity, { parentIds: [itemId] });
  }

  async createNftToken(item: Item, owner?: User, artist?: User, gallery?: User) {
    if (!owner) {
      owner = await this.userService.get(item.ownerId);
    }

    if (!gallery) {
      gallery = await this.userService.get(item.galleryId);
    }

    if (!artist) {
      artist = await this.userService.get(item.artistId);
    }

    const uri = await this.klaytnService.uploadMetadata(gallery, artist, item);
    await this.update(item.id, { metadataUri: uri });

    if (item.isWantCard) {
      await this.klaytnService.createMultiToken(
        owner.klaytnAccount.address,
        makeMultiTokenId(item),
        item.initialSupply,
        uri
      );
      await delay(6000);
      const multiTokenDeployerAddress = await this.klaytnService.getMultiTokenDeployerAddress();
      await this.klaytnService.transferMultiToken(
        multiTokenDeployerAddress,
        multiTokenDeployerAddress,
        owner.klaytnAccount.address,
        makeMultiTokenId(item),
        item.initialSupply
      );
    } else {
      await this.klaytnService.createNftToken(
        owner.klaytnAccount.address,
        makeTokenId(item.id),
        uri
      );
    }
  }

  async transferNft(
    item: Item,
    toUserId: string,
    ownerId?: string,
    ownerAddress?: string
  ): Promise<void> {
    if (item.isNft) {
      const fromAddress = await this.userService.getAddress(toUserId);
      const toAddress = await this.userService.getAddress(item.ownerId);

      if (!ownerAddress && ownerId) {
        ownerAddress = await this.userService.getAddress(ownerId);
      }

      if (item.isWantCard) {
        try {
          await this.klaytnService.transferMultiToken(
            toAddress,
            toAddress,
            ownerAddress || fromAddress,
            makeMultiTokenId(item),
            item.initialSupply
          );
        } catch (err) {
          if (err.message === 'insufficient balance') {
            const multiTokenDeployerAddress =
              await this.klaytnService.getMultiTokenDeployerAddress();
            await this.klaytnService.transferMultiToken(
              multiTokenDeployerAddress,
              multiTokenDeployerAddress,
              ownerAddress || fromAddress,
              makeMultiTokenId(item),
              item.initialSupply
            );
          } else {
            throw err;
          }
        }
      } else {
        await this.klaytnService.transferNftToken(
          toAddress,
          toAddress,
          ownerAddress || fromAddress,
          makeTokenId(item.id)
        );
      }
    }
  }

  async transferNik(
    item: Item,
    toUserId: string,
    itemAmount: number,
    gas?: number
  ): Promise<ItemHistory> {
    const diamondChains: DiamondChain[] = [];

    if (item.paymentType === ItemPaymentType.Nik) {
      const fromAddress = await this.userService.getAddress(toUserId);
      const toAddress = await this.userService.getAddress(item.ownerId);

      itemAmount = +(itemAmount || getImmediateAmount(item));

      if (item.ownerId === toUserId) {
        if (gas > 0) {
          const nikplaceAddress = await this.klaytnService.getDeployerAddress();

          await this.klaytnService.transferNik(fromAddress, nikplaceAddress, gas);
          diamondChains.push({
            fromUserId: toUserId,
            toUserId: null,
            amount: gas,
            type: DiamondChainType.Deposit
          });
        }
      } else {
        const nikplaceAddress = await this.klaytnService.getDeployerAddress();

        await this.klaytnService.transferNik(fromAddress, nikplaceAddress, itemAmount + (gas || 0));
        diamondChains.push({
          fromUserId: toUserId,
          toUserId: null,
          amount: itemAmount + (gas || 0),
          type: DiamondChainType.Deposit
        });

        const itemHistories = await this.listHistoryForTransfer(item.id);

        if (itemHistories.length === 0) {
          await this.klaytnService.transferNik(nikplaceAddress, toAddress, itemAmount * 0.97);
          diamondChains.push({
            fromUserId: null,
            toUserId: item.ownerId,
            amount: itemAmount * 0.97,
            type: DiamondChainType.Withdraw
          });
        } else {
          const itemHistoryAmount = await this.getItemHistoryNikAmount(itemHistories[0]);

          const diffAmount = itemAmount - itemHistoryAmount;

          if (diffAmount <= 0) {
            await this.klaytnService.transferNik(nikplaceAddress, toAddress, itemAmount * 0.97);
            diamondChains.push({
              fromUserId: null,
              toUserId: item.ownerId,
              amount: itemAmount * 0.97,
              type: DiamondChainType.Withdraw
            });
          } else {
            await this.klaytnService.transferNik(
              nikplaceAddress,
              toAddress,
              roundNik(diffAmount * 0.75 + itemHistoryAmount)
            );
            diamondChains.push({
              fromUserId: null,
              toUserId: item.ownerId,
              amount: roundNik(diffAmount * 0.75 + itemHistoryAmount),
              type: DiamondChainType.Withdraw
            });

            const artistAddress = await this.userService.getAddress(item.artistId);

            await this.klaytnService.transferNik(
              nikplaceAddress,
              artistAddress,
              roundNik(diffAmount * 0.22 * 0.25)
            );
            diamondChains.push({
              fromUserId: null,
              toUserId: item.artistId,
              amount: roundNik(diffAmount * 0.22 * 0.25),
              type: DiamondChainType.Withdraw
            });

            if (item.galleryId) {
              const galleryAddress = await this.userService.getAddress(item.galleryId);

              await this.klaytnService.transferNik(
                nikplaceAddress,
                galleryAddress,
                roundNik(diffAmount * 0.22 * 0.75 * 0.25)
              );
              diamondChains.push({
                fromUserId: null,
                toUserId: item.galleryId,
                amount: roundNik(diffAmount * 0.22 * 0.75 * 0.25),
                type: DiamondChainType.Withdraw
              });
            }

            for (let i = 1; i < itemHistories.length; i++) {
              const itemHistory = itemHistories[i];

              const historyAddress = await this.userService.getAddress(itemHistory.toUserId);

              const amount = roundNik(diffAmount * 0.22 * 0.75 * Math.pow(0.75, i) * 0.25);

              await this.klaytnService.transferNik(nikplaceAddress, historyAddress, amount);
              diamondChains.push({
                fromUserId: null,
                toUserId: itemHistory.fromUserId,
                amount,
                type: DiamondChainType.Withdraw
              });
            }

            if (itemHistories.length > 0) {
              const index = itemHistories.length - 1;

              if (itemHistories[index].fromUserId !== item.galleryId) {
                const historyAddress = await this.userService.getAddress(
                  itemHistories[index].fromUserId
                );

                const amount = roundNik(
                  diffAmount * 0.22 * 0.75 * Math.pow(0.75, index + 1) * 0.25
                );

                await this.klaytnService.transferNik(nikplaceAddress, historyAddress, amount);
                diamondChains.push({
                  fromUserId: null,
                  toUserId: itemHistories[index].fromUserId,
                  amount,
                  type: DiamondChainType.Withdraw
                });
              }
            }
          }
        }
      }
    }

    if (item.ownerId !== toUserId) {
      return this.addHistory(item.id, {
        fromUserId: item.ownerId,
        toUserId,
        amount: itemAmount,
        paymentType: item.paymentType,
        diamondChains
      });
    }

    return null;
  }

  async updateTransferResult(
    item: Item,
    toUserId: string,
    goalStatus: ItemStatus,
    itemAmount: number,
    ownerId?: string
  ) {
    const owner = await this.userService.get(ownerId || toUserId);

    const itemUpdate: Partial<Item> = {
      ownerId: ownerId || toUserId,
      status: goalStatus,
      lastSoldAt: new Date(),
      auctionEndAt: null
    };

    if (owner) {
      itemUpdate.ownerName = owner.nickname || owner.name;
    }

    if (item.ownerId !== toUserId) {
      itemUpdate.amount = itemAmount;
    }

    await this.update(item.id, itemUpdate);
  }

  async transferOwner(
    item: Item,
    toUserId: string,
    goalStatus: ItemStatus,
    gas?: number,
    itemAmount?: number,
    ownerId?: string
  ): Promise<ItemHistory> {
    const fromAddress = await this.userService.getAddress(toUserId);
    const toAddress = await this.userService.getAddress(item.ownerId);
    let ownerAddress: string;

    if (ownerId) {
      ownerAddress = await this.userService.getAddress(ownerId);
    }

    itemAmount = +(itemAmount || getImmediateAmount(item));

    const diamondChains: DiamondChain[] = [];

    if (item.isNft) {
      if (item.isWantCard) {
        try {
          await this.klaytnService.transferMultiToken(
            toAddress,
            toAddress,
            ownerAddress || fromAddress,
            makeMultiTokenId(item),
            item.initialSupply
          );
        } catch (err) {
          if (err.message === 'insufficient-balance') {
            const multiTokenDeployerAddress =
              await this.klaytnService.getMultiTokenDeployerAddress();
            await this.klaytnService.transferMultiToken(
              multiTokenDeployerAddress,
              multiTokenDeployerAddress,
              ownerAddress || fromAddress,
              makeMultiTokenId(item),
              item.initialSupply
            );
          } else {
            throw err;
          }
        }
      } else {
        await this.klaytnService.transferNftToken(
          toAddress,
          toAddress,
          ownerAddress || fromAddress,
          makeTokenId(item.id)
        );
      }
    }

    if (item.paymentType === ItemPaymentType.Nik) {
      if (item.ownerId === toUserId) {
        if (gas > 0) {
          const nikplaceAddress = await this.klaytnService.getDeployerAddress();

          await this.klaytnService.transferNik(fromAddress, nikplaceAddress, gas);
          diamondChains.push({
            fromUserId: toUserId,
            toUserId: null,
            amount: gas,
            type: DiamondChainType.Deposit
          });
        }
      } else {
        const nikplaceAddress = await this.klaytnService.getDeployerAddress();

        await this.klaytnService.transferNik(fromAddress, nikplaceAddress, itemAmount + (gas || 0));
        diamondChains.push({
          fromUserId: toUserId,
          toUserId: null,
          amount: itemAmount + (gas || 0),
          type: DiamondChainType.Deposit
        });

        const itemHistories = await this.listHistoryForTransfer(item.id);

        if (itemHistories.length === 0) {
          await this.klaytnService.transferNik(nikplaceAddress, toAddress, itemAmount * 0.97);
          diamondChains.push({
            fromUserId: null,
            toUserId: item.ownerId,
            amount: itemAmount * 0.97,
            type: DiamondChainType.Withdraw
          });
        } else {
          const itemHistoryAmount = await this.getItemHistoryNikAmount(itemHistories[0]);

          const diffAmount = itemAmount - itemHistoryAmount;

          if (diffAmount <= 0) {
            await this.klaytnService.transferNik(nikplaceAddress, toAddress, itemAmount * 0.97);
            diamondChains.push({
              fromUserId: null,
              toUserId: item.ownerId,
              amount: itemAmount * 0.97,
              type: DiamondChainType.Withdraw
            });
          } else {
            await this.klaytnService.transferNik(
              nikplaceAddress,
              toAddress,
              roundNik(diffAmount * 0.75 + itemHistoryAmount)
            );
            diamondChains.push({
              fromUserId: null,
              toUserId: item.ownerId,
              amount: roundNik(diffAmount * 0.75 + itemHistoryAmount),
              type: DiamondChainType.Withdraw
            });

            const artistAddress = await this.userService.getAddress(item.artistId);

            await this.klaytnService.transferNik(
              nikplaceAddress,
              artistAddress,
              roundNik(diffAmount * 0.22 * 0.25)
            );
            diamondChains.push({
              fromUserId: null,
              toUserId: item.artistId,
              amount: roundNik(diffAmount * 0.22 * 0.25),
              type: DiamondChainType.Withdraw
            });

            if (item.galleryId) {
              const galleryAddress = await this.userService.getAddress(item.galleryId);

              await this.klaytnService.transferNik(
                nikplaceAddress,
                galleryAddress,
                roundNik(diffAmount * 0.22 * 0.75 * 0.25)
              );
              diamondChains.push({
                fromUserId: null,
                toUserId: item.galleryId,
                amount: roundNik(diffAmount * 0.22 * 0.75 * 0.25),
                type: DiamondChainType.Withdraw
              });
            }

            for (let i = 1; i < itemHistories.length; i++) {
              const itemHistory = itemHistories[i];

              const historyAddress = await this.userService.getAddress(itemHistory.toUserId);

              const amount = roundNik(diffAmount * 0.22 * 0.75 * Math.pow(0.75, i) * 0.25);

              await this.klaytnService.transferNik(nikplaceAddress, historyAddress, amount);
              diamondChains.push({
                fromUserId: null,
                toUserId: itemHistory.fromUserId,
                amount,
                type: DiamondChainType.Withdraw
              });
            }

            if (itemHistories.length > 0) {
              const index = itemHistories.length - 1;

              if (itemHistories[index].fromUserId !== item.galleryId) {
                const historyAddress = await this.userService.getAddress(
                  itemHistories[index].fromUserId
                );

                const amount = roundNik(
                  diffAmount * 0.22 * 0.75 * Math.pow(0.75, index + 1) * 0.25
                );

                await this.klaytnService.transferNik(nikplaceAddress, historyAddress, amount);
                diamondChains.push({
                  fromUserId: null,
                  toUserId: itemHistories[index].fromUserId,
                  amount,
                  type: DiamondChainType.Withdraw
                });
              }
            }
          }
        }
      }
    }

    await this.updateTransferResult(item, toUserId, goalStatus, itemAmount, ownerId);

    if (item.ownerId !== toUserId) {
      return this.addHistory(item.id, {
        fromUserId: item.ownerId,
        toUserId,
        amount: itemAmount,
        paymentType: item.paymentType,
        diamondChains
      });
    }

    return null;
  }

  async setTransactions(ids: string[]): Promise<void> {
    const beforeStatuses = [];

    for (let i = 0; i < ids.length; i++) {
      const id = ids[i];

      try {
        await this.itemDb.transaction((db) => async (transaction) => {
          const itemRef = db.doc(`items/${id}`);
          const itemSnap = await transaction.get(itemRef);

          const item = { ...itemSnap.data(), id: itemSnap.id } as Item;

          if (!(item.status === ItemStatus.OnSale || item.status === ItemStatus.Bidding)) {
            throw new Error(`${item.name} 작품은 이미 판매되었습니다.`);
          }

          beforeStatuses.push(item.status);

          transaction.update(itemRef, { status: ItemStatus.InTransaction });
        });
      } catch (err) {
        const promises = [];

        for (let j = i - 1; j >= 0; j--) {
          promises.push(this.itemDb.update(ids[j], { status: beforeStatuses[j] }));
        }

        await Promise.all(promises);

        throw err;
      }
    }
  }

  async getGroupViewCount(group: string): Promise<number> {
    const query: SearchQuery = {
      filters: [{ field: 'group', value: group }],
      size: 9999
    };

    const response = await this.itemSearch.search(query);

    return response.items.reduce((prev, curr) => prev + curr.viewCount, 0);
  }

  async checkSellingPartStatus(item: Item): Promise<ItemStatus> {
    const query: DbQuery = {
      filters: [
        { field: 'group', comparison: '==', value: item.group },
        { field: 'status', comparison: '==', value: ItemStatus.OnSale }
      ],
      limit: 1
    };

    const response = await this.itemDb.list(query);

    if (response.count === 1) {
      return ItemStatus.OnSale;
    }

    const queryWaiting: DbQuery = {
      filters: [
        { field: 'group', comparison: '==', value: item.group },
        { field: 'status', comparison: '==', value: ItemStatus.DepositWaiting }
      ],
      limit: 1
    };

    const responseWaiting = await this.itemDb.list(queryWaiting);

    if (responseWaiting.count === 1) {
      return ItemStatus.DepositWaiting;
    } else {
      return ItemStatus.Ready;
    }
  }

  async getItemNikAmount(item: Item): Promise<number> {
    let amount = item.amount;

    if (item.paymentType === ItemPaymentType.Usd) {
      const { ratio } = await this.configService.getUsdNikRatio();

      amount = item.amount / ratio;
    } else if (item.paymentType === ItemPaymentType.Won) {
      const { ratio } = await this.configService.getNikRatio();

      amount = item.amount / ratio;
    }

    return amount;
  }

  private async getItemHistoryNikAmount(itemHistory: ItemHistory): Promise<number> {
    let itemHistoryAmount = itemHistory.amount;

    if (itemHistory.paymentType === ItemPaymentType.Usd) {
      const { ratio } = await this.configService.getUsdNikRatio();

      itemHistoryAmount = itemHistory.amount / ratio;
    } else if (itemHistory.paymentType === ItemPaymentType.Won) {
      const { ratio } = await this.configService.getNikRatio();

      itemHistoryAmount = itemHistory.amount / ratio;
    }

    return itemHistoryAmount;
  }


  async getPromotionItems(): Promise<Item[]> {
    /*
        const query: SearchQuery = {
          filters: [
            { field: 'series', value: ItemNikSeries.HOL_NIK },
            { field: 'isEdition', value: false },
            { field: 'status', value: ItemStatus.OnSale },
            { field: 'hidden', comparison: '!=', value: true }
          ],
          sorts: [{ field: 'createdAt', direction: DbSortDirection.Desc }],
          size: 9
        };
        const response = await this.itemSearch.search(query);

        return response.items;
    */
    

    const items = [];

    const responses = await Promise.all([
      this.itemDb.get('QslQEepR9Ch5lolSe7S9'),
      this.itemDb.get('C8zSwX6cnI9wLDIufBha'),
      this.itemDb.get('t12qaS35YMhgSo9rtOvM'),
      this.itemDb.get('P5kFiMytgZSkmve9mY3j'),
      this.itemDb.get('psQ89orrPON3CL2noLYc'),
    ]);

    if (responses[0]) {
      items.push(responses[0]);
    }
    if (responses[1]) {
      items.push(responses[1]);
    }
    if (responses[2]) {
      items.push(responses[2]);
    }
    if (responses[3]) {
      items.push(responses[3]);
    }
    if (responses[4]) {
      items.push(responses[4]);
    }
    return items;
  }

  async searchRealItemForMain(user: User, type:string): Promise<Item[]> {
    const query: SearchQuery = {
      filters: [
        { field: 'isWantCard', value: false },
        { field: 'hidden', comparison: '!=', value: true },
        { field: 'isDeleted', comparison: '!=', value: true },
        { field: 'isParticipatory', value: false },
        { field: 'hasOwnership', value: true },
        { field: 'saleByNikplace', value: true }
      ],
      sorts: [{ field: 'createdAt', direction: DbSortDirection.Desc }],
      size: 9,
      distinct: 'group'
    };

    if (!user || user.level < UserLevel.Gold) {
      query.filters.push({ field: 'isForVip', comparison: '!=', value: true });
    }

    if(type != 'all'){
      query.filters.push({ parenthesis: 'start' });

      if(type == "real-painting"){
        query.filters.push({ logical:'and', field: 'assetType', comparison: '!=', value: 'real-figure' });
        query.filters.push({ logical:'and', field: 'assetType', comparison: '!=', value: 'real-goods' });
        query.filters.push({ logical:'and', field: 'assetType', comparison: '!=', value: 'real-etc' });
      } 
      if(type == "real-figure") query.filters.push({ field: 'assetType', comparison: '==', value: 'real-figure' });
      if(type == "real-goods") query.filters.push({ field: 'assetType', comparison: '==', value: 'real-goods' });
      if(type == "real-etc") query.filters.push({ field: 'assetType', comparison: '==', value: 'real-etc' });

      query.filters.push({ parenthesis: 'end' });
    }

    const response = await this.itemSearch.search(query);

    return response.items;
  }

  async searchDigitalItemForMain(user: User, type:string): Promise<Item[]> {
    const query: SearchQuery = {
      filters: [
        { field: 'isWantCard', value: false },
        { field: 'hidden', comparison: '!=', value: true },
        { field: 'isDeleted', comparison: '!=', value: true },
        { field: 'isParticipatory', value: false },
        { field: 'hasOwnership', value: false },
        { field: 'saleByNikplace', value: true }
      ],
      sorts: [{ field: 'createdAt', direction: DbSortDirection.Desc }],
      size: 9,
      distinct: 'group'
    };

    if (!user || user.level < UserLevel.Gold) {
      query.filters.push({ field: 'isForVip', comparison: '!=', value: true });
    }

    if(type == "digital-etc") query.filters.push({ field: 'assetType', comparison: '==', value: 'digital-etc' });

    const response = await this.itemSearch.search(query);

    return response.items;
  }

  async searchPopluarForMain(user: User): Promise<Item[]> {
    const query: SearchQuery = {
      filters: [
        { field: 'isWantCard', value: false },
        { field: 'hidden', comparison: '!=', value: true },
        { field: 'isDeleted', comparison: '!=', value: true },
        { field: 'isParticipatory', value: false },
        { field: 'hasOwnership', value: false },
      ],
      sorts: [
        { field: 'wishCount', direction: DbSortDirection.Desc },
        { field: 'createdAt', direction: DbSortDirection.Desc }
      ],
      size: 9,
      distinct: 'group'
    };

    if (!user || user.level < UserLevel.Gold) {
      query.filters.push({ field: 'isForVip', comparison: '!=', value: true });
    }

    const response = await this.itemSearch.search(query);
    
    return response.items;
  }
  

}
