import { Injectable } from '@angular/core';
import { FirestoreDbAdapter } from '../../../../../../../src/modules/firebase/firestore-db.adapter';

@Injectable({
  providedIn: 'root'
})
export class NgConfigDb extends FirestoreDbAdapter<any> {
  constructor() {
    super('config');
  }
}
