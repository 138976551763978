import { ApiResponse } from './types';
import { ApiAdapter } from './api.adapter';
import { DateObject } from '../types';

export class FrontendApiService {
  constructor(protected apiAdapter: ApiAdapter) {}

  bigquery(
    countQuery: string,
    query: string
  ): Promise<ApiResponse<{ count: number; docs: any[] }>> {
    return this.apiAdapter.post('bigquery', { countQuery, query });
  }

  bigqueryOne(query: string): Promise<ApiResponse<any[]>> {
    return this.apiAdapter.post('bigqueryOne', query);
  }

  checkAccountHolder(
    accountNumber: string,
    bankCode: string,
    accountHolder: string
  ): Promise<ApiResponse<{ result: boolean }>> {
    return this.apiAdapter.post('checkAccountHolder', { accountNumber, bankCode, accountHolder });
  }

  certifications(imp_uid: string): Promise<ApiResponse> {
    return this.apiAdapter.post('certifications', { imp_uid });
  }

  makeThumbnail(imageUrl: string): Promise<ApiResponse<string>> {
    return this.apiAdapter.post('makeThumbnail', { imageUrl });
  }

  deleteUser(userId: string): Promise<ApiResponse<string>> {
    return this.apiAdapter.post('deleteUser', { userId });
  }

  changePasswordUser(userId: string, password: string): Promise<ApiResponse<string>> {
    return this.apiAdapter.post('changePasswordUser', { userId, password });
  }

  sendMail(email: string, subject: string, title: string, text: string): Promise<ApiResponse> {
    return this.apiAdapter.post('sendMail', { email, subject, title, text });
  }

  stripeCreateItemCheckout(paymentId: string, type: string): Promise<ApiResponse> {
    return this.apiAdapter.post('stripeCreateItemCheckout', { paymentId, type });
  }

  invitationCode(userId: string, invitationCode: string): Promise<ApiResponse> {
    return this.apiAdapter.post('invitationCode', { userId, invitationCode });
  }

  makeOrderExcel(start: DateObject, end: DateObject): Promise<ApiResponse> {
    return this.apiAdapter.post('makeOrderExcel', { start, end });
  }

  deleteAllOfGroup(group: string): Promise<ApiResponse> {
    return this.apiAdapter.post('deleteAllOfGroup', { group });
  }

  sendAppPush(appPushManagementId: string): Promise<ApiResponse> {
    return this.apiAdapter.post('sendAppPush', { appPushManagementId });
  }
}
