import { Injectable } from '@angular/core';
import { ItemHistory } from '../../../../../../../src/entities/item/types';
import { FirestoreDbAdapter } from '../../../../../../../src/modules/firebase/firestore-db.adapter';

@Injectable({
  providedIn: 'root'
})
export class NgItemHistoryDb extends FirestoreDbAdapter<ItemHistory> {
  constructor() {
    super('itemHistories', { parentCollectionNames: ['items'] });
  }
}
