import { BaseEntity } from '../../core/base-entity';

export interface WonExchange extends BaseEntity {
  userId: string;
  bankCode: string;
  accountNumber: string;
  accountHolder: string;
  nikAmount: number;
  wonCommission: number;
  wonAmount: number;
  usdCommission: number;
  usdAmount: number;
  idImageUrl: string;
  status: WonExchangeStatus;
}

export enum WonExchangeStatus {
  Requested = 1,
  Completed = 31,
  Rejected = 61,
  Canceled = 91
}

export const WonExchangeStatusText = {
  [WonExchangeStatus.Requested]: 'Confirmation of payment',
  [WonExchangeStatus.Completed]: 'Order complete',
  [WonExchangeStatus.Rejected]: 'Order Rejected',
  [WonExchangeStatus.Canceled]: 'Order Cancellation'
};

export const WonExchangeStatusClass = {
  [WonExchangeStatus.Requested]: 'wait',
  [WonExchangeStatus.Completed]: 'complete',
  [WonExchangeStatus.Rejected]: 'fail',
  [WonExchangeStatus.Canceled]: 'fail'
};
