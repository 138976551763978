import { CdkDragEnd } from '@angular/cdk/drag-drop';
import { ComponentType } from '@angular/cdk/overlay';
import { CdkPortalOutlet, ComponentPortal } from '@angular/cdk/portal';
import {
  Component,
  OnInit,
  ViewEncapsulation,
  ViewChild,
  ViewContainerRef,
  Type,
  Inject,
  ComponentFactoryResolver,
  Injector
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NikDialogOptions, NIK_DIALOG_DATA } from '../../types';


@Component({
  selector: 'nik-bottom-up-dialog',
  templateUrl: './bottom-up-dialog.component.html',
  styleUrls: ['./bottom-up-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BottomUpDialogComponent implements OnInit {
  @ViewChild('container', { read: ViewContainerRef, static: true }) container: ViewContainerRef;

  private component: Type<any>;
  private readonly innerData: any;
  private readonly disableClose: boolean;

  constructor(
    public dialogRef: MatDialogRef<BottomUpDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { component: ComponentType<Type<any>>, options?: NikDialogOptions },
    private injector: Injector,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {
    const options = data.options || {} as NikDialogOptions;

    this.innerData = options.data;
    this.disableClose = options.disableClose;
  }

  ngOnInit(): void {
    const portalOutlet = new CdkPortalOutlet(this.componentFactoryResolver, this.container);

    const componentPortal = new ComponentPortal(
      this.data.component,
      undefined,
      this.createInjector()
    );

    this.component = portalOutlet.attach(componentPortal).instance;
  }

  onDragEnded(event: CdkDragEnd): void {
    if (event.distance.y > 50) {
      if ((this.component as any).close) {
        (this.component as any).close();
      } else {
        this.dialogRef.close();
      }

    } else {
      event.source._dragRef.reset();
    }
  }

  private createInjector(): Injector {
    return Injector.create({
      providers: [
        { provide: NIK_DIALOG_DATA, useValue: this.innerData }
      ],
      parent: this.injector
    });
  }
}
