import { HashMap } from '../../core/types';
import { KlaytnKip17QueryOptions } from './types';


export function makeQueryOptions(query: HashMap<any>): KlaytnKip17QueryOptions {
  const queryOptions: KlaytnKip17QueryOptions = {};

  const size = parseInt(query.size as string, 10);

  if (size > 0) {
    queryOptions.size = size;
  }

  if (query.cursor) {
    queryOptions.cursor = query.cursor;
  }

  return queryOptions;
}

export function roundNik(nik: number): number {
  const rounded = Math.round(nik * 1000000000000000) / 1000000000000000;

  if (rounded === 0) {
    return 0.000000000000001;
  }

  return rounded;
}
