import { Injectable, Inject } from '@angular/core';
import algoliasearch from 'algoliasearch/lite';
import { Environment } from '../../../../../../../src/core/environment';
import { AlgoliaSearchAdapter } from '../../../../../../../src/modules/aloglia/algolia-search.adapter';
import { ENVIRONMENT } from '../../../core/tokens';

@Injectable({
  providedIn: 'root'
})
export class NgItemSearch extends AlgoliaSearchAdapter {
  constructor(
    @Inject(ENVIRONMENT) private environment: Environment
  ) {
    super(
      algoliasearch(environment.algolia.appId, environment.algolia.searchKey) as any,
      'item',
      ['createdAt', 'modifiedAt', 'lastSoldAt', 'auctionStartAt', 'auctionEndAt']
    );
  }
}
