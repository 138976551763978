import { BaseEntity } from '../../core/base-entity';

export interface NikExchange extends BaseEntity {
  userId: string;
  depositorName: string;
  nikAmount: number;
  wonAmount: number;
  usdAmount: number;
  phoneNumber: string;
  method: NikExchangeMethod;
  status: NikExchangeStatus;
}

export enum NikExchangeMethod {
  Transfer = 'transfer',
  InApp = 'inApp',
  Paypal = 'paypal',
}

export enum NikExchangeStatus {
  Requested = 1,
  Completed = 31,
  Rejected = 61,
  Canceled = 91
}

export const NikExchangeMethodText = {
  [NikExchangeMethod.Transfer]: 'Deposit without a bankbook',
  [NikExchangeMethod.InApp]: 'Paying for the in app',
  [NikExchangeMethod.Paypal]: 'Paypal',
};

export const NikExchangeStatusText = {
  [NikExchangeStatus.Requested]: 'Confirmation of payment',
  [NikExchangeStatus.Completed]: 'Order complete',
  [NikExchangeStatus.Rejected]: 'Order Rejected',
  [NikExchangeStatus.Canceled]: 'Order Cancellation'
};

export const NikExchangeStatusClass = {
  [NikExchangeStatus.Requested]: 'wait',
  [NikExchangeStatus.Completed]: 'complete',
  [NikExchangeStatus.Rejected]: 'fail',
  [NikExchangeStatus.Canceled]: 'fail'
};
