import { ApiService } from '../../core/api/api.service';
import { ConfigService } from './config.service';


export class ApiRatioService {
  constructor(
    protected configService: ConfigService,
    protected apiService: ApiService
  ) {}

  getWonNikRatio(): Promise<{ ratio: number }> {
    return this.configService.getNikRatio();
  }

  async getUsdNikRatio(): Promise<{ ratio: number }> {
    const [wonNikRatio, wonUsdRatio] = await Promise.all([
      this.getWonNikRatio(),
      this.getWonUsdRatio()
    ]);

    if (wonNikRatio.ratio && wonUsdRatio.ratio) {
      const ratio = Math.round((wonNikRatio.ratio / wonUsdRatio.ratio) * 100) / 100;

      return { ratio };
    } else {
      throw new Error('환율 정보를 가져오지 못했습니다');
    }
  }

  async getWonUsdRatio(): Promise<{ ratio: number }> {
    try {
      return this.configService.getWonUsdRatio();

    } catch (err) {
      throw new Error('환율 정보를 가져오지 못했습니다');
    }
  }

  updateWonNikRatio(ratio: number): Promise<void> {
    if (typeof ratio !== 'number' || ratio <= 0) {
      throw new Error('invalid ratio');
    }

    return this.configService.updateNikRatio(ratio);
  }

  getWonKlayRatio(): Promise<{ ratio: number }> {
    return this.configService.getKlayRatio();
  }

  updateWonKlayRatio(ratio: number): Promise<void> {
    if (typeof ratio !== 'number' || ratio <= 0) {
      throw new Error('invalid ratio');
    }

    return this.configService.updateKlayRatio(ratio);
  }
}
