import firebase from 'firebase/app';

namespace firebaseAdapter {
  export let firestore: firebase.firestore.Firestore;
  export let auth: firebase.auth.Auth;
  export let storage: firebase.storage.Storage;
  export let functions: firebase.functions.Functions;
  export let analytics: firebase.analytics.Analytics;
  export let messaging: firebase.messaging.Messaging;
  export let FieldValue: typeof firebase.firestore.FieldValue;
  export let Timestamp: typeof firebase.firestore.Timestamp;

  export function init(app: {
    firestore?: firebase.firestore.Firestore,
    auth?: firebase.auth.Auth,
    storage?: firebase.storage.Storage,
    functions?: firebase.functions.Functions,
    analytics?: firebase.analytics.Analytics,
    messaging?: firebase.messaging.Messaging,
    FieldValue?: typeof firebase.firestore.FieldValue,
    Timestamp?: typeof firebase.firestore.Timestamp
  } = {}) {
    firestore = app.firestore;
    auth = app.auth;
    storage = app.storage;
    functions = app.functions;
    analytics = app.analytics;
    messaging = app.messaging;
    FieldValue = app.FieldValue;
    Timestamp = app.Timestamp;
  }
}

export default firebaseAdapter;
