import { Injectable } from '@angular/core';
import { ConfigService } from '../../../../../../../src/entities/config/config.service';
import { NgConfigDb } from './config.db';
import { NgUserService } from '../../../core/user/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class NgConfigService extends ConfigService {
  constructor(
    protected configDb: NgConfigDb,
    protected userService: NgUserService
  ) {
    super(configDb, userService);
  }
}
