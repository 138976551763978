import { Injectable } from '@angular/core';
import { User } from '../../../../../../../src/core/user/types';
import { FirestoreDbAdapter } from '../../../../../../../src/modules/firebase/firestore-db.adapter';

@Injectable({
  providedIn: 'root'
})
export class NgUserDb extends FirestoreDbAdapter<User> {
  constructor() {
    super('users');
  }
}
