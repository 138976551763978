import { Bank, HashMap, HotObservableOnce, InfinityList, Option, PaginationList } from './types';
import { BaseEntity } from './base-entity';
import { DbAdapter } from './db/db.adapter';
import { DbOptions, DbQuery } from './db/types';
import { AsyncSubject, BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { first, map, switchMap, take, tap } from 'rxjs/operators';

export function generateId(len: number): string {
  const arr = new Uint8Array((len || 40) / 2);
  const crypto = window.crypto || (window as any).msCrypto;
  crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join('');
}

function dec2hex(dec: number): string {
  return ('0' + dec.toString(16)).substr(-2);
}

export function isEmptyObject(obj: { [key: string]: any }) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
}
export function convertEnumToArray<T = any>(value: any): T[] {
  return Object.keys(value).map((key) => value[key]);
}
export function convertEnumToArraySort<T>(value: T): string[] {
  return Object.keys(value)
    .map((key) => value[key])
    .sort();
}

export function convertNumberEnumToArray<T>(value: T): T[keyof T][] {
  return Object.keys(value)
    .filter((key) => typeof value[key] === 'number')
    .map((key) => value[key]);
}

export function convertEnumToOptions<T>(value): Option[] {
  const array = convertEnumToArray(value);

  return array.map((v) => ({ value: v, text: v }));
}

export function convertUtf8ToHex(str: string): string {
  return Array.from(str)
    .map((c) =>
      c.charCodeAt(0) < 128
        ? c.charCodeAt(0).toString(16)
        : encodeURIComponent(c).replace(/\%/g, '').toLowerCase()
    )
    .join('');
}

export function convertUnixtimeToString(unixtime: number): string {
  return new Date(unixtime * 1000).toISOString();
}

export function pad(n: number, width = 4, z = '0') {
  const str = n.toString();
  return str.length >= width ? str : new Array(width - str.length + 1).join(z) + str;
}

export function findByIgnoreCaseKey<T = any>(object: HashMap<T>, key: string): T {
  const foundKey = Object.keys(object).find((k) => k.toLowerCase() === key.toLowerCase());

  if (foundKey) {
    return object[foundKey];
  }

  return null;
}

export function makeDbInfinityList<T extends BaseEntity>(
  dbAdapter: DbAdapter<T>,
  query: DbQuery = {},
  options?: DbOptions
): InfinityList<T> {
  const limit = query.limit || 20;
  const limitSubject = new BehaviorSubject<number>(limit);
  const hasMoreSubject = new BehaviorSubject<boolean>(false);

  let moreProcessing = false;

  return {
    valueChange: limitSubject.asObservable().pipe(
      switchMap((l) => dbAdapter.listChange({ ...query, limit: l }, options)),
      tap((response) => {
        hasMoreSubject.next(response.count === limitSubject.getValue());
        moreProcessing = false;
      }),
      map((response: any) => response.items)
    ),
    hasMoreChange: hasMoreSubject.asObservable(),
    more(): HotObservableOnce<void> {
      if (moreProcessing || !hasMoreSubject.getValue()) {
        return of(null);
      }

      moreProcessing = true;
      limitSubject.next(limitSubject.getValue() + limit);

      return hasMoreSubject.asObservable().pipe(
        take(1),
        map(() => {})
      );
    },
  };
}

export function makeArrayPaginationList<T>(
  observable: Observable<T[]>,
  limit: number
): PaginationList<T> {
  const pageSubject = new BehaviorSubject<number>(0);
  const totalCountSubject = new AsyncSubject<number>();

  return {
    valueChange: combineLatest([observable, pageSubject.asObservable()]).pipe(
      tap(([entities]) => {
        if (!totalCountSubject.closed) {
          totalCountSubject.next(entities.length);
          totalCountSubject.complete();
        }
      }),
      map(([entities, p]) => entities && entities.slice(p * limit, (p + 1) * limit))
    ),
    totalCountChange: totalCountSubject.asObservable(),
    pageChange: pageSubject.asObservable(),
    setPage: (p: number) => pageSubject.next(p),
    prev: () => {
      return pageSubject.asObservable().pipe(
        first(),
        map((p) => {
          pageSubject.next(p - 1);
          return p - 1;
        })
      );
    },
    next: () => {
      return pageSubject.asObservable().pipe(
        first(),
        map((p) => {
          pageSubject.next(p + 1);
          return p + 1;
        })
      );
    },
  };
}

export function formatDate(date: Date, format: string): string {
  const weekName = ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'];

  return format.replace(/(yyyy|yy|MM|dd|E|hh|mm|ss|a\/p)/gi, ($1) => {
    switch ($1) {
      case 'yyyy':
        return date.getFullYear().toString();
      case 'yy':
        return pad(date.getFullYear() % 1000, 2);
      case 'MM':
        return pad(date.getMonth() + 1, 2);
      case 'dd':
        return pad(date.getDate(), 2);
      case 'E':
        return weekName[date.getDay()];
      case 'HH':
        return pad(date.getHours(), 2);
      case 'hh':
        const h = date.getHours() % 12;
        return pad(h || 12, 2);
      case 'mm':
        return pad(date.getMinutes(), 2);
      case 'ss':
        return pad(date.getSeconds(), 2);
      case 'a/p':
        return date.getHours() < 12 ? '오전' : '오후';
      default:
        return $1;
    }
  });
}

export function delayTask(callback: () => void): void {
  setTimeout(() => {
    callback();
  });
}

export function delayMicrotask(callback: () => void): void {
  Promise.resolve(null).then(() => {
    callback();
  });
}

export function delay(milliseconds: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(null);
    }, milliseconds);
  });
}

export function distributeFileName(fileName: string): { name: string; ext: string } {
  const splitFileName = fileName.split('.');

  if (splitFileName.length === 1) {
    return { name: fileName, ext: null };
  }

  return {
    name: splitFileName.slice(0, -1).join('.'),
    ext: splitFileName[splitFileName.length - 1],
  };
}

export function checkMobile(): 'android' | 'ios' | 'other' {
  const varUA = navigator.userAgent.toLowerCase();

  if (varUA.indexOf('android') > -1) {
    return 'android';
  } else if (
    varUA.indexOf('iphone') > -1 ||
    varUA.indexOf('ipad') > -1 ||
    varUA.indexOf('ipod') > -1 ||
    varUA.indexOf('ios') > -1
  ) {
    return 'ios';
  } else {
    return 'other';
  }
}

export function checkApp(): boolean {
  return checkIOSApp() || checkAndroidApp();
}

export function checkIOSApp(): boolean {
  return navigator.userAgent.indexOf('_APP_NIKPLACE_iOS') > -1;
}

export function checkAndroidApp(): boolean {
  return navigator.userAgent.indexOf('_APP_NIKPLACE_ANDROID') > -1;
}

export function getKoreaDate(date: Date): Date {
  const utc = date.getTime() + date.getTimezoneOffset() * 60 * 1000;
  const KR_TIME_DIFF = 9 * 60 * 60 * 1000;
  return new Date(utc + KR_TIME_DIFF);
}

export function getBanks(): Bank[] {
  return [
    {
      id: '011',
      name: 'NATIONAL<br />AGRICULTURAL',
      icon: '/assets/icons/bank-05.png',
    },
    { id: '020', name: 'WOORI', icon: '/assets/icons/bank-15.png' },
    { id: '088', name: 'SHINHAN', icon: '/assets/icons/bank-11.png' },
    { id: '004', name: 'KOOKMIN', icon: '/assets/icons/bank-03.png' },
    { id: '081', name: 'KEB Hana Bank ', icon: '/assets/icons/bank-26.png' },
    { id: '027', name: 'CITIBANK', icon: '/assets/icons/bank-13.png' },
    { id: '003', name: 'IBK', icon: '/assets/icons/bank-04.png' },
    { id: '089', name: 'K BANK', icon: '/assets/icons/bank-20.png' },
    { id: '090', name: 'KAKAO BANK', icon: '/assets/icons/bank-19.png' },
    {
      id: '045',
      name: 'KOREAN FEDERATION<br/>OF COMMUNITY',
      icon: '/assets/icons/bank-09.png',
    },
    { id: '032', name: 'BUSAN', icon: '/assets/icons/bank-01.png' },
    { id: '039', name: 'KYONGNAM', icon: '/assets/icons/bank-01.png' },
    { id: '034', name: 'KWANGJU', icon: '/assets/icons/bank-17.png' },
    { id: '037', name: 'JEONBUK', icon: '/assets/icons/bank-17.png' },
    { id: '048', name: 'CREDIT UNION', icon: '/assets/icons/bank-12.png' },
    {
      id: '023',
      name: 'STANDARD<br/>CHARTERED FIRST<br/>BANK KOREA',
      icon: '/assets/icons/bank-24.png',
    },
    { id: '002', name: 'KDB', icon: '/assets/icons/bank-25.png' },
    { id: '031', name: 'DAEGU', icon: '/assets/icons/bank-06.png' },
    { id: '035', name: 'JEJU', icon: '/assets/icons/bank-18.png' },
    { id: '071', name: 'KOREA POST<br/>OFFICE', icon: '/assets/icons/bank-97.png' },
    { id: '007', name: 'SUHYUP', icon: '/assets/icons/bank-98.png' },
    { id: '008', name: 'KOREA EXIM<br/>BANK', icon: '/assets/icons/bank-99.png' },
    {
      id: '050',
      name: 'KOREA FEDERATION<br/>OF SAVINGS',
      icon: '/assets/icons/bank-08.png',
    },
  ];
}

export function getBank(id: string): Bank {
  return getBanks().find((bank) => bank.id === id);
}

export function openFullscreen(): void {
  document.documentElement.requestFullscreen();
}

export function closeFullscreen(): void {
  document.exitFullscreen();
}

export function trackByEntityId(index: number, entity: BaseEntity): string {
  return entity.id;
}

export function stripTag(html: string): string {
  if (typeof html !== 'string') {
    return html;
  }

  return html.replace(/(<br \/>)|(<[^>]p *>)/g, '%%br%%')
    .replace(/<[^>]*>/g, ' ')
    .replace(/&nbsp;/g, ' ')
    .replace(/\s+/g, ' ')
    .replace(/%%br%%/g, '\n');
}


export function createHourOption()  {
  const HourOptions = [];

  for (let i = 0; i <= 24; i++) {
    let j =  i.toString();
    if (j.length === 1){
      j = '0' + j;
    }
    HourOptions.push({text: j + ':00', value: j});
  }
  return HourOptions;
}
