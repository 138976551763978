import { Injectable } from '@angular/core';
import { RatioStore } from './ratio.store';

@Injectable({ providedIn: 'root' })
export class RatioService {
  constructor(private ratioStore: RatioStore) {}

  updateUsdNik(usdNik: number): void {
    this.ratioStore.update({ usdNik });
  }

  updateWonUsd(wonUsd: number): void {
    this.ratioStore.update({ wonUsd });
  }

  updateWonNik(wonNik: number): void {
    this.ratioStore.update({ wonNik });
  }
}
