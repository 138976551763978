import { KlaytnAccount } from '../../modules/klaytn/types';
import { User, UserLevel } from './types';
import { Option } from '../types';
import { convertEnumToArray, convertNumberEnumToArray } from '../utils';


export function removeUserSecret(user: User): User {
  const u = { ...user };

  if (user.klaytnAccount) {
    u.klaytnAccount = { address: user.klaytnAccount.address } as KlaytnAccount;
  }

  delete u.otpKey;
  delete u.uniqueInSite;

  return u;
}

export function makeResponseUser(user: Partial<User>): User {
  return {
    email: null,
    name: null,
    nickname: null,
    backgroundImageUrl: null,
    profilePictureUrl: null,
    followingCount: 0,
    followedCount: 0,
    collectCount: 0,
    bio: null,
    snsLinkUrl: null,
    type: null,
    otpKey: null,
    klaytnAccount: null,
    ...user
  } as User;
}

export function makeUserLevelOptions(): Option[] {
  return convertNumberEnumToArray(UserLevel).map(l => ({ text: makeUserLevelText(l), value: l }));
}

export function makeUserLevelText(level: UserLevel): string {
  switch (level) {
    case UserLevel.Bronze:
      return 'Bronze';
    case UserLevel.Silver:
      return 'Silver';
    case UserLevel.Gold:
      return 'Gold';
  }

  return '';
}
