import { Injectable } from '@angular/core';
import { ApiMyService } from '../../../../../../../src/core/user/api-my.service';
import { NgUserService } from '../../../core/user/services/user.service';
import { NgBidService } from '../../bid/services/bid.service';
import { NgItemService } from '../../item/services/item.service';
import { NgKlaytnService } from '../../klaytn/services/klaytn.service';
import { NgWishService } from '../../wish/services/wish.service';


@Injectable({
  providedIn: 'root'
})
export class NgApiMyService extends ApiMyService {
  constructor(
    protected userService: NgUserService,
    protected itemService: NgItemService,
    protected bidService: NgBidService,
    protected wishService: NgWishService,
    protected klaytnService: NgKlaytnService
  ) {
    super(userService, itemService, bidService, wishService, klaytnService);
  }
}
