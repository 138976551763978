import { Component, OnInit } from '@angular/core';
import { NgAuthService } from '../../../../../../nikplace/src/lib/core/auth/services/auth.service';
import { User } from '../../../../../../../src/core/user/types';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import qrcode from 'qrcode';
import { NgApiMyService } from '../../../../../../nikplace/src/lib/modules/my/services/api-my.service';
import { SubscriptionBaseComponent } from '../../../../../../nikplace/src/lib/core/base-components/subscription-base.component';
import { checkIOSApp, convertEnumToArray } from '../../../../../../../src/core/utils';
import { ItemNikSeries } from '../../../../../../../src/entities/item/types';
import { NgConfigService } from '../../../../../../nikplace/src/lib/modules/config/services/config.service';
import { filter, tap } from 'rxjs/operators';
import { Clipboard } from '@angular/cdk/clipboard';
import { RouterChildParamIdService } from '../../service/router-child-param-id.service';

@Component({
  selector: 'nik-mobile-toolbar',
  templateUrl: './mobile-toolbar.component.html',
  styleUrls: ['./mobile-toolbar.component.scss'],
})
export class MobileToolbarComponent extends SubscriptionBaseComponent implements OnInit {
  isShow = false;
  isHoverQR = false;
  isOpenDepth = false;
  chargeEnabled = false;
  attendanceCheckEnabled = false;

  isLoggedIn: boolean;
  user$: Observable<User> = this.authService.user$;

  myNik = 0;
  qrCodeDataUrl: string;

  nikSeries = convertEnumToArray(ItemNikSeries);

  mobileDigitalVisibility = true;
  mobileRealVisibility = true;
  mobileSupportVisibility = false;
  mobileMyVisibility = false;

  isIos = checkIOSApp();

  user: User;

  isMain: boolean;

  noticeId: string;

  constructor(
    private authService: NgAuthService,
    private router: Router,
    private translateService: TranslateService,
    private apiMyService: NgApiMyService,
    private configService: NgConfigService,
    private clipboard: Clipboard,
    private routerChildParamIdService: RouterChildParamIdService
  ) {
    super();
    this.isMain = this.checkIsMain(this.router.url);
  }

  ngOnInit(): void {
    this.subscription.add(this.init());
    this.subscription.add(this.initRouterEvent());
    this.initObservable('chargeEnabled', this.configService.getChargeEnabledChange());
    this.initObservable('attendanceCheckEnabled', this.configService.checkAttendanceCheckEnabled());
    this.initObservable('isLoggedIn', this.authService.isLoggedIn$);
    this.setObservable('noticeId', 'noticeId', this.routerChildParamIdService.noticeId$);
  }

  logout(): void {
    this.authService.logout().then(() => this.router.navigateByUrl('/'));
    this.close();
  }

  toggleIsShow(): void {
    this.isShow = !this.isShow;
  }

  toggleIsOpenDepth(): void {
    this.isOpenDepth = !this.isOpenDepth;
  }

  alert(): void {
    this.translateService.get('TOOLBAR.NAV.COMING_SOON').subscribe((res) => {
      window.alert(res);
    });
  }

  toggleIsHoverQr() {
    if (!this.authService.isLoggedIn) {
      this.router.navigateByUrl('/auth/login').then();
    } else {
      this.isHoverQR = !this.isHoverQR;
    }
  }

  close() {
    this.isShow = false;
    this.isOpenDepth = false;
    this.isHoverQR = false;
  }

  copied() {
    this.isHoverQR = false;
    alert('Copied!');
  }

  copyUserId() {
    this.clipboard.copy(this.user.id);

    alert('클립보드에 복사되었습니다.');
  }

  private init(): Subscription {
    return this.authService.user$.subscribe((user) => {
      this.user = user;
      this.getMyNick(user.id);
      this.setQrCode(user.klaytnAccount.address);
    });
  }

  private getMyNick(userId: string): void {
    this.apiMyService.getNik(userId).then((nik) => (this.myNik = nik));
  }

  private setQrCode(address: string): void {
    qrcode.toDataURL(address).then((result) => {
      this.qrCodeDataUrl = result;
    });
  }

  private initRouterEvent(): Subscription {
    return this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.isMain = this.checkIsMain(event.urlAfterRedirects);
      });
  }

  private checkIsMain(url: string): boolean {
    return url.indexOf('main') !== -1;
  }
}
