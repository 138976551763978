import { BaseEntity } from '../../core/base-entity';
import { Item } from '../item/types';

export type Notification =
  | NotificationBid
  | NotificationWinningBid
  | NotificationWish
  | NotificationCharged
  | NotificationTransferred
  | NotificationOrdered
  | NotificationPaypal
  | NotificationFollow;

interface NotificationBase extends BaseEntity {
  unread: boolean;
  type: NotificationType;
  info?: any;
}

interface NotificationBid extends NotificationBase {
  type: NotificationType.Bid;
  info: NotificationInfoBid;
}

interface NotificationWinningBid extends NotificationBase {
  type: NotificationType.WinningBid;
  info: NotificationInfoWinningBid;
}

interface NotificationWish extends NotificationBase {
  type: NotificationType.Wish;
  info: NotificationInfoWish;
}

interface NotificationCharged extends NotificationBase {
  type: NotificationType.Charged;
  info: NotificationInfoCharged;
}

interface NotificationTransferred extends NotificationBase {
  type: NotificationType.Transferred;
  info: NotificationInfoTransferred;
}

interface NotificationOrdered extends NotificationBase {
  type: NotificationType.Ordered;
  info: NotificationInfoOrdered;
}

interface NotificationPaypal extends NotificationBase {
  type: NotificationType.Paypal;
  info: NotificationInfoPaypal;
}

interface NotificationFollow extends NotificationBase {
  type: NotificationType.Follow;
  info: NotificationInfoFollow;
}

export enum NotificationType {
  Bid = 'bid',
  WinningBid = 'winningBid',
  Wish = 'wish',
  Charged = 'charged',
  Exchanged = 'exchanged',
  Transferred = 'transferred',
  Ordered = 'ordered',
  Paypal = 'paypal',
  Follow = 'follow',
}

export interface NotificationInfoBid {
  item: Item;
  amount: number;
  userName: string;
}

export interface NotificationInfoWinningBid {
  item: Item;
  amount: number;
  userName: string;
}

export interface NotificationInfoWish {
  userId: string;
  name: string;
  nickname: string;
  itemId: string;
}

export interface NotificationInfoCharged {
  userId: string;
  nikAmount: number;
}

export interface NotificationInfoTransferred {
  endDate: Date;
}

export interface NotificationInfoOrdered {
  items: Item[];
}
export interface NotificationInfoPaypal {
  amount: string;
}

export interface NotificationInfoFollow {
  followedUserId: string;
}
