import { Component, OnInit } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { NavigationStart, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Location } from '@angular/common';

@Component({
  selector: 'nik-layout-page',
  templateUrl: './layout-page.component.html',
  styleUrls: ['./layout-page.component.scss'],
})
export class LayoutPageComponent implements OnInit {
  isMyPage$ = new BehaviorSubject<boolean>(false);
  constructor(private router: Router, private location: Location) {
    this.checkIsMyPage(this.router.url);
  }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.checkIsMyPage(event.url);
      }
    });
  }

  private checkIsMyPage(url: string) {
    this.isMyPage$.next(url.indexOf('/my') !== -1);
  }

  openSNS(type:string){
    if(type == "telegram") window.open('https://t.me/NIKProtocol', '_blank');
    if(type == "twitter") window.open('https://twitter.com/Nikprotocol', '_blank');
  }
}
