import { Injectable } from '@angular/core';
import { ItemService } from '../../../../../../../src/entities/item/item.service';
import { NgUserService } from '../../../core/user/services/user.service';
import { NgKlaytnService } from '../../klaytn/services/klaytn.service';
import { NgItemHistoryDb } from './item-history.db';
import { NgItemDb } from './item.db';
import { NgItemSearch } from './item.search';
import { NgItemBigSearch } from './item.big-search';
import { NgConfigService } from '../../config/services/config.service';


@Injectable({
  providedIn: 'root'
})
export class NgItemService extends ItemService {
  constructor(
    protected itemDb: NgItemDb,
    protected itemHistoryDb: NgItemHistoryDb,
    protected itemSearch: NgItemSearch,
    protected userService: NgUserService,
    protected klaytnService: NgKlaytnService,
    protected itemBigSearch: NgItemBigSearch,
    protected configService: NgConfigService
  ) {
    super(itemDb, itemHistoryDb, itemSearch, userService, klaytnService, itemBigSearch, configService);
  }
}
