import { Directive, OnDestroy } from '@angular/core';
import { SubscriptionBaseService } from '../base-services/subscription-base.service';

@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class SubscriptionBaseComponent extends SubscriptionBaseService implements OnDestroy {
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
