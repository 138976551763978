import { Injectable } from '@angular/core';
import { Wish } from '../../../../../../../src/entities/wish/types';
import { FirestoreDbAdapter } from '../../../../../../../src/modules/firebase/firestore-db.adapter';

@Injectable({
  providedIn: 'root'
})
export class NgWishDb extends FirestoreDbAdapter<Wish> {
  constructor() {
    super('wishes');
  }
}
