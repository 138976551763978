import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation, Renderer2, Input, Output } from '@angular/core';
import { NgAuthService } from '../../../../../../nikplace/src/lib/core/auth/services/auth.service';
import { User } from '../../../../../../../src/core/user/types';
import { Observable, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import qrcode from 'qrcode';
import { NgApiMyService } from '../../../../../../nikplace/src/lib/modules/my/services/api-my.service';
import {
  SubscriptionBaseComponent
} from '../../../../../../nikplace/src/lib/core/base-components/subscription-base.component';
import { checkIOSApp } from '../../../../../../../src/core/utils';
import { NgConfigService } from '../../../../../../nikplace/src/lib/modules/config/services/config.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { RouterChildParamIdService } from '../../service/router-child-param-id.service';
import { NikholderDialogComponent } from '../nikholder-dialog/nikholder-dialog.component';
import { NgRouteService } from '../../../../../../nikplace/src/lib/core/services/route.service';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { makeItemSeriesText } from '../../../../../../../src/entities/item/utils';
import { NgUserService } from '../../../../../../nikplace/src/lib/core/user/services/user.service';
import {
  NgItemSeriesCountService
} from '../../../../../../nikplace/src/lib/modules/item/services/item-series-count.service';
import { ItemSeriesCount } from '../../../../../../../src/entities/item/types';
import { NgCollectionService } from '../../../../../../nikplace/src/lib/modules/collection/services/collection.service';
import { Collection } from '../../../../../../../src/entities/collection/types';
import { makeUserLevelText } from '../../../../../../../src/core/user/utils';
import { NgDialogService } from '../../../modules/dialog/services/dialog.service';

import { Router,NavigationEnd  } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

declare const getParamMap:any;

@Component({
  selector: 'nik-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ToolbarComponent extends SubscriptionBaseComponent implements OnInit {

  isMainPage$ = new BehaviorSubject<boolean>(false);
  
  isShow = false;
  isHoverQR = false;
  isOpenDepth = false;
  chargeEnabled = false;
  attendanceCheckEnabled = false;

  isLoggedIn: boolean;
  pageName: string;

  user$: Observable<User> = this.authService.user$;

  myNik = 0;
  qrCodeDataUrl: string;

  mobileDigitalVisibility = false;
  mobileRealVisibility = true;
  mobileSupportVisibility = false;
  mobileMyVisibility = false;

  /* PC */
  mainMenuVisibility = false;
  snsMenuVisibility = false;
  aboutUsVisibility = false;
  newsVisibility = false;
  contactVisibility = false;
  /* Mobile & Tab */
  mobileAboutUsVisibility = false;
  mobileNewsVisibility = false;
  mobileContactVisibility = false;

  isIos = checkIOSApp();

  user: User;

  noticeId: string;
  puchaseLink: string;
  currentLang: string;

  searchForm = new FormGroup({
    keyword: new FormControl(null)
  });

  makeItemSeriesText = makeItemSeriesText;
  makeUserLevelText = makeUserLevelText;

  filteredSeriesItems: Collection[] = [];
  filteredGalleries: Collection[] = [];
  filteredArtists: Collection[] = [];

  private seriesItems: Collection[] = [];
  private galleries: Collection[] = [];
  private artists: Collection[] = [];

  buyParams: any = {};
  auctionParams: any = {};

  constructor(
    private authService: NgAuthService,
    private translateService: TranslateService,
    private routerChildParamIdService: RouterChildParamIdService,
    private routeService: NgRouteService,
    private router: Router
  ) {
    super();
    this.checkMainPage(this.router.url);

    this.translateService.onLangChange.subscribe(lang=>{
      this.currentLang = lang.lang;
    })
  }

  ngOnInit(): void {
    this.initObservable('isLoggedIn', this.authService.isLoggedIn$);
    this.setObservable('noticeId', 'noticeId', this.routerChildParamIdService.noticeId$);
    this.currentLang = 'en';

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.checkMainPage(event.url);
      }
    });
    
  }

  logout(): void {
    this.authService.logout().then(() => this.routeService.navigate(['/main']));
    this.close();
  }

  toggleIsShow(): void {
    this.isShow = !this.isShow;
  }

  toggleIsOpenDepth(): void {
    this.isOpenDepth = !this.isOpenDepth;
  }

  alert(): void {
    this.translateService.get('TOOLBAR.NAV.COMING_SOON').subscribe((res) => {
      window.alert(res);
    });
  }

  toggleIsHoverQr() {
    if (!this.authService.isLoggedIn) {
      this.routeService.navigate(['/auth/login']);
    } else {
      this.isHoverQR = !this.isHoverQR;
    }
  }

  close() {
    this.isShow = false;
    this.isOpenDepth = false;
    this.isHoverQR = false;
  }


  openSNS(type:string){
    if(type == "telegram") window.open('https://t.me/NIKProtocol', '_blank');
    if(type == "twitter") window.open('https://twitter.com/Nikprotocol', '_blank');
  }
  
  changeLang(value:string) {
    this.translateService.use(value);
    window.scrollTo(0,0);     
  }

  private checkMainPage(url: string) {
    this.isMainPage$.next(url.indexOf('/main') !== -1);
  }

  moveMenu(type: string){
    const area = document.getElementById(type) as HTMLInputElement | null;
    if (area != null) {
      area.scrollIntoView();
      this.isShow = false;
    }
  }
}
