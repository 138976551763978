<header class="toolbar-box" [class.toolbar-box2]="isMainPage$ | async" fxLayout="row" fxLayoutAlign="start center">

  <div class="toolbar" fxHide.lt-lg>

    <div class="left_area">
      <a routerLink="/main">
        NIKPROTOCOL
        <!-- <img class="main_logo" *ngIf="isMainPage$.value" nikSrc="/assets/logos/toolbar_nikprotocol_logo.png"/>
        <img class="main_logo" *ngIf="!isMainPage$.value" nikSrc="/assets/logos/toolbar_nikpro_logo_black.png"/> -->
      </a>
    </div>

    <div class="right_area">
      <div [matMenuTriggerFor]="mainMenu">
        <i class="xi-bars"></i>
      </div>
    </div>   

  </div>

  <div fxHide.lg>

    <div class="mobile_toolbar" *ngIf="isMainPage$.value">

      <div class="left_area">
        <a routerLink="/main">NIKPROTOCOL</a>
        <!-- <img class="main_logo" nikSrc="/assets/logos/toolbar_nikprotocol_logo_m.png"> -->
      </div>

      <div class="right_area">

        <div (click)="toggleIsShow()">
          <img *ngIf="isMainPage$.value" nikSrc="/assets/icons/menu_icon_m.png"/>
          <img *ngIf="!isMainPage$.value" nikSrc="/assets/icons/menu_icon_m_black.png"/>
        </div>
      </div>
    </div>

    <div class="mobile_toolbar" *ngIf="!isMainPage$.value">

      <div class="prev_btn" onclick="history.back();">
        <i class="xi-angle-left"></i>
      </div>

      <div class="mobile_menu_title">{{ pageName }}</div>

      <div class="menu_btn">
        <div (click)="toggleIsShow()">
          <img *ngIf="isMainPage$.value" nikSrc="/assets/icons/menu_icon_m.png"/>
          <img *ngIf="!isMainPage$.value" nikSrc="/assets/icons/menu_icon_m_black.png"/>
        </div>
      </div>

    </div>

  </div>

  <mat-menu #mainMenu class="pc_toolbar_menu hover_menu" xPosition="after">
    <ul>
      <li class="title_menu" (click)="moveMenu('white_paper_area')">WHITE PAPER</li>
      <li class="title_menu" (click)="moveMenu('business_area')">BUSINESS PLAN</li>
      <li class="title_menu" (click)="moveMenu('nikplace_area')">NIKPLACE</li>
<!--
      <li class="title_menu" (click)="moveMenu('expansion_area')">UORDER</li>
      <li class="title_menu" (click)="moveMenu('expansion_area')">WATCH</li>
-->
      <li class="title_menu" (click)="moveMenu('footer')">CONTACT</li>           
    </ul>
  </mat-menu>
 
  <nav class="mobile_toolbar_menu" [class.show]="isShow">
              
    <div class="mobile_toolbar_header">
        <div class="logo_area">
          <img class="main_logo" nikSrc="/assets/logos/toolbar_nikprotocol_logo_m.png">
        </div>
        <div class="cancel_area" (click)="toggleIsShow()">
          <i class="xi-close-thin"></i>
        </div>
    </div>
        
    <div class="menu_area">

      <div class="toolbar_area">
        <ul>
          <li class="title_menu" (click)="moveMenu('white_paper_area')">WHITE PAPER</li>
          <li class="title_menu" (click)="moveMenu('business_area')">BUSINESS PLAN</li>
          <li class="title_menu" (click)="moveMenu('nikplace_area')">NIKPLACE</li>
<!--
          <li class="title_menu" (click)="moveMenu('expansion_area')">UORDER</li>
          <li class="title_menu" (click)="moveMenu('expansion_area')">WATCH</li>
-->
          <li class="title_menu" (click)="moveMenu('footer')">CONTACT</li>                    
        </ul>
      </div>    
      <div class="sns_area">
        <ul>          
          <li class="sns" (click)="openSNS('telegram');">
              <i class="xi-telegram"></i>
          </li>
          <li class="sns" (click)="openSNS('twitter');">
            <i class="xi-twitter"></i>
          </li>                       
        </ul>
      </div>

    </div>
  </nav>     

</header>

<mat-menu #notificationMenu class="notification-menu" xPosition="before">
  <nik-notification-list></nik-notification-list>
</mat-menu>

