import { BaseEntity } from '../../core/base-entity';
import { User } from '../../core/user/types';

export interface Item extends BaseEntity {
  artistId: string;
  artistName: string;
  ownerId: string;
  ownerName: string;
  galleryId: string;
  name: string;
  description: string;
  mediaType: ItemMediaType;
  imageUrl: string;
  thumbImageUrl: string;
  videoUrl: string;
  backgroundColor: string;
  wishCount: number;
  viewCount: number;
  group: string; // 300 or 1000개 대표 이름(key)
  groupName: string;
  series: string; // 닉플레이스 자체 카테고리 일반, 베어닉, 몽키, 전속작가
  lastSoldAt: Date;
  salesReserveAt?: Date;
  hidden: boolean;

  realAmount: number;
  fairAmount: number;
  amount: number;
  paymentType: ItemPaymentType;
  status: ItemStatus;
  isParticipatory: boolean;
  participatory: number;

  isNft: boolean;
  isWantCard: boolean;
  initialSupply: number;
  hasOwnership: boolean; // true: 실물 작품 소유권, false: 디지털 작품 에디션

  assetType: AssetType; //  필드가 없을 수 있음(2024.01 이후 생성된 필드임)

  saleByNikplace: boolean; // true: 닉플레이스 등록, false: 닉플레이스 등록 X
  isEdition: boolean; // true: 분할 소유권, false: 전체 소유권
  saleType: SaleType;
  isDelivery: boolean; // 배송가능 여부

  // nft 인 경우
  totalPieces: number;
  currentPieceNumber: number;
  metadataUri: string;

  // saleType이 auction 인 경우
  auctionStartAt: Date;
  auctionEndAt: Date;
  auctionStartAmount: number;
  highestBidAmount: number;
  auctionImmediateAmount: number;
  immediateAmountVisibility: boolean;

  isOpenSea: boolean;
  isOnlyOne: boolean;

  uuid: string;

  allSoldOut: boolean;
  usedBy?: string;

  isForVip: boolean;

  isDeleted: boolean;
  deletedAt: Date;
  deletedUser: User;
}

export interface ItemHistory extends BaseEntity {
  fromUserId: string;
  toUserId: string;
  amount: number;
  paymentType: ItemPaymentType;
  diamondChains: DiamondChain[];
}

export enum AssetType {
  RealPainting = 'real-painting',
  RealFigure = 'real-figure',
  RealGoods = 'real-goods',
  RealEtc = 'real-etc',
  DigitalCollection = 'digital-collection',  
  DigitalEtc = 'digital-etc'
}

export enum SaleType {
  Auction = 'auction',
  General = 'general'
}

export enum AuctionStatus {
  Ready = 'ready',
  Bidding = 'bidding',
  Succeed = 'succeed',
  Failed = 'failed'
}

export enum ItemStatus {
  Ready = 1,

  Bidding = 31,
  OnSale = 41,
  InTransaction = 25,

  WinningBid = 11,
  FailureBid = 12,
  DepositWaiting = 21,

  Disabled = 91
}

export const ItemStatusText = {
  [ItemStatus.Ready]: 'Ready',
  [ItemStatus.Bidding]: 'Bidding',
  [ItemStatus.OnSale]: 'On Sale', // 경매중
  [ItemStatus.WinningBid]: 'Winning Bid', // 낙찰
  [ItemStatus.FailureBid]: 'Failure Bid', // 유찰
  [ItemStatus.DepositWaiting]: 'Deposit Waiting',
  [ItemStatus.Disabled]: 'Disabled'
};

export const ItemStatusClass = {
  [ItemStatus.Ready]: 'wait',
  [ItemStatus.Disabled]: 'cancel',
  [ItemStatus.WinningBid]: 'complete',
  [ItemStatus.FailureBid]: 'fail'
};

export interface KlaytnMetadata {
  attributes: KlaytnMetadataAttribute[];
  background_color: string;
  created_date: string;
  description: string;
  value?: string;
  image?: string;
  animation?: string;
  name: string;
  send_friend_only: boolean;
  sendable: boolean;
  special?: string;
  license?: string;
}

export interface KlaytnMetadataAttribute {
  trait_type: string;
  value: string;
}

export enum ItemPaymentType {
  Nik = 'nik',
  Won = 'won',
  Usd = 'usd',
  Inquiry = 'inquiry'
}

export interface ImagePopUpData {
  name: string;
  artistName: string;
  imageUrl: string;
}

export interface ItemFilterType {
  type?: string;
  category?: string;
  product?: string;
  subProduct?: string;
  piece?: string;
  status?: string;
  series?: string;
  currency?: string;
  owner?: string;
  query?: string;
  id?: string;
}

export enum ItemNikSeries {
  PAUL_NIK = 'paul nik',
  HOL_NIK = 'hol nik',
  BEAR_NIK = 'bear nik',
  MONKEY_NIK = 'monkey nik',
  EXCLUSIVE_ARTIST = 'exclusive artist',
  BELLYGOM = 'bellygom',
  MR_BLANK = 'mr.blank',
  HONG_GILDONG_CAT = 'hong gildong cat',
  BBIGGU = 'bbiggu',
  RUN_HANY = 'run hany'
}

export interface DiamondChain {
  fromUserId: string;
  toUserId: string;
  amount: number;
  type: DiamondChainType;
}

export enum DiamondChainType {
  Deposit = 'deposit',
  Withdraw = 'withdraw'
}

export enum ItemMediaType {
  Image = 'image',
  Video = 'video'
}

export enum ItemSort {
  New = 'new',
  Old = 'old',
  Low = 'low',
  High = 'high',
  Wish = 'wish',
  Sold = 'sold'
}

export interface ItemGroupCount extends BaseEntity {
  soldCount: number;
  totalCount: number;
  viewCount: number;
  wishCount: number;

  name?: string;
  thumbImageUrl?: string;
  artistId?: string;
  artistName?: string;
  mainId?: string;
  isWantCard?: boolean;
  isNft?: boolean;
  initialSupply?: number;
  hasOwnership?: boolean; // true: 실물 작품 소유권, false: 디지털 작품 에디션
  saleByNikplace?: boolean; // true: 닉플레이스 등록, false: 닉플레이스 등록 X
  isEdition?: boolean; // true: 분할 소유권, false: 전체 소유권
  saleType?: SaleType;
  isDelivery?: boolean; // 배송가능 여부
  totalPieces?: number;
  isParticipatory?: boolean;
  galleryId: string;
  hidden?: boolean;
  allSoldOut?: boolean;
  usedBy?: string;
  isForVip?: boolean;
  isDeleted?: boolean;

  isOpenSea: boolean;
  amount: number;
  paymentType: ItemPaymentType;
  status: ItemStatus;
  series: string; // 닉플레이스 자체 카테고리 일반, 베어닉, 몽키, 전속작가
  salesReserveAt?: Date;
  mediaType: ItemMediaType;
  videoUrl: string;
  ownerId: string;
}

export interface ItemSeriesCount extends BaseEntity {
  imageUrl: string;
  count: number;
  backgroundImageUrl: string;
  title: string;
  intro: string;
  superior: boolean;
  order: number;
  orderCount: number;
}
