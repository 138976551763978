import { Observable, partition } from 'rxjs';
import { distinctUntilChanged, filter, first, map, switchMap, tap } from 'rxjs/operators';
import { StoreAdapter } from '../store/store.adapter';
import { User, UserType } from '../user/types';
import { UserService } from '../user/user.service';
import { removeUserSecret } from '../user/utils';
import { checkAndroidApp, checkIOSApp } from '../utils';
import { AuthAdapter } from './auth.adapter';
import {
  AuthState,
  authStoreNotLoggedInValue,
  authStoreDbLoadedValue,
  authStoreLoggedInValue,
} from './auth.state';
import { AuthResponse, AuthSession } from './types';

export class AuthService {
  state$: Observable<AuthState> = this.authStore.select((state) => state);
  userOnce = this.authStore.select((state) => state.user as User).pipe(first<User>(Boolean));
  userOnce2 = this.authStore.select().pipe(
    first((state) => Boolean(state.authLoaded)),
    map((state) => state.user)
  );
  user$: Observable<User> = this.authStore
    .select((state) => state.user as User)
    .pipe(filter<User>(Boolean));
  isLoggedIn$: Observable<boolean> = this.authStore.select().pipe(
    filter((state) => Boolean(state.authLoaded)),
    map((state) => state.isLoggedIn),
    distinctUntilChanged()
  );

  constructor(
    private auth: AuthAdapter,
    private authStore: StoreAdapter<AuthState>,
    protected userService: UserService
  ) {}

  get user(): User {
    return this.authStore.getValue().user as User;
  }

  get isLoggedIn(): boolean {
    return this.authStore.getValue().isLoggedIn;
  }

  init(): void {
    const [loggedIn$, notLoggedIn$] = partition<AuthSession>(this.auth.sessionChange(), Boolean);

    loggedIn$
      .pipe(
        tap(() => this.authStore.update(authStoreLoggedInValue)),
        switchMap((authSession: AuthSession) => {
          try {
            if (checkIOSApp()) {
              (window as any).webkit.messageHandlers.doLogin.postMessage(authSession.accessToken);
            } else if (checkAndroidApp()) {
              (window as any).Android.doLogin(authSession.accessToken);
            }

          } catch (err) {}

          return this.userService.getChange(authSession.userId);
        })
      )
      .subscribe((user) => {
        this.authStore.update({ ...authStoreDbLoadedValue, user });
      });

    notLoggedIn$.subscribe((res) => {
      this.authStore.update(authStoreNotLoggedInValue);
    });
  }

  async checkToken(token: string): Promise<AuthResponse> {
    try {
      const session = await this.auth.checkToken(token);

      const user = await this.userService.get(session.userId);

      return { ...session, user };
    } catch (err) {
      return null;
    }
  }

  async signUp(email: string, password: string, userInfo: Partial<User>): Promise<AuthResponse> {
    const session = await this.auth.signUpWithEmailAndPassword(email, password);

    const user = await this.userService.add({ ...userInfo, email, id: session.userId });

    this.authStore.update({ ...authStoreDbLoadedValue, user });

    return { ...session, user };
  }

  async login(email: string, password: string, type?: UserType): Promise<AuthResponse> {
    const session = await this.auth.loginWithEmailAndPassword(email, password);

    const user = await this.userService.get(session.userId);

    if (type && user.type !== type) {
      const error: any = new Error('유저 타입이 맞지 않습니다');
      error.code = 'auth/invalid-type';
      throw error;
    }

    return { ...session, user: removeUserSecret(user) };
  }

  async logout(): Promise<void> {
    await this.auth.logout();

    try {
      if (checkIOSApp()) {
        (window as any).webkit.messageHandlers.doLogout.postMessage('');
      } else if (checkAndroidApp()) {
        (window as any).Android.doLogout('');
      }

    } catch (err) {}

    this.authStore.update(authStoreNotLoggedInValue);
  }

  async withdraw(userId: string, email: string, password: string): Promise<void> {
    await this.auth.reauthenticateWithPassword(email, password);
    await this.userService.delete(userId);
    await this.auth.withdraw();
  }

  async withdrawWithoutPassword(userId: string, email: string): Promise<void> {
    await this.userService.delete(userId);
    await this.auth.withdraw();
  }

  async reauthenticateWithPassword(email: string, password: string): Promise<void>{
    await this.auth.reauthenticateWithPassword(email, password);
  }

  async updatePassword(email: string, currentPassword: string, newPassword: string): Promise<void> {
    await this.auth.reauthenticateWithPassword(email, currentPassword);
    await this.auth.updatePassword(newPassword);
  }

  async update(update: Partial<User>): Promise<void> {
    const userId = this.user.id;

    return this.userService.update(userId, update);
  }

  sendPasswordResetEmail(email: string): Promise<void> {
    return this.auth.sendPasswordResetEmail(email);
  }

  getErrorMessage(code: string) {
    switch (code) {
      case 'auth/user-mismatch':
        return '로그인 방법이 잘못되었습니다. 다른 방법으로 로그인해 주세요.';
      case 'auth/user-not-found':
        return '유저 정보를 찾을 수 없습니다.';
      case 'auth/invalid-credential':
        return 'SNS 로그인에 실패하였습니다. 다시 시도해주세요.';
      case 'auth/invalid-email':
        return '이메일이 잘못되었습니다. 다시 확인해 주세요.';
      case 'auth/wrong-password':
        return '패스워드가 틀렸습니다. 다시 확인해 주세요.';
      case 'auth/invalid-verification-code':
        return '인증코드가 틀렸습니다. 다시 확인해 주세요.';
      case 'auth/invalid-verification-id':
        return '인증이 실패하였습니다. 다시 시도해주세요.';
      case 'permission-denied':
        return '이미 탈퇴한 회원입니다.';
      case 'auth/weak-password':
        return '비밀번호는 6자 이상이어야 합니다.';
      case 'auth/email-already-in-use':
        return '이미 가입되어 있는 이메일입니다.';
      case 'auth/invalid-type':
        return '유저 타입이 맞지 않습니다';
      case 'auth/user-cancelled':
        return null;
    }

    return '알 수 없는 오류가 발생했습니다. 관리자에게 문의해주세요.';
  }

  getAuthedUser(accessToken: string): Promise<User> {
    return null;
  }

  checkVerified(): boolean {
    return Boolean(this.user && (this.user.uniqueInSite || this.user.foreignerVerification));
  }

  checkVerifiedByUser(user: User): boolean {
    return Boolean(user && (user.uniqueInSite || user.foreignerVerification));
  }
}
