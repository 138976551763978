import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ENVIRONMENT } from '../../../nikplace/src/lib/core/tokens';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BREAKPOINT } from '@angular/flex-layout';
import { BREAKPOINTS } from './breakpoint';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DialogModule } from './modules/dialog/dialog.module';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { COMPOSITION_BUFFER_MODE } from '@angular/forms';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    DialogModule,
    environment.production ? [] : AkitaNgDevtools.forRoot(),
  ],
  providers: [
    { provide: BREAKPOINT, useValue: BREAKPOINTS, multi: true },
    {
      provide: COMPOSITION_BUFFER_MODE,
      useValue: false,
    },
    { provide: ENVIRONMENT, useValue: environment },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
