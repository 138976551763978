import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NikSharedModule } from '../../../../nikplace/src/lib/shared/shared.module';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { LayoutPageComponent } from './components/layout-page/layout-page.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { RouterModule } from '@angular/router';
import { MobileToolbarComponent } from './components/mobile-toolbar/mobile-toolbar.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatMenuModule } from '@angular/material/menu';
import { NotificationModule } from '../modules/notification/notification.module';
import { ReactiveFormsModule } from '@angular/forms';
import { DesktopToolbarComponent } from './components/desktop-toolbar/desktop-toolbar.component';
import { NikholderDialogComponent } from './components/nikholder-dialog/nikholder-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  declarations: [
    LayoutPageComponent,
    ToolbarComponent,
    FooterComponent,
    MobileToolbarComponent,
    DesktopToolbarComponent,
    NikholderDialogComponent
  ],
  exports: [LayoutPageComponent, ToolbarComponent, FooterComponent],
  imports: [
    CommonModule,
    FlexModule,
    FlexLayoutModule,
    NikSharedModule,
    RouterModule,
    TranslateModule,
    ClipboardModule,
    MatMenuModule,
    NotificationModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatSnackBarModule
  ]
})
export class CoreModule {}
