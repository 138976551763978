<div class="toolbar-wrap" [class.shadow]="subNavVisibility" (mouseenter)="subNavVisibility = true" (mouseleave)="subNavVisibility = false">
  <div class="toolbar-box" fxLayout="row" fxLayoutAlign="start center">
    <div class="logo-box" fxFlex="207px">
      <a routerLink="/">
        <img nikSrc="/assets/logos/toolbar-logo.png">
      </a>
    </div>

    <ul class="nav-list" fxFlex="auto">
      <li class="nav-item">
        <a class="nav-link" routerLink="/nft" [queryParams]="{ type: 'item', category: 'buy' }">
          {{ 'TOOLBAR.NAV.BUY_NOW' | translate }}
        </a>
      </li>

      <li class="nav-item">
        <a class="nav-link" routerLink="/nft" [queryParams]="{ type: 'item', category: 'auction' }">
          {{ 'TOOLBAR.NAV.AUCTION' | translate }}
        </a>
      </li>

      <li class="nav-item">
        <a class="nav-link" routerLink="/nikplay">
          NIKPLAY
        </a>
      </li>

      <li class="nav-item">
        <a class="nav-link" routerLink="/customer/faq">
          {{ 'TOOLBAR.NAV.SUPPORT' | translate }}
        </a>
      </li>

      <li class="nav-item">
        <a class="nav-link" routerLink="/my">
          SELL MY NFT
        </a>
      </li>

      <li class="nav-item">
        <a class="nav-link" (click)="openNikholderDialog()">
          NIKHOLDER
        </a>
      </li>
    </ul>

    <div class="auth-box" fxFlex="none" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
      <ng-container *ngIf="isLoggedIn">
        <div class="notification-box">
          <a [matMenuTriggerFor]="settingsMenu">
            <img nikSrc="/assets/icons/bell.png">
          </a>

          <mat-menu #settingsMenu class="notification-menu" xPosition="before">
            <nik-notification-list></nik-notification-list>
          </mat-menu>
        </div>

        <div class="profile-box" routerLink="/my" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="11px">
          <div class="profile-picture" [style.background-image]="makeProfilePictureUrl(user?.profilePictureUrl)" fxFlex="none"></div>

          <div class="profile-info">
            <p class="profile-name">{{ user?.nickname || user?.name }}</p>

            <p class="profile-level">Bronze</p>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div *ngIf="subNavVisibility" class="sub-nav-wrap">
    <div class="sub-nav-box">
      <h3 class="sub-nav-title">NIKPLAY</h3>

      <ul class="sub-nav-list">
        <li class="sub-nav-item">
          <a class="sub-nav-link" routerLink="/nft" [queryParams]="{ type: 'item', category: 'buy', product: 'want-card' }">
            WANT CARD
          </a>
        </li>

        <li class="sub-nav-item">

          <a
            class="sub-nav-link"
            href="https://klayswap.com/exchange/swap?output=0xdabee145a1395e09280c23ea9aa71caca35a1ec0"
            target="_blank"
          >
            KLAY SWAP
          </a>
        </li>
      </ul>
    </div>

    <div class="sub-nav-box">
      <h3 class="sub-nav-title">{{ 'TOOLBAR.NAV.SUPPORT' | translate }}</h3>

      <ul class="sub-nav-list">
        <li class="sub-nav-item">
          <a class="sub-nav-link" routerLink="/about-us">
            ABOUT US
          </a>
        </li>

        <li class="sub-nav-item">
          <a class="sub-nav-link" routerLink="/customer/notice">
            NOTICE
          </a>
        </li>

        <li class="sub-nav-item">
          <a class="sub-nav-link" routerLink="/customer/faq">
            FAQ
          </a>
        </li>

        <li class="sub-nav-item">
          <a class="sub-nav-link" href="/assets/pdf/NIKPLACE_사용설명서_웹.pdf" target="_blank">
            USER'S GUIDE
          </a>
        </li>

        <li class="sub-nav-item">
          <a class="sub-nav-link" href="http://pf.kakao.com/_fcFxis" target="_blank">
            CUSTOMER CENTER
          </a>
        </li>
      </ul>
    </div>

    <div *ngIf="isLoggedIn" class="sub-nav-box">
      <h3 class="sub-nav-title">{{ 'TOOLBAR.MY_PAGE.MY_PAGE' | translate }}</h3>

      <ul class="sub-nav-list">
        <li class="sub-nav-item">
          <a class="sub-nav-link" routerLink="/my">
            {{ 'TOOLBAR.MY_PAGE.MY_PAGE' | translate }}
          </a>
        </li>

        <li class="sub-nav-item">
          <a class="sub-nav-link" routerLink="/shop/cart">
            {{ 'TOOLBAR.MY_PAGE.CART' | translate }}
          </a>
        </li>


        <li *ngIf="attendanceCheckEnabled" class="sub-nav-item">
          <a class="sub-nav-link" routerLink="/attendance">
            {{ 'TOOLBAR.MY_PAGE.ATTENDANCE' | translate }}
          </a>
        </li>

        <li class="sub-nav-item">
          <a class="sub-nav-link" (click)="logout()">
            {{ 'TOOLBAR.MY_PAGE.LOGOUT' | translate }}
          </a>
        </li>
      </ul>
    </div>

    <div *ngIf="!isLoggedIn" class="sub-nav-box">
      <h3 class="sub-nav-title">{{ 'TOOLBAR.MY_PAGE.MY_PAGE' | translate }}</h3>

      <ul class="sub-nav-list">
        <li class="sub-nav-item">
          <a class="sub-nav-link" routerLink="/auth/sign">
            {{ 'TOOLBAR.MY_PAGE.SIGN_UP' | translate }}
          </a>
        </li>

        <li class="sub-nav-item">
          <a class="sub-nav-link" routerLink="/auth/login">
            {{ 'TOOLBAR.MY_PAGE.LOGIN' | translate }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
