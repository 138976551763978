import { KlaytnAccount } from '../../modules/klaytn/types';
import { BaseEntity } from '../base-entity';
import { DbListRequest } from '../db/types';

export interface User extends BaseEntity {
  email: string;
  name: string;
  nickname: string;
  galleryId?: string;
  galleryName?: string;
  birthday: string;
  gender: 'male' | 'female';
  backgroundImageUrl: string;
  profilePictureUrl: string;
  followingCount: number;
  followedCount: number;
  collectCount: number;
  bio: string; // 소개
  snsLinkUrl: string;
  type: UserType;
  otpKey?: string;
  klaytnAccount?: KlaytnAccount;
  phoneNumber?: string;
  bankCode?: string;
  accountNumber?: string;
  invitationCode?: string;
  invitationCount: number;
  invitationVerifiedCount: number;
  sns: UserSns; // SNS
  imp_uid?: string;
  privacyCheck: boolean;
  agreeCheck: boolean;
  marketingCheck: boolean;
  uniqueInSite?: string;
  lastItemUpdatedAt?: Date;
  withdrawalRestriction: boolean;
  isForeigner: boolean;
  foreignerVerification: boolean;
  foreignerImageUrl: string;
  itemCount?: number;
  level: UserLevel;
  isWhiteList: boolean;
  pushToken?: string;
  pushTokens?: PushToken[];
}

export enum UserType {
  Admin = 'admin',
  AdminItem = 'admin_item',
  AdminCms = 'admin_cms',
  Gallery = 'gallery',
  Collector = 'collector',
  Artist = 'artist',
}

export interface UserListRequest extends DbListRequest {
  type?: UserType;
  email?: string;
}

export interface UserSns {
  instagram: string;
  facebook: string;
  naverBlog: string;
  twitter: string;
}

export enum UserLevel {
  Bronze = 10,
  Silver = 30,
  Gold = 50
}

export interface WriteUser {
  id: string;
  email: string;
  name: string;
  nickName: string;
}

export function convertUserToWriteUser(user: User): WriteUser {
  return {
    id: user.id,
    email: user.email,
    name: user.name || null,
    nickName: user.nickname || null,
  };
}

export interface PushToken {
  app: string;
  os: string;
  device: string;
  pushToken: string;
}
