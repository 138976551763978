import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { CustomComponentDialogComponent } from './components/custom-component-dialog/custom-component-dialog.component';
import { MessageDialogComponent } from './components/message-dialog/message-dialog.component';
import { NgDialogService } from './services/dialog.service';
import { PipesModule } from '../pipes/pipes.module';
import { NikSharedModule } from '../../../../../nikplace/src/lib/shared/shared.module';
import { DaumPostcodeDialogComponent } from './components/daum-postcode-dialog/daum-postcode-dialog.component';
import { ReactiveFormsModule } from '@angular/forms';
import { BottomUpDialogComponent } from './components/bottom-up-dialog/bottom-up-dialog.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  declarations: [
    MessageDialogComponent,
    CustomComponentDialogComponent,
    DaumPostcodeDialogComponent,
    BottomUpDialogComponent,
    ConfirmDialogComponent
  ],
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        MatRippleModule,
        FlexModule,
        PipesModule,
        NikSharedModule,
        ReactiveFormsModule,
        DragDropModule,
        TranslateModule,
    ],
  providers: [NgDialogService],
})
export class DialogModule {}
