export enum AppType {
  Web = 'web',
  Admin = 'admin',
  Backend = 'backend',
}

export enum Configuration {
  Dev,
  Prod,
}

export interface Environment {
  production: boolean;
  appType: AppType;
  configuration: Configuration;
  baseUrl?: string;
  webUrl?: string;
  firebase?: {
    apiKey: string;
    authDomain: string;
    databaseURL?: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId?: string;
  };
  algolia?: {
    appId: string;
    searchKey: string;
  };
  klaytn?: {
    contractAddress: string;
    multiTokenContractAddress: string;
  };
  bigquery?: {
    user: string;
    game: string;
    gameRoom: string;
    report: string;
  };
  stripe?: {
    publishableKey?: string;
    secretKey?: string;
  };
}
