import { DbAdapter } from '../../core/db/db.adapter';
import { roundNik } from '../../modules/klaytn/utils';
import { ColdObservable } from '../../core/types';
import { map } from 'rxjs/operators';
import { User } from '../../core/user/types';
import { UserService } from '../../core/user/user.service';


export class ConfigService {
  constructor(
    protected configDb: DbAdapter<any>,
    protected userService: UserService
  ) {}

  async getKlayRatio(): Promise<{ ratio: number }> {
    return await this.configDb.get('klay');
  }

  async updateKlayRatio(ratio: number): Promise<void> {
    return await this.configDb.update('klay', { ratio });
  }

  async getNikRatio(): Promise<{ ratio: number }> {
    return await this.configDb.get('nik');
  }

  async getWonUsdRatio(): Promise<{ ratio: number }> {
    return await this.configDb.get('wonUsd');
  }

  async getUsdNikRatio(): Promise<{ ratio: number }> {
    const [wonNikRatio, wonUsdRatio] = await Promise.all([
      this.getNikRatio(),
      this.getWonUsdRatio()
    ]);

    if (wonNikRatio.ratio && wonUsdRatio.ratio) {
      const ratio = Math.round((wonNikRatio.ratio / wonUsdRatio.ratio) * 100000) / 100000;

      return { ratio };
    } else {
      throw new Error('환율 정보를 가져오지 못했습니다');
    }
  }

  async updateNikRatio(ratio: number): Promise<void> {
    return await this.configDb.update('nik', { ratio });
  }

  async calcGas(): Promise<number> {
    const [nikResponse] = await Promise.all([
      this.getNikRatio()
    ]);

    const nikRatio = nikResponse.ratio;

    return roundNik(3000 / nikRatio);
  }

  getChargeEnabledChange(): ColdObservable<boolean> {
    return this.configDb.getChange('chargeEnabled').pipe(
      map(response => response.data)
    );
  }

  checkAttendanceCheckEnabled(): ColdObservable<boolean> {
    return this.configDb.getChange('attendanceCheckEnabled').pipe(
      map(response => response.data)
    );
  }

  async getAppPushTesters(): Promise<User[]> {
    const userIds = await this.getAppPushTesterIds();

    const users = await this.userService.getMany(userIds);

    let needUpdate = false;

    for (let i = users.length - 1; i >= 0; i--) {
      if (!users[i]) {
        needUpdate = true;
        userIds.splice(i, 1);
        users.splice(i, 1);
      }
    }

    if (needUpdate) {
      await this.configDb.update('appPushTesters', { userIds });
    }

    return users;
  }

  async addAppPushTester(userId: string): Promise<void> {
    const userIds = await this.getAppPushTesterIds();

    if (userIds.indexOf(userId) > -1) {
      throw new Error('already exists');
    }

    userIds.push(userId);

    await this.configDb.update('appPushTesters', { userIds });
  }

  async deleteAppPushTester(userId: string): Promise<void> {
    const userIds = await this.getAppPushTesterIds();

    const index = userIds.indexOf(userId);

    if (index > -1) {
      userIds.splice(index, 1);

      await this.configDb.update('appPushTesters', { userIds });
    }
  }

  private async getAppPushTesterIds(): Promise<string[]> {
    const appPushTesters = await this.configDb.get('appPushTesters');

    if (!appPushTesters) {
      await this.configDb.add({ id: 'appPushTesters', userIds: [] });

      return [];
    }

    const userIds: string[] = appPushTesters.userIds;

    if (!userIds) {
      return [];
    }

    return userIds;
  }

  async getDayLimitNik(): Promise<number> {
    let limitNik = await this.configDb.get('limitNik');
    return limitNik.day;
  }

  async getPlatformDayLimitNik(): Promise<number> {
    let limitNik = await this.configDb.get('limitNik');
    return limitNik.platformDay;
  }

  async updateLimitNik(day: number, platformDay: number): Promise<void> {
    return await this.configDb.update('limitNik', { day, platformDay });
  }
}
