<div class="confirm-dialog-box">
  <div class="confirm-dialog-header">
    <h4 class="confirm-title">
      {{ title | translate }}
    </h4>
  </div>

  <div class="confirm-dialog-contents">
    <p class="confirm-message">
      {{ message | translate }}
    </p>
  </div>

  <div class="confirm-dialog-footer">
    <div class="button-control-box">
      <button type="button" class="confirm-dialog-button" (click)="cancel()">
        {{ cancelButtonText | translate }}
      </button>

      <button type="button" class="confirm-dialog-button confirm-dialog-confirm-button" (click)="confirm()">
        {{ confirmButtonText | translate }}
      </button>
    </div>
  </div>
</div>
