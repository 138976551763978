<section class="notification-list-section">
  <h1>NOTICE</h1>

  <ul class="notification-list">
    <li class="notification-item" *ngFor="let notification of notifications">
      <a class="notification-link" (click)="movePage(notification)">
        <div class="notification-content">
          <article class="notification-message">
            <ng-container *ngIf="notification.type === type.Wish">
              <h3>##안내</h3>

              <p>{{ notification.info.name }}님이 회원님의 콜렉션을 좋아합니다.</p>
            </ng-container>

            <ng-container *ngIf="notification.type === type.Transferred">
              <h3>##안내</h3>

              <p>{{ notification.info.endDate | date: 'YYYY.MM.dd HH:mm:ss' }}까지 입금을 완료해주시기
                바랍니다. - 기업은행,000-3535-8282</p>
            </ng-container>

            <ng-container *ngIf="notification.type === type.Charged">
              <h3>##안내</h3>

              <p>{{ notification.info.nikAmount }}NIK 충전이 완료되었습니다.</p>
            </ng-container>

            <ng-container *ngIf="notification.type === type.Paypal">
              <h3>##안내</h3>

              <p>{{ notification.info.amount }}USD 결제 완료되었습니다.</p>
            </ng-container>

            <ng-container *ngIf="notification.type === type.Bid">
              <h3>[입찰 완료]</h3>

              <p>{{ notification.info.item.name }},
              {{ notification.info.item.artistName }} (콜렉터 :
              {{ notification?.info?.userName || '' }})에 {{ notification.info.amount }}NIK 으로
                입찰 완료되었습니다.</p>
            </ng-container>

            <ng-container *ngIf="notification.type === type.WinningBid">
              <h3>[경매 체결]</h3>

              <p>{{ notification.info.item.name }},
              {{ notification.info.item.artistName }} (콜렉터 :
              {{ notification?.info?.userName || '' }})에 {{ notification.info.amount }}NIK 으로
                경매가 체결되었습니다.</p>
            </ng-container>

            <ng-container *ngIf="notification.type === type.Ordered">
              <ng-container *ngIf="notification.info.items[0] as item">
                <h3>{{ item.saleType === saleType.Auction ? '[바로구매 완료]' : '[구매 완료]' }}</h3>

                <p>{{ item.name }}, {{ item.artistName }} 구매가 완료되었습니다.</p>
              </ng-container>
            </ng-container>
          </article>
        </div>
        <!--        <div class="notification-footer">-->
        <!--          <p>Take a closer look at the quote</p>-->
        <!--        </div>-->
      </a>
    </li>

    <li *ngIf="!notifications || notifications.length === 0" class="notification-item">
      <p class="empty-text">{{ 'NOTIFICATION.NO_ITEM' | translate }}</p>
    </li>
  </ul>

  <div *ngIf="isLoading" class="loading-box">
    <img src="/assets/icons/loading128.gif" alt="logo" />
  </div>
</section>
