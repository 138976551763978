import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'styleBackgroundImage'
})
export class StyleBackgroundImagePipe implements PipeTransform {

  transform(value: string | string[], defaultUrl?: string): unknown {
    if (typeof value === 'string' && value) {
      return `url('${value.replace('\'', '\\\'')}')`;
    } else if (Array.isArray(value)) {
      for (const str of value) {
        if (str) {
          return `url('${str.replace('\'', '\\\'')}')`;
        }
      }
    }

    if (defaultUrl) {
      return `url('${defaultUrl.replace('\'', '\\\'')}')`;
    }

    return null;
  }

}
