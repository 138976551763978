import { Environment, Configuration } from '../environment';
import { formatDate } from '../utils';
import { ApiAdapter } from './api.adapter';
import { ApiOptions, RefreshTokenResponse, ApiResponse, PaypalTokenResponse, PaypalPayoutResponse, PaypalPayoutRequest } from './types';
import { KlaytnMetadata } from '../../entities/item/types';


export class ApiService {
  constructor(
    private key: {
      refreshTokenApiKey: string,
      paypal: {
        username: string,
        password: string
      },
      wonUsdRatioAuthKey: string
    },
    protected apiAdapter: ApiAdapter,
    protected environment: Environment
  ) {}

  async getMetadata(url: string): Promise<ApiResponse<KlaytnMetadata>> {
    const options: ApiOptions = {
      headers: {
        Accept: 'application/json'
      }
    };

    return this.apiAdapter.get<KlaytnMetadata>(url, options);
  }

  async refreshToken(refreshToken: string): Promise<ApiResponse<RefreshTokenResponse>> {
    const url = `https://securetoken.googleapis.com/v1/token?key=${this.key.refreshTokenApiKey}`;

    const data = {
      grant_type: 'refresh_token',
      refresh_token: refreshToken
    };

    const options: ApiOptions = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      }
    };

    return this.apiAdapter.post<RefreshTokenResponse>(url, data, options);
  }

  async requestPaypalToken(): Promise<ApiResponse<PaypalTokenResponse>> {
    let url: string;

    if (this.environment.configuration === Configuration.Prod) {
      url = 'https://api-m.paypal.com/v1/oauth2/token';
    } else {
      url = 'https://api-m.sandbox.paypal.com/v1/oauth2/token';
    }

    const data = {
      grant_type: 'client_credentials'
    };

    const options: ApiOptions = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      ...this.key.paypal
    };

    return this.apiAdapter.post<PaypalTokenResponse>(url, data, options);
  }

  async requestPaypalPayout(accessToken: string, request: PaypalPayoutRequest): Promise<ApiResponse> {
    let url: string;

    if (this.environment.configuration === Configuration.Prod) {
      url = 'https://api-m.paypal.com/v1/payments/payouts';
    } else {
      url = 'https://api-m.sandbox.paypal.com/v1/payments/payouts';
    }

    const data = {
      ...request
    };

    const options: ApiOptions = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    };

    return this.apiAdapter.post<PaypalPayoutResponse>(url, data, options);
  }

  async getWonUsdRatio(): Promise<ApiResponse> {
    const now = new Date();
    const start = new Date(now.getFullYear(), now.getMonth(), now.getDay() - 6);
    const startDate = formatDate(start, 'yyyyMMdd');
    const endDate = formatDate(now, 'yyyyMMdd');

    const url = `http://ecos.bok.or.kr/api/StatisticSearch/${this.key.wonUsdRatioAuthKey}/json/kr/1/10/036Y003/DD/${startDate}/${endDate}/0000003`;

    const options: ApiOptions = {
      headers: {
        Accept: 'application/json',
      }
    };

    return this.apiAdapter.get(url, options);
  }
}
