import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'depositDateLimit',
})
export class DepositDateLimitPipe implements PipeTransform {
  transform(value: Date): string {
    return moment(value)
      .add(1, 'day')
      .set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
      .format('YYYY-MM-DD HH:mm:ss');
  }
}
