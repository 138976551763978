import { Injectable } from '@angular/core';
import { ApiRatioService } from '../../../../../../../src/entities/config/api-ratio.service';
import { NgApiService } from '../../../core/services/api.service';
import { NgConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class NgApiRatioService extends ApiRatioService {
  constructor(
    protected configService: NgConfigService,
    protected apiService: NgApiService
  ) {
    super(configService, apiService);
  }
}
