import { Pipe, PipeTransform } from '@angular/core';
import { OrderStatus, OrderStatusClass } from '../../../../../../src/entities/order/types';

@Pipe({
  name: 'orderStatusClass',
})
export class OrderStatusClassPipe implements PipeTransform {
  transform(status: OrderStatus): string {
    if (!status) {
      return '';
    }
    return OrderStatusClass[status];
  }
}
