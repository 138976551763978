import { User } from '../../core/user/types';
import { pad } from '../../core/utils';
import { Item, ItemMediaType, ItemNikSeries, ItemPaymentType, KlaytnMetadata } from '../../entities/item/types';
import { makeTokenId } from '../../entities/item/utils';
import {
  KlaytnAccount,
  KlaytnGetKip17TokenHistoryResponse,
  KlaytnGetKip17TokenResponse,
  KlaytnGetOwnerKip17TokensResponse, KlaytnInput,
  KlaytnKip17QueryOptions,
  KlaytnKip17TransactionStatusResponse,
  KlaytnKip37TransactionStatusResponse,
  KlaytnKip7TransactionStatusResponse
} from './types';
import BigNumber from 'bignumber.js';
import * as promiseRetry from 'promise-retry';

export class KlaytnService {
  caver: any;

  constructor(
    private credential: { chainId: string; accessKeyId: string; secretAccessKey: string; },
    private alias: string,
    private nftAlias: string,
    private multiAlias: string,
    private deployerAddress: string,
    private multiTokenDeployerAddress: string,
    private contractAddress: string,
    private CaverExtKAS: any,
    private axios: any
  ) {
    if (this.credential) {
      this.caver = new CaverExtKAS(
        this.credential.chainId,
        this.credential.accessKeyId,
        this.credential.secretAccessKey
      );
    }
  }

  getDeployerAddress(): Promise<string> {
    return Promise.resolve(this.deployerAddress);
  }

  getMultiTokenDeployerAddress(): Promise<string> {
    return Promise.resolve(this.multiTokenDeployerAddress);
  }

  async createAccount(): Promise<KlaytnAccount> {
    const response = await promiseRetry(async retry => {
      try {
        return await this.caver.kas.wallet.createAccount();

      } catch (err) {
        if (err.code === 'ETIMEDOUT' || err.message === 'Forbidden') {
          retry(err);
        }

        throw err;
      }
    });

    return Object.assign({}, response);
  }

  async deleteAccount(address: string): Promise<KlaytnAccount> {
    const response = await promiseRetry(async retry => {
      try {
        return await this.caver.kas.wallet.deleteAccount(address);

      } catch (err) {
        if (err.code === 'ETIMEDOUT' || err.message === 'Forbidden') {
          retry(err);
        }

        throw err;
      }
    });

    return Object.assign({}, response);
  }

  async createNftToken(to: string, tokenId: string, tokenURI: string): Promise<KlaytnKip17TransactionStatusResponse> {
    const response = await promiseRetry(async retry => {
      try {
        return await this.caver.kas.kip17.mint(this.nftAlias, to, tokenId, tokenURI);

      } catch (err) {
        if (err.code === 'ETIMEDOUT' || err.message === 'Forbidden') {
          retry(err);
        }

        throw err;
      }
    });

    return Object.assign({}, response);
  }

  async getToken(tokenId: string): Promise<KlaytnGetKip17TokenResponse> {
    const response = await promiseRetry(async retry => {
      try {
        return await this.caver.kas.kip17.getToken(this.nftAlias, tokenId);

      } catch (err) {
        if (err.code === 'ETIMEDOUT' || err.message === 'Forbidden') {
          retry(err);
        }

        throw err;
      }
    });

    return Object.assign({}, response);
  }

  async transferNftToken(sender: string, owner: string, to: string, tokenId: string): Promise<KlaytnKip17TransactionStatusResponse> {
    const response = await promiseRetry(async retry => {
      try {
        return await this.caver.kas.kip17.transfer(this.nftAlias, sender, owner, to, tokenId);

      } catch (err) {
        if (err.code === 'ETIMEDOUT' || err.message === 'Forbidden') {
          retry(err);
        }

        throw err;
      }
    });

    return Object.assign({}, response);
  }

  async deleteNftToken(from: string, tokenId: string): Promise<KlaytnKip17TransactionStatusResponse> {
    const response = await promiseRetry(async retry => {
      try {
        return await this.caver.kas.kip17.burn(this.nftAlias, from, tokenId);

      } catch (err) {
        if (err.code === 'ETIMEDOUT' || err.message === 'Forbidden') {
          retry(err);
        }

        throw err;
      }
    });

    return Object.assign({}, response);
  }

  async createMultiToken(to: string, id: string, initialSupply: number, uri: string): Promise<KlaytnKip37TransactionStatusResponse> {
    const response = await promiseRetry(async retry => {
      try {
        return await this.caver.kas.kip37.create(this.multiAlias, id, this.convertNikToBalance(initialSupply), uri);

      } catch (err) {
        if (err.code === 'ETIMEDOUT' || err.message === 'Forbidden') {
          retry(err);
        }

        throw err;
      }
    });

    return Object.assign({}, response);
  }

  async mintMultiToken(to: string, id: string, initialSupply: number): Promise<KlaytnKip37TransactionStatusResponse> {
    const response = await promiseRetry(async retry => {
      try {
        return await this.caver.kas.kip37.mint(this.multiAlias, to, [id], [this.convertNikToBalance(initialSupply)]);

      } catch (err) {
        if (err.code === 'ETIMEDOUT' || err.message === 'Forbidden') {
          retry(err);
        }

        throw err;
      }
    });

    return Object.assign({}, response);
  }

  async transferMultiToken(
    sender: string,
    owner: string,
    to: string,
    tokenId: string,
    amount: number
  ): Promise<KlaytnKip37TransactionStatusResponse> {
    const response = await promiseRetry(async retry => {
      try {
        return await this.caver.kas.kip37.transfer(this.multiAlias, sender, owner, to, [tokenId], [this.convertNikToBalance(amount)]);

      } catch (err) {
        if (err.code === 'ETIMEDOUT' || err.message === 'Forbidden') {
          retry(err);
        }

        throw err;
      }
    });

    return Object.assign({}, response);
  }

  async deleteMultiToken(tokenId: string, initialSupply: number, from: string): Promise<KlaytnKip17TransactionStatusResponse> {
    const response = await promiseRetry(async retry => {
      try {
        return await this.caver.kas.kip37.burn(this.multiAlias, [tokenId], [this.convertNikToBalance(initialSupply)], from);

      } catch (err) {
        if (err.code === 'ETIMEDOUT' || err.message === 'Forbidden') {
          retry(err);
        }

        throw err;
      }
    });

    return Object.assign({}, response);
  }

  async getTokenListByOwner(owner: string, queryOptions: KlaytnKip17QueryOptions): Promise<KlaytnGetOwnerKip17TokensResponse> {
    const response = await promiseRetry(async retry => {
      try {
        return await this.caver.kas.kip17.getTokenListByOwner(this.nftAlias, owner, queryOptions);

      } catch (err) {
        if (err.code === 'ETIMEDOUT' || err.message === 'Forbidden') {
          retry(err);
        }

        throw err;
      }
    });

    return Object.assign({}, response);
  }

  async getTransferHistoryOfNftToken(tokenId: string, queryOptions: KlaytnKip17QueryOptions): Promise<KlaytnGetKip17TokenHistoryResponse> {
    const response = await promiseRetry(async retry => {
      try {
        return await this.caver.kas.kip17.getTransferHistory(this.nftAlias, tokenId, queryOptions);

      } catch (err) {
        if (err.code === 'ETIMEDOUT' || err.message === 'Forbidden') {
          retry(err);
        }

        throw err;
      }
    });

    return Object.assign({}, response);
  }

  async getBalance(owner: string): Promise<number> {
    const response = await promiseRetry(async retry => {
      try {
        return await this.caver.kas.kip7.balance(this.alias, owner);

      } catch (err) {
        if (err.code === 'ETIMEDOUT' || err.message === 'Forbidden') {
          retry(err);
        }

        throw err;
      }
    });

    return this.convertBalanceToNik(parseInt(response.balance, 16));
  }

  async createNik(to: string, amount: number): Promise<KlaytnKip7TransactionStatusResponse> {
    const response = await promiseRetry(async retry => {
      try {
        return await this.caver.kas.kip7.mint(this.alias, to, this.convertNikToBalance(amount));

      } catch (err) {
        if (err.code === 'ETIMEDOUT' || err.message === 'Forbidden') {
          retry(err);
        }

        throw err;
      }
    });

    return Object.assign({}, response);
  }

  async transferNik(from: string, to: string, amount: number): Promise<KlaytnKip7TransactionStatusResponse> {
    const response = await promiseRetry(async retry => {
      try {
        return await this.caver.kas.kip7.transfer(this.alias, from, to, this.convertNikToBalance(amount));

      } catch (err) {
        if (err.code === 'ETIMEDOUT' || err.message === 'Forbidden') {
          retry(err);
        }

        throw err;
      }
    });

    return Object.assign({}, response);
  }

  async uploadMetadata(gallery: User, artist: User, item: Item): Promise<string> {
    let currency = 'NIK';

    if (item.paymentType === ItemPaymentType.Won) {
      currency = 'WON';
    } else if (item.paymentType === ItemPaymentType.Usd) {
      currency = 'USD';
    } else if (item.paymentType === ItemPaymentType.Inquiry) {
      currency = 'WON';
    }

    const amountValue = item.amount === 0 ? '구매처 문의' : `${item.amount}${currency}`;

    let created_date: string;

    if (item.createdAt) {
      if (item.createdAt.toISOString) {
        created_date = item.createdAt.toISOString();
      } else {
        try {
          const date = new Date(item.createdAt);
          created_date = date.toISOString();
        } catch (err) {
          created_date = new Date().toISOString();
        }
      }
    } else {
      created_date = new Date().toISOString();
    }

    const request: { metadata: KlaytnMetadata; filename?: string } = {
      metadata: {
        attributes: [],
        background_color: item.backgroundColor,
        created_date,
        description: item.description + '\n* 이 상품은 저작권을 포함하지 않습니다.',
        name: item.name,
        send_friend_only: false,
        sendable: true
      }
    };

    if (item.mediaType === ItemMediaType.Video) {
      request.metadata.animation = item.videoUrl;
    } else {
      request.metadata.image = item.imageUrl;
    }

    if (item.isOnlyOne) {
      request.metadata.special = '세상에 단 하나뿐인 고유 작품입니다.';
    }

    if (item.series === ItemNikSeries.BELLYGOM) {
      request.metadata.license = '© bellygom. All rights reserved 본 제품은 ㈜ 우리홈쇼핑(롯데홈쇼핑)의 정식계약으로 인해 ㈜닉플레이스에서 제작·판매하는 것으로 무단복제 및 판매를 금합니다.';
    }

    let traitType: string;

    if (item.hasOwnership) {
      if (item.totalPieces > 1) {
        traitType = '분할 수익권';
      } else {
        traitType = '실물 작품 소유권';
      }

    } else {
      traitType = '디지털 작품 에디션';
    }

    if (item.isWantCard) {
      request.metadata.attributes = [{
        trait_type: '발행처',
        value: 'NIKPLACE'
      }, {
        trait_type: '가격',
        value: amountValue
      }, {
        trait_type: 'WANT CARD',
        value: `소각시 매장된 ${item.initialSupply}NIK을 얻을 수 있는 멀티토큰입니다.`
      }];
    } else {
      request.metadata.attributes = [{
        trait_type: '발행처',
        value: gallery.name
      }, {
        trait_type: '화가',
        value: item.artistName || artist.nickname
      }, {
        trait_type: '가격',
        value: amountValue
      }, {
        trait_type: traitType,
        value: `${makeTokenId(item.id)} 현소유자는 ${item.artistName} ${item.name}의 ${makeOwnershipText(item)}의 소유자입니다`
      }];
    }

    const url = `https://metadata-api.klaytnapi.com/v1/metadata`;

    const options = {
      method: 'post',
      url,
      auth: {
        username: this.credential.accessKeyId,
        password: this.credential.secretAccessKey
      },
      headers: {
        'x-chain-id': this.credential.chainId
      },
      responseType: 'json',
      data: request
    };

    try {
      const response: any = await this.axios(options);

      return response.data.uri;

    } catch (err) {
      console.error(err);
      console.log(err.response && err.response.data);
      throw new Error('메타데이터 업로드 실패');
    }
  }

  async execute(fromAddress: string, name: string, inputs: KlaytnInput[], data: any[]): Promise<void> {
    const input = await promiseRetry(async retry => {
      try {
        return await this.caver.abi.encodeFunctionCall({ name, type: 'function', inputs }, data);

      } catch (err) {
        if (err.code === 'ETIMEDOUT' || err.message === 'Forbidden') {
          retry(err);
        }

        throw err;
      }
    });

    await promiseRetry(async retry => {
      try {
        return await this.caver.kas.wallet.requestFDSmartContractExecutionPaidByGlobalFeePayer({
          from: fromAddress,
          to: this.contractAddress,
          value: 0,
          input,
          gas: 0,
          feeRatio: 0,
          submit: true
        });

      } catch (err) {
        if (err.code === 'ETIMEDOUT' || err.message === 'Forbidden') {
          retry(err);
        }

        throw err;
      }
    });
  }

  private convertNikToBalance(nik: number): BigNumber {
    if (this.credential.chainId === '8217') {
      return new BigNumber(Math.round(nik * Math.pow(10, 16)) || 1);
    }

    return new BigNumber(Math.round(nik) || 1);
  }

  private convertBalanceToNik(nik: number): number {
    if (this.credential.chainId === '8217') {
      return nik / Math.pow(10, 16);
    }

    return nik;
  }
}

function makeOwnershipText(item: Item) {
  if (item.hasOwnership) {
    if (item.totalPieces > 1) {
      return `수익권(${makeEquityRightNumber(item)},1/${item.totalPieces || 1})`;
    } else {
      return `지분권(${makeEquityRightNumber(item)},1/${item.totalPieces || 1})`;
    }
  } else {
    return `에디션(N${item.currentPieceNumber}, ${item.totalPieces || 1})`;
  }
}

function makeEquityRightNumber(item: Item): string {
  const year = new Date().getFullYear() % 100;
  const artistId = item.artistId.slice(-2);
  const equityRatio = Math.round((item.currentPieceNumber / item.totalPieces) * 1000);

  return `NX17Y${year}${artistId}P${pad(item.totalPieces, 3)}N${pad(item.currentPieceNumber, 3)}SR${pad(equityRatio, 4)}`;
}


function retryError(target: any, propertyName: any, descriptor: any) {
  const method = descriptor.value;

  descriptor.value = async (...args: any) => {
    const response = await promiseRetry(async retry => {
      try {
        return await method.apply(target, args);

      } catch (err) {
        if (err.code === 'ETIMEDOUT' || err.message === 'Forbidden') {
          retry(err);
        }

        throw err;
      }
    });

    return Object.assign({}, response);
  };
}
