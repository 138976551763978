import { Injectable } from '@angular/core';
import { Notification } from '../../../../../../../src/entities/notification/types';
import { FirestoreDbAdapter } from '../../../../../../../src/modules/firebase/firestore-db.adapter';

@Injectable({
  providedIn: 'root',
})
export class NgNotificationDb extends FirestoreDbAdapter<Notification> {
  constructor() {
    super('notifications', { parentCollectionNames: ['users'] });
  }
}
