import { Injectable } from '@angular/core';
import { Item } from '../../../../../../../src/entities/item/types';
import { FirestoreDbAdapter } from '../../../../../../../src/modules/firebase/firestore-db.adapter';

@Injectable({
  providedIn: 'root'
})
export class NgItemDb extends FirestoreDbAdapter<Item> {
  constructor() {
    super('items');
  }
}
