import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import { AuthService } from '../../../../../../../src/core/auth/auth.service';
import { FirebaseAuthAdapter } from '../../../../../../../src/modules/firebase/firebase-auth.adapter';
import { NgUserService } from '../../user/services/user.service';
import { AuthStore } from '../../../../../../../src/core/auth/auth.store';

@Injectable({
  providedIn: 'root',
})
export class NgAuthService extends AuthService {
  constructor(protected userService: NgUserService) {
    super(new FirebaseAuthAdapter(firebase.auth()), new AuthStore(), userService);
  }
}
