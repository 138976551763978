import { DbListRequest, DbListResponse, DbQuery, DbSortDirection } from '../../core/db/types';
import { FirestoreDbAdapter } from '../../modules/firebase/firestore-db.adapter';
import { ItemService } from '../item/item.service';
import { Bid, BidStatus } from './types';
import { UserService } from '../../core/user/user.service';
import { makeDbPaginationList } from '../../core/db/utils';
import { PaginationList } from '../../core/types';
import { KlaytnService } from '../../modules/klaytn/klaytn.service';
import { KlaytnInputType } from '../../modules/klaytn/types';
import { makeTokenId } from '../item/utils';

export class BidService {
  constructor(
    protected bidDb: FirestoreDbAdapter<Bid>,
    protected itemService: ItemService,
    protected userService: UserService,
    protected klaytnService: KlaytnService
  ) {}

  get(bidId: string): Promise<Bid> {
    return this.bidDb.get(bidId);
  }

  add(bid: Partial<Bid>): Promise<Bid> {
    return this.bidDb.add(bid);
  }

  update(bidId: string, update: Partial<Bid>): Promise<void> {
    return this.bidDb.update(bidId, update);
  }

  delete(bidId: string): Promise<void> {
    return this.bidDb.delete(bidId);
  }

  async bid(userId: string, itemId: string, amount: number): Promise<Bid> {
    const fromAddress = await this.userService.getAddress(userId);
    await this.klaytnService.execute(
      fromAddress,
      'bid',
      [{
        type: KlaytnInputType.Uint256,
        name: 'tokenId'
      }, {
        type: KlaytnInputType.Uint32,
        name: 'amount'
      }, {
        type: KlaytnInputType.String,
        name: 'unit'
      }, {
        type: KlaytnInputType.Address,
        name: 'from'
      }],
      [
        makeTokenId(itemId),
        amount,
        'nik',
        fromAddress
      ]
    );
    return await this.add({ userId, itemId, amount, status: BidStatus.Bidding });
  }

  win(bidId: string): Promise<void> {
    return this.update(bidId, { status: BidStatus.Winning });
  }

  async cancel(bidId: string): Promise<void> {
    const bid = await this.get(bidId);

    const fromAddress = await this.userService.getAddress(bid.userId);

    await this.klaytnService.execute(
      fromAddress,
      'cancelBidding',
      [{
        type: KlaytnInputType.Uint256,
        name: 'tokenId'
      }, {
        type: KlaytnInputType.Uint32,
        name: 'amount'
      }, {
        type: KlaytnInputType.String,
        name: 'unit'
      }, {
        type: KlaytnInputType.Address,
        name: 'from'
      }],
      [
        makeTokenId(bid.itemId),
        bid.amount,
        'nik',
        fromAddress
      ]
    );

    return await this.update(bidId, { status: BidStatus.Canceled });
  }

  fail(bidId: string): Promise<void> {
    return this.update(bidId, { status: BidStatus.Failure });
  }

  async listAll(itemId: string, request: DbListRequest = {}): Promise<DbListResponse<Bid>> {
    const query: DbQuery = {
      filters: [{ field: 'itemId', comparison: '==', value: itemId }],
      sorts: [{ field: 'createdAt', direction: DbSortDirection.Desc }],
      limit: request.size ? +request.size : null
    };

    if (request.cursor) {
      query.gt = await this.bidDb.getSnapshot(request.cursor);
    }

    return this.bidDb.list(query);
  }

  async listWithUser(itemId: string, request: DbListRequest = {}): Promise<Bid[]> {
    const query: DbQuery = {
      filters: [{ field: 'itemId', comparison: '==', value: itemId }],
      sorts: [{ field: 'createdAt', direction: DbSortDirection.Desc }],
      limit: request.size ? +request.size : null
    };

    if (request.cursor) {
      query.gt = await this.bidDb.getSnapshot(request.cursor);
    }

    const response = await this.bidDb.list(query);

    const userIds = response.items.map((bids) => bids.userId);

    const users = await this.userService.getMany(userIds);

    return response.items.map((bid, index) => ({ ...bid, user: users[index] }));
  }

  async listForHistory(itemId: string, auctionStartAt: Date): Promise<Bid[]> {
    const query: DbQuery = {
      filters: [
        { field: 'itemId', comparison: '==', value: itemId },
        { field: 'createdAt', comparison: '>', value: auctionStartAt }
      ],
      sorts: [{ field: 'createdAt', direction: DbSortDirection.Desc }]
    };

    const response = await this.bidDb.list(query);

    const userIds = response.items.map((bids) => bids.userId);

    const users = await this.userService.getMany(userIds);

    return response.items.map((bid, index) => ({ ...bid, user: users[index] }));
  }

  async listAllWithUser(itemId: string, auctionStartAt: Date): Promise<Bid[]> {
    const query: DbQuery = {
      filters: [
        { field: 'itemId', comparison: '==', value: itemId },
        { field: 'createdAt', comparison: '>', value: auctionStartAt },
        { field: 'status', comparison: '==', value: BidStatus.Bidding }
      ],
      sorts: [{ field: 'createdAt', direction: DbSortDirection.Desc }]
    };

    const response = await this.bidDb.list(query);

    const userIds = response.items.map((bids) => bids.userId);

    const users = await this.userService.getMany(userIds);

    return response.items.map((bid, index) => ({ ...bid, user: users[index] }));
  }

  async listAllWithoutUser(itemId: string, auctionStartAt: Date): Promise<Bid[]> {
    const query: DbQuery = {
      filters: [
        { field: 'itemId', comparison: '==', value: itemId },
        { field: 'createdAt', comparison: '>', value: auctionStartAt },
        { field: 'status', comparison: '==', value: BidStatus.Bidding }
      ],
      sorts: [{ field: 'createdAt', direction: DbSortDirection.Desc }]
    };

    const response = await this.bidDb.list(query);

    return response.items;
  }

  async listRecentByUserId(
    userId: string,
    request: DbListRequest = {}
  ): Promise<DbListResponse<Bid>> {
    const query: DbQuery = {
      filters: [{ field: 'userId', comparison: '==', value: userId }],
      sorts: [{ field: 'createdAt', direction: DbSortDirection.Desc }],
      limit: request.size ? +request.size : null
    };

    if (request.cursor) {
      query.gt = await this.bidDb.getSnapshot(request.cursor);
    }

    const response = await this.bidDb.list(query);

    const items = await this.itemService.getMany(response.items.map((doc) => doc.itemId));

    return {
      ...response,
      items: response.items.map((bid, index) => {
        bid.item = items[index];

        return bid as Bid;
      })
    };
  }

  listRecentByUserIdPaginationList(
    userId: string,
    request: DbListRequest = {}
  ): PaginationList<Bid> {
    const query: DbQuery = {
      filters: [{ field: 'userId', comparison: '==', value: userId }],
      sorts: [{ field: 'createdAt', direction: DbSortDirection.Desc }],
      limit: request.size ? +request.size : null
    };

    return makeDbPaginationList(this.bidDb, query);
  }

  async listBiddingByUserId(userId: string): Promise<Bid[]> {
    const query: DbQuery = {
      filters: [
        { field: 'userId', comparison: '==', value: userId },
        { field: 'status', comparison: '==', value: BidStatus.Bidding }
      ],
      sorts: [{ field: 'createdAt', direction: DbSortDirection.Desc }]
    };

    const response = await this.bidDb.list(query);

    return response.items;
  }

  async listExceptHighestBid(itemId: string, auctionStartAt: Date): Promise<Bid[]> {
    const query: DbQuery = {
      filters: [
        { field: 'itemId', comparison: '==', value: itemId },
        { field: 'createdAt', comparison: '>', value: auctionStartAt },
        { field: 'status', comparison: '==', value: BidStatus.Bidding }
      ],
      sorts: [{ field: 'createdAt', direction: DbSortDirection.Desc }]
    };

    const response = await this.bidDb.list(query);

    return response.items.slice(1);
  }

  async getHighestBid(itemId: string, auctionStartAt: Date): Promise<Bid> {
    const query: DbQuery = {
      filters: [
        { field: 'itemId', comparison: '==', value: itemId },
        { field: 'createdAt', comparison: '>', value: auctionStartAt },
        { field: 'status', comparison: '==', value: BidStatus.Bidding }
      ],
      sorts: [{ field: 'createdAt', direction: DbSortDirection.Desc }],
      limit: 1
    };

    const response = await this.bidDb.list(query);

    const bid = response.items[0];

    if (bid) {
      bid.user = await this.userService.get(bid.userId);
    }

    return response.items[0];
  }
}
