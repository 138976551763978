import { Injectable } from '@angular/core';
import { makeFunctionsService } from '../../../../../../../src/modules/firebase/utils';
import { KlaytnService } from '../../../../../../../src/modules/klaytn/klaytn.service';

@Injectable({
  providedIn: 'root'
})
export class NgKlaytnService extends makeFunctionsService(KlaytnService, 'KlaytnService') {
  constructor() {
    super();
  }
}
