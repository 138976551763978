import { Environment, AppType, Configuration } from '../../../../src/core/environment';

export const environment: Environment = {
  production: false,
  appType: AppType.Web,
  configuration: Configuration.Dev,
  baseUrl: 'http://localhost:4200',
  firebase: {
    apiKey: "AIzaSyBNb_shsA7nOZEErenwAX3c38qSMkZCH4E",
    authDomain: "skilful-courage-315509.firebaseapp.com",
    projectId: "skilful-courage-315509",
    storageBucket: "skilful-courage-315509.appspot.com",
    messagingSenderId: "901880102917",
    appId: "1:901880102917:web:c6cd88b11bbc3457c35c6c",
    measurementId: "G-Y4C4MTE8TE"
  },
  algolia: {
    // appId: 'YO7YNM98Y8',
    // searchKey: 'd5e9a0afcf731cf1782c1c55ea4b89d8',
    appId: 'F4TCRBHZIM',
    searchKey: '3c1c74c1b89076937ef0ede4ed14aea5',
    // appId: '3RIQ58SD5I',
    // searchKey: '63ee972d9c47f48e62e16e5f0b253083'
  },
  klaytn: {
    contractAddress: '0x6a63eb7da8bd7bea0314133c2ac01343073e6653',
    multiTokenContractAddress: '0xc6a64539ff39f0c362526c4c96ee30df46cc3c23',
  },
  stripe: {
    publishableKey: 'pk_test_51KgfXsJvSUnaFbHgmuC5OONGhaLNQvJOHR4MiTjpJ9ZY6cFmHfSpmjEE8SVpE8LwzXTxPaGnWox4Cg34rM33ITNt00UzASbMgP'
  }
};
