import { Component, OnInit } from '@angular/core';
import {
  SubscriptionBaseComponent
} from '../../../../../../nikplace/src/lib/core/base-components/subscription-base.component';
import { NgAuthService } from '../../../../../../nikplace/src/lib/core/auth/services/auth.service';
import { User } from '../../../../../../../src/core/user/types';
import { MatDialog } from '@angular/material/dialog';
import { NikholderDialogComponent } from '../nikholder-dialog/nikholder-dialog.component';
import { NgRouteService } from '../../../../../../nikplace/src/lib/core/services/route.service';


@Component({
  selector: 'nik-desktop-toolbar',
  templateUrl: './desktop-toolbar.component.html',
  styleUrls: ['./desktop-toolbar.component.scss']
})
export class DesktopToolbarComponent extends SubscriptionBaseComponent implements OnInit {
  isLoggedIn = false;
  user: User;

  subNavVisibility = false;

  attendanceCheckEnabled = false;

  constructor(
    private authService: NgAuthService,
    private dialog: MatDialog,
    private routeService: NgRouteService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initObservable('isLoggedIn', this.authService.isLoggedIn$);
    this.initObservable('user', this.authService.user$);
  }

  logout() {
    this.authService.logout().then(() => this.routeService.navigate(['/auth/login']));
  }

  makeProfilePictureUrl(profilePictureUrl: string) {
    if (profilePictureUrl) {
      return `url(${profilePictureUrl})`;
    } else {
      return `url(/assets/icons/no-user.png)`;
    }
  }

  openNikholderDialog() {
    if (!this.isLoggedIn) {
      this.routeService.navigate(['/auth/login']);
      return;
    }

    this.dialog.open(
      NikholderDialogComponent,
      {
        width: '448px',
        panelClass: 'nikholder-dialog'
      }
    );
  }
}
