import { Inject, Injectable } from '@angular/core';
import { FrontendBigquerySearchAdapter } from '../../../../../../../src/modules/google/frontend-bigquery-search.adapter';
import { User } from '../../../../../../../src/core/user/types';
import { ENVIRONMENT } from '../../tokens';
import { Environment } from '../../../../../../../src/core/environment';
import { NgFrontendApiService } from '../../services/frontend-api.service';
import { NgUserDb } from './user.db';

@Injectable({
  providedIn: 'root',
})
export class NgUserBigSearch extends FrontendBigquerySearchAdapter<User> {
  constructor(
    @Inject(ENVIRONMENT) private environment: Environment,
    protected frontendApiService: NgFrontendApiService,
    protected userDb: NgUserDb
  ) {
    super(
      `${environment.firebase.projectId}.firestore_export.users_schema_schema_latest`, frontendApiService, userDb
    );
  }
}
