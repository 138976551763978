import { FirestoreDbAdapter } from '../../modules/firebase/firestore-db.adapter';
import { DbListRequest, DbListResponse, DbQuery, DbSortDirection } from '../db/types';
import { ColdObservable, PaginationList } from '../types';
import { User, UserType, UserListRequest } from './types';
import { SearchQuery, SearchResponse } from '../search/types';
import { makeSearchPaginationList } from '../search/utils';
import { SearchAdapter } from '../search/search.adapter';

export class UserService {
  galleries: User[];
  artists: User[];
  galleries2: User[];
  artists2: User[];

  constructor(
    protected userDb: FirestoreDbAdapter<User>,
    protected userSearch: SearchAdapter<User>,
    protected userSearchByAlgolia: SearchAdapter<User>
  ) {}

  add(entity: Partial<User>): Promise<User> {
    return this.userDb.add(entity);
  }

  get(id: string): Promise<User> {
    return this.userDb.get(id);
  }

  async getByEmail(email: string): Promise<User> {
    const query: DbQuery = {
      filters: [{ field: 'email', comparison: '==', value: email }],
      limit: 1
    };

    const response = await this.userDb.list(query);

    return response.items[0];
  }

  getChange(id: string): ColdObservable<User> {
    return this.userDb.getChange(id);
  }

  getMany(userIds: string[]): Promise<User[]> {
    return this.userDb.getMany(userIds);
  }

  delete(id: string): Promise<void> {
    return this.userDb.delete(id);
  }

  search(query: SearchQuery): Promise<SearchResponse<User>> {
    return this.userSearch.search(query);
  }

  searchByAlgolia(query: SearchQuery): Promise<SearchResponse<User>> {
    return this.userSearchByAlgolia.search(query);
  }

  searchPaginationList(query: SearchQuery): PaginationList<User> {
    return makeSearchPaginationList(this.userSearch, query);
  }

  increaseInvitationCount(userId: string): Promise<void> {
    return this.userDb.increase(userId, 'invitationCount', 1);
  }

  increaseInvitationVerifiedCount(userId: string): Promise<void> {
    return this.userDb.increase(userId, 'invitationVerifiedCount', 1);
  }

  async list(request: UserListRequest = {}): Promise<DbListResponse<User>> {
    const query: DbQuery = {
      filters: [],
      sorts: [{ field: 'createdAt', direction: DbSortDirection.Desc }],
      limit: request.size ? +request.size : null,
    };

    if (request.type) {
      query.filters.push({ field: 'type', comparison: '==', value: request.type });
    }

    if (request.email) {
      query.filters.push({ field: 'email', comparison: '==', value: request.email });
    }

    if (request.cursor) {
      query.gt = await this.userDb.getSnapshot(request.cursor);
    }

    return this.userDb.list(query);
  }

  async listGalleries(): Promise<User[]> {
    if (this.galleries) {
      return this.galleries;
    }

    const query: DbQuery = {
      filters: [{ field: 'type', comparison: '==', value: UserType.Gallery }],
      limit: 9999
    };

    const response = await this.userDb.list(query);

    this.galleries = response.items;

    return this.galleries;
  }
  
  async listGalleryNikFirst(): Promise<User[]> {
    if (this.galleries2) {
      return this.galleries2;
    }

    const query: SearchQuery = {
      filters: [
        { field: 'type', comparison: '==', value: UserType.Gallery },
        { field: 'name', logical:'and', comparison: 'text', value: 'Gallery Nik' }
      ],
      sorts: [{field: 'name', direction: DbSortDirection.Asc }],
    };
    const query2: SearchQuery = {
      filters: [
        { field: 'type', comparison: '==', value: UserType.Gallery },
        { field: 'name', logical:'and', comparison: '!=', value: 'Gallery Nik' },
        { field: 'name', logical:'and', comparison: '!=', value: 'Gallery Nik / Gallery the Sky' },
        { field: 'name', logical:'and', comparison: '!=', value: 'Gallery nik/Gallery the sky' }
      ],
      sorts: [{field: 'name', direction: DbSortDirection.Asc }],
      size: 9999
    };

    const response = await this.userSearch.search(query);
    const response2 = await this.userSearch.search(query2);

    this.galleries2 = response.items.concat(response2.items);

    return this.galleries2;
  }

  async listArtists(): Promise<User[]> {
    if (this.artists) {
      return this.artists;
    }

    const query: DbQuery = {
      filters: [{ field: 'type', comparison: '==', value: UserType.Artist }],
      limit: 9999
    };

    const response = await this.userDb.list(query);

    this.artists = response.items;

    return this.artists;
  }

  async listArtistNikFirst(): Promise<User[]> {
    if (this.artists2) {
      return this.artists2;
    }

    const query: SearchQuery = {
      filters: [
        { field: 'type', comparison: '==', value: UserType.Artist },
        { field: 'name', logical:'and', comparison: '==', value: 'NIKPLACE' }
      ],
      sorts: [{field: 'name', direction: DbSortDirection.Asc }],
    };
    const query2: SearchQuery = {
      filters: [
        { field: 'type', comparison: '==', value: UserType.Artist },
        { field: 'name', logical:'and', comparison: '!=', value: 'NIKPLACE' }
      ],
      sorts: [{field: 'name', direction: DbSortDirection.Asc }],
      size: 9999
    };

    const response = await this.userSearch.search(query);
    const response2 = await this.userSearch.search(query2);

    this.artists2 = response.items.concat(response2.items);

    return this.artists2;
  }


  async listRanking(type: UserType, request: DbListRequest = {}): Promise<DbListResponse<User>> {
    const query: DbQuery = {
      filters: [{ field: 'type', comparison: '==', value: type }],
      sorts: [{ field: 'collectCount', direction: DbSortDirection.Desc }],
      limit: request.size ? +request.size : null,
    };

    if (request.cursor) {
      query.gt = await this.userDb.getSnapshot(request.cursor);
    }

    return this.userDb.list(query);
  }

  async listFromBigquery(query: SearchQuery): Promise<SearchResponse<User>> {
    return this.userSearch.search(query);
  }

  listFromDb(query: DbQuery): Promise<DbListResponse<User>> {
    return this.userDb.list(query);
  }

  update(id: string, update: Partial<User>): Promise<void> {
    return this.userDb.update(id, update);
  }

  async getAddress(userId: string): Promise<string> {
    const user = await this.userDb.get(userId);

    if (!user) {
      throw new Error('해당하는 유저가 없습니다');
    }

    return user.klaytnAccount.address;
  }

  findId(name: string, phoneNumber: string): Promise<User> {
    const query: DbQuery = {
      filters: [
        { field: 'name', comparison: '==', value: name },
        { field: 'phoneNumber', comparison: '==', value: phoneNumber },
      ],
      limit: 1,
    };

    return this.userDb.list(query).then((response) => response.items[0]);
  }

  async checkAddress(address: string): Promise<User> {
    const query: DbQuery = {
      filters: [
        { field: 'klaytnAccount.address', comparison: '==', value: address }
      ],
      limit: 1
    };

    const response = await this.userDb.list(query);

    return response.items[0] || null;
  }

  increaseItemCount(id: string): Promise<void> {
    return this.userDb.increase(id, 'itemCount', 1);
  }
}
