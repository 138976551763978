import { BaseEntity } from '../../core/base-entity';
import { ItemPaymentType, Item } from '../item/types';
import { User } from '../../core/user/types';

export interface Order extends BaseEntity {
  userId: string;
  toUserId?: string;
  isToAdmin?: boolean;
  ownerAddress?: string;
  user?: User;
  itemIds?: string[];
  items?: Item[];
  itemTransferResult: OrderItemTransferResult;
  paymentInfos: OrderPaymentInfo[];
  deliveryInfo: OrderDeliveryInfo;
  paymentMethod: OrderPaymentMethod;
  depositorName?: string;
  depositorPhoneNumber?: string;
  totalGas: number;
  type: OrderType;
  status: OrderStatus;
  failureReason?: string;
}

export enum OrderStatus {
  Waiting = 1,
  Completed = 61,
  Canceled = 81,
  Refunded = 82,
  Failed = 91,
}

export const OrderStatusText = {
  [OrderStatus.Waiting]: 'Confirmation of payment',
  [OrderStatus.Canceled]: 'Order Cancellation',
  [OrderStatus.Completed]: 'Order Complete',
  [OrderStatus.Refunded]: 'Order Refunded',
  [OrderStatus.Failed]: 'Order Fail',
};

export const OrderStatusClass = {
  [OrderStatus.Waiting]: 'wait',
  [OrderStatus.Canceled]: 'cancel',
  [OrderStatus.Completed]: 'complete',
  [OrderStatus.Refunded]: 'refunded',
  [OrderStatus.Failed]: 'fail',
};

export interface OrderPaymentInfo {
  paymentType: ItemPaymentType;
  totalAmount: number;
  count: number;
}

export interface OrderDeliveryInfo {
  ordererName: string;
  ordererPhoneNumber: string;
  depositorName: string;
  email: string;
  recipientName: string;
  recipientPhoneNumber1: string;
  recipientPhoneNumber2: string;
  postcode: string;
  address: string;
  addressDetail: string;
  sigunguCode: string;
  dongCode: string;
  bun: string;
  ji: string;
  request: string;
}

export enum OrderPaymentMethod {
  Deposit = 'deposit',
  Nik = 'nik',
  InApp = 'inApp',
  CreditCard = 'creditCard',
  Paypal = 'paypal',
  Inquiry = 'inquiry',
}

export enum OrderType {
  Cart = 'cart',
  Checkout = 'checkout',
  Gift = 'gift',
  Inquiry = 'inquiry',
}

export interface OrderItemTransferResult {
  [itemId: string]: {
    status: OrderItemTransferStatus;
    failureReason?: string;
  };
}

export enum OrderItemTransferStatus {
  Ready = 1,
  NftTransferred = 31,
  NikTransferred = 41,
  Success = 61,
  Failed = 91,
}

export const OrderPaymentMethodText = {
  [OrderPaymentMethod.Deposit]: '무통장',
  [OrderPaymentMethod.Nik]: 'nik',
  [OrderPaymentMethod.InApp]: '인앱',
  [OrderPaymentMethod.Paypal]: '페이팔',
  [OrderPaymentMethod.CreditCard]: '신용카드',
  [OrderPaymentMethod.Inquiry]: '구매처 문의',
};
