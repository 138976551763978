import { BidService } from '../../entities/bid/bid.service';
import { Bid } from '../../entities/bid/types';
import { ItemService } from '../../entities/item/item.service';
import { Item } from '../../entities/item/types';
import { WishService } from '../../entities/wish/wish.service';
import { KlaytnService } from '../../modules/klaytn/klaytn.service';
import {
  KlaytnGetOwnerKip17TokensResponse,
  KlaytnGetOwnerKip17TokensResponseItem,
} from '../../modules/klaytn/types';
import { makeQueryOptions } from '../../modules/klaytn/utils';
import { DbListRequest, DbListResponse, DbQuery, DbSortDirection } from '../db/types';
import { convertUnixtimeToString, makeArrayPaginationList } from '../utils';
import { User } from './types';
import { UserService } from './user.service';
import { PaginationList } from '../types';
import { Wish } from '../../entities/wish/types';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';

export class ApiMyService {
  constructor(
    protected userService: UserService,
    protected itemService: ItemService,
    protected bidService: BidService,
    protected wishService: WishService,
    protected klaytnService: KlaytnService
  ) {}

  get(userId: string): Promise<User> {
    return this.userService.get(userId);
  }

  async update(userId: string, update: Partial<User>): Promise<User> {
    if (!update) {
      throw new Error('no body');
    }

    await this.userService.update(userId, update);

    return await this.userService.get(userId);
  }

  listItems(userId: string, request?: DbListRequest): Promise<DbListResponse<Item>> {
    return this.itemService.listByUserId(userId, request);
  }

  paginationListItems(userId: string): PaginationList<Item> {
    const query: DbQuery = {
      filters: [{ field: 'ownerId', comparison: '==', value: userId }],
      sorts: [{ field: 'lastSoldAt', direction: DbSortDirection.Desc }]
    };

    const observable = from(this.itemService.listFromDb(query)).pipe(
      map(response =>
        response.items.filter((item, index, arr) =>
          arr.findIndex(i => i.groupName === item.groupName) === index
        )
      )
    );

    return makeArrayPaginationList(observable, 12);
  }

  paginationListItems2(userId: string, hasOwnership: boolean): PaginationList<Item> {
    const query: DbQuery = {
      filters: [
        { field: 'ownerId', comparison: '==', value: userId },
        { field: 'hasOwnership', comparison: '==', value: hasOwnership },
      ],
      sorts: [{ field: 'lastSoldAt', direction: DbSortDirection.Desc }]
    };

    const observable = from(this.itemService.listFromDb(query)).pipe(
      map(response =>
        response.items.filter((item, index, arr) =>
          arr.findIndex(i => i.groupName === item.groupName) === index
        )
      )
    );

    return makeArrayPaginationList(observable, 12);
  }

  listItemsOnAuction(userId: string, request?: DbListRequest): Promise<DbListResponse<Item>> {
    return this.itemService.listOnAuctionByUserId(userId, request);
  }

  listOnAuctionByUserIdPaginationList(userId: string, request: DbListRequest = {}): PaginationList<Item> {
    return this.itemService.listOnAuctionByUserIdPaginationList(userId, request);
  }

  listItemsRecentBid(userId: string, request?: DbListRequest): Promise<DbListResponse<Bid>> {
    return this.bidService.listRecentByUserId(userId, request);
  }

  listRecentByUserIdPaginationList(userId: string, request?: DbListRequest): PaginationList<Bid> {
    return this.bidService.listRecentByUserIdPaginationList(userId, request);
  }

  listWished(userId: string, request?: DbListRequest): Promise<DbListResponse<Item>> {
    return this.wishService.wishList(userId, request);
  }

  listWishedPaginationList(userId: string, request?: DbListRequest): PaginationList<Wish> {
    return this.wishService.listWishedPaginationList(userId, request);
  }

  async getNik(userId: string): Promise<number> {
    const address = await this.userService.getAddress(userId);

    return this.klaytnService.getBalance(address);
  }

  async listNfts(
    userId: string,
    request?: DbListRequest
  ): Promise<KlaytnGetOwnerKip17TokensResponse> {
    const address = await this.userService.getAddress(userId);

    const result = await this.klaytnService.getTokenListByOwner(address, makeQueryOptions(request));

    result.items = result.items.map((item) => mapNftItem(item) as any);

    return result;
  }
}

function mapNftItem(item: KlaytnGetOwnerKip17TokensResponseItem) {
  return {
    ...item,
    createdAt: convertUnixtimeToString(item.createdAt),
    updatedAt: convertUnixtimeToString(item.updatedAt),
  };
}
