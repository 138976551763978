import { Pipe, PipeTransform } from '@angular/core';
import { ItemPaymentType } from '../../../../../../src/entities/item/types';
import { RatioQuery } from '../../../../../web/src/app/modules/ntf/state/ratio.query';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'amountMultiRatio'
})
export class AmountMultiRatioPipe implements PipeTransform {
  constructor(private ratioQuery: RatioQuery) {}

  transform(value: number, fromTo: string): Observable<number> {
    if (fromTo === 'nikUsd') {
      return this.ratioQuery.usdNik$.pipe(map((ratio) => ratio * value));
    } else if (fromTo === 'nikKrw') {
      return this.ratioQuery.wonNik$.pipe(map((ratio) => ratio * value));
    } else if (fromTo === 'wonUsd') {
      return this.ratioQuery.wonUsd$.pipe(
        map((ratio) => {
          return parseFloat((value / ratio).toFixed(2));
        })
      );
    } else if (fromTo === 'wonNik') {
      return this.ratioQuery.wonNik$.pipe(
        map((ratio) => {
          return parseFloat((value / ratio).toFixed(2));
        })
      );
    } else if (fromTo === 'usdKrw') {
      return this.ratioQuery.wonUsd$.pipe(map((ratio) => ratio * value));
    } else if (fromTo === 'usdNik') {
      return this.ratioQuery.usdNik$.pipe(
        map((ratio) => {
          return value / ratio;
          return parseFloat((value / ratio).toFixed(2));
        })
      );
    }
  }
}
