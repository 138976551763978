import { Pipe, PipeTransform } from '@angular/core';
import {ItemStatus, ItemStatusText} from "../../../../../../src/entities/item/types";

@Pipe({
  name: 'itemStatusFormat',
})
export class ItemStatusFormatPipe implements PipeTransform {
  transform(status: ItemStatus): string {
    if (!status) {
      return '';
    }
    return ItemStatusText[status];
  }
}
