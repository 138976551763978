import { Pipe, PipeTransform } from '@angular/core';
import { stripTag } from '../../../../../../src/core/utils';

@Pipe({
  name: 'stripTag'
})
export class StripTagPipe implements PipeTransform {

  transform(value: string): string {
    return stripTag(value);
  }
}
