import { Pipe } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe {
  constructor(private sanitizer: DomSanitizer) {
  }

  transform(style): SafeHtml {
    if (!style) {
      return '';
    }

    return this.sanitizer.bypassSecurityTrustHtml(style);
  }
}
