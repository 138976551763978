import { Observable, combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseEntity } from '../../core/base-entity';
import { DbAdapter } from '../../core/db/db.adapter';
import {
  DbQuery,
  DbOptions,
  DbListResponse,
  DbBatchItems,
  DbSortDirection, DbFirestoreListResponse
} from '../../core/db/types';
import * as resolvePath from 'object-resolve-path';
import firebase from 'firebase/app';
import { reverseDirection } from '../../core/db/utils';
import { ColdObservable } from '../../core/types';
import { isEmptyObject } from '../../core/utils';
import firebaseAdapter from './firebase.adapter';

export interface FirestoreDbConfig {
  parentCollectionNames?: string[];
  recordTime?: boolean;
  countFields?: string[];
}

export class FirestoreDbAdapter<E extends BaseEntity> implements DbAdapter<E> {
  protected db = firebaseAdapter.firestore;

  createdAtDescQuery: DbQuery = {
    sorts: [{ field: 'createdAt', direction: DbSortDirection.Desc }],
  };

  private baseLimit = 20;

  constructor(protected collectionName: string, protected config: FirestoreDbConfig = {}) {
    this.config.parentCollectionNames = this.config.parentCollectionNames || [];
    this.config.recordTime = this.config.recordTime !== false;
  }

  async get(id: string, options: DbOptions = {}): Promise<E> {
    const snap = await this.db.doc(this.makePathWithId(id, options.parentIds)).get();

    return this.convertSnapshotToEntity(snap);
  }

  getChange(id: string, options: DbOptions = {}): ColdObservable<E> {
    return this.convertFirestoreDocumentOnSnapshotToObservable(
      this.db.doc(this.makePathWithId(id, options.parentIds))
    ).pipe(map((snap) => this.convertSnapshotToEntity(snap)));
  }

  async getMany(ids: string[], options: DbOptions = {}): Promise<E[]> {
    const promises = [];

    for (const id of ids) {
      promises.push(this.db.doc(this.makePathWithId(id, options.parentIds)).get());
    }

    const snaps = await Promise.all(promises);

    return snaps.map((snap) => this.convertSnapshotToEntity(snap));
  }

  getManyChange(ids: string[], options: DbOptions = {}): ColdObservable<E[]> {
    if (!ids || ids.length === 0) {
      return of([]);
    } else {
      return combineLatest(ids.map((id) => this.getChange(id)));
    }
  }

  async list(query: DbQuery<E> = {}, options: DbOptions = {}): Promise<DbListResponse<E>> {
    const ref: firebase.firestore.Query<firebase.firestore.DocumentData> = this.db.collection(
      this.makePath(options.parentIds)
    );

    if (!query && this.config.recordTime) {
      query = this.createdAtDescQuery;
    }

    const [snaps, totalCount] = await Promise.all([
      this.convertQueryForFirestore(query, ref).get(),
      this.count(query, { parentIds: options.parentIds }),
    ]);

    return this.makeListResponse(
      snaps,
      totalCount,
      Boolean(query.lt),
      query.limit || this.baseLimit
    );
  }

  listChange(query: DbQuery<E> = {}, options: DbOptions = {}): ColdObservable<DbFirestoreListResponse<E>> {
    const ref: firebase.firestore.Query<firebase.firestore.DocumentData> = this.db.collection(
      this.makePath(options.parentIds)
    );

    if (!query && this.config.recordTime) {
      query = this.createdAtDescQuery;
    }

    return combineLatest([
      this.convertFirestoreCollectionOnSnapshotToObservable(
        this.convertQueryForFirestore(query, ref)
      ),
      this.count(query, { parentIds: options.parentIds }),
    ]).pipe(
      map(([snaps, totalCount]) => {
        return this.makeFirestoreListResponse(
          snaps,
          totalCount,
          Boolean(query.lt)
        );
      })
    );
  }

  async count(query: DbQuery = {}, options: DbOptions = {}): Promise<number> {
    const path = this.makeCountPath(options.parentIds);

    const snap = await this.db.doc(path).get();

    if (snap.exists) {
      const data = snap.data();

      if (query.filters && query.filters[0]) {
        return data[`${query.filters[0].field}=${query.filters[0].value}`] || 0;
      }

      return data.total;
    }

    return 0;
  }

  async countIncrease(field: string, increase: number, options: DbOptions = {}): Promise<void> {
    const path = this.makeCountPath(options.parentIds);

    await this.db.doc(path).update({ [field]: firebaseAdapter.FieldValue.increment(increase) });
  }

  countDecrease(field: string, decrease: number, options: DbOptions = {}): Promise<void> {
    return this.countIncrease(field, decrease * -1, options);
  }

  async add(entity: Partial<E>, options: DbOptions = {}): Promise<E> {
    const path = this.makePath(options.parentIds);

    let id = entity.id;

    delete entity.id;

    if (!id) {
      id = this.db.collection(path).doc().id;
    }

    await this.addWithCount(id, entity, path);

    return this.get(id, { parentIds: options.parentIds });
  }

  async update(id: string, update: Partial<E>, options: DbOptions = {}): Promise<void> {
    if (this.config.countFields) {
      return this.updateWithCount(id, update, this.makePath(options.parentIds));
    }

    await this.db
      .doc(this.makePathWithId(id, options.parentIds))
      .update(
        this.config.recordTime ? { ...update, modifiedAt: firebaseAdapter.FieldValue.serverTimestamp() } : update
      );
  }

  increase(id: string, field: keyof E, increase: number, options: DbOptions = {}): Promise<void> {
    return this.update(
      id,
      { [field]: firebaseAdapter.FieldValue.increment(increase) } as any,
      options
    );
  }

  decrease(id: string, field: keyof E, decrease: number, options: DbOptions = {}): Promise<void> {
    return this.increase(id, field, decrease * -1, options);
  }

  increaseMultiFields(id: string, fields: (keyof E)[], increase: number, options: DbOptions = {}): Promise<void> {
    let update = {};

    for (const field of fields) {
      update = { ...update, [field]: firebaseAdapter.FieldValue.increment(increase) };
    }

    return this.update(
      id,
      update,
      options
    );
  }

  decreaseMultiFields(id: string, fields: (keyof E)[], decrease: number, options: DbOptions = {}): Promise<void> {
    return this.increaseMultiFields(id, fields, decrease * -1, options);
  }

  async upsert(id: string, entity: Partial<E>, options: DbOptions = {}): Promise<E> {
    const path = this.makePath(options.parentIds);
    const pathWithId = `${path}/${id}`;

    const snap = await this.db.doc(pathWithId).get();

    if (snap.exists) {
      await this.update(id, entity, options);
      return this.get(id, options);
    } else {
      return this.add({ ...entity, id }, options);
    }
  }

  delete(id: string, options: DbOptions = {}): Promise<void> {
    const path = this.makePath(options.parentIds);

    return this.deleteWithCount(id, path);
  }

  async batch(items: DbBatchItems<E>, options: DbOptions = {}): Promise<any> {
    if (items.set) {
      for (let i = 0; i < items.set.length; i += 500) {
        const batch = this.db.batch();

        for (let j = i; j < Math.min(i + 500, items.set.length); j++) {
          const item = items.set[j];

          batch.set(this.db.doc(this.makePathWithId(item.id, options.parentIds)), item.data);
        }

        await batch.commit();
      }
    }

    if (items.update) {
      for (let i = 0; i < items.update.length; i += 500) {
        const batch = this.db.batch();

        for (let j = i; j < Math.min(i + 500, items.update.length); j++) {
          const item = items.update[j];

          batch.update(this.db.doc(this.makePathWithId(item.id, options.parentIds)), item.data);
        }

        await batch.commit();
      }
    }

    if (items.delete) {
      for (let i = 0; i < items.delete.length; i += 500) {
        const batch = this.db.batch();

        for (let j = i; j < Math.min(i + 500, items.delete.length); j++) {
          const item = items.delete[j];

          batch.delete(this.db.doc(this.makePathWithId(item.id, options.parentIds)));
        }

        await batch.commit();
      }
    }
  }

  transaction(
    callback: (
      db: firebase.firestore.Firestore
    ) => (transaction: firebase.firestore.Transaction) => any
  ): Promise<any> {
    return this.db.runTransaction(callback(this.db));
  }

  createId(options: DbOptions = {}): string {
    return this.db.collection(this.makePath(options.parentIds)).doc().id;
  }

  async getSnapshot(
    id: string,
    options: DbOptions = {}
  ): Promise<firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>> {
    return this.db.doc(this.makePathWithId(id, options.parentIds)).get();
  }

  private convertSnapshotToEntity(
    snap: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
  ) {
    return snap.exists
      ? ({ ...this.convertFirestoreToEntity(snap.data()), id: snap.id } as E)
      : null;
  }

  private convertFirestoreToEntity(documentData: firebase.firestore.DocumentData): any {
    if (documentData.toDate) {
      return documentData.toDate();
    } else if (Array.isArray(documentData)) {
      const node = [];

      for (const data of documentData) {
        node.push(this.convertFirestoreToEntity(data));
      }

      return node;
    } else if (documentData === null) {
      return null;
    } else if (typeof documentData === 'object') {
      const data = { ...documentData };

      if (Object.keys(data).length > 0) {
        for (const key in data) {
          if (data.hasOwnProperty(key) && data[key]) {
            data[key] = this.convertFirestoreToEntity(data[key]);
          }
        }

        return data;
      }
    } else {
      return documentData;
    }
  }

  private makePath(parentIds: string[] = []): string {
    const namesLength = this.config.parentCollectionNames.length;

    if (!namesLength) {
      return this.collectionName;
    }

    if (namesLength !== parentIds.length) {
      throw new Error('Parent collection 이름과 id의 갯수가 맞지 않습니다.');
    }

    let path = '';
    for (let i = 0; i < namesLength; i++) {
      if (i !== 0) {
        path += '/';
      }

      path += `${this.config.parentCollectionNames[i]}/${parentIds[i]}`;
    }

    return `${path}/${this.collectionName}`;
  }

  private makePathWithId(id: string, parentIds: string[] = []): string {
    return `${this.makePath(parentIds)}/${id}`;
  }

  private makeCountPath(parentIds: string[] = []): string {
    let path = 'counts/';

    if (this.config.parentCollectionNames) {
      path += this.makePath(parentIds);
    } else {
      path += this.collectionName;
    }

    return path;
  }

  private convertQueryForFirestore(
    query: DbQuery<E> = {},
    ref: firebase.firestore.Query<firebase.firestore.DocumentData>
  ): firebase.firestore.Query<firebase.firestore.DocumentData> {
    const reverse = Boolean(query.lt);

    if (query.filters && query.filters.length) {
      for (const filter of query.filters) {
        if (filter.logical === 'or') {
          throw new Error('Firestore에서는 "or"로 filtering 할 수 없습니다.');
        }

        if (filter.comparison === '!=' || filter.comparison === 'text') {
          throw new Error('Firestore에서는 "!="와 "text"로 filtering 할 수 없습니다.');
        }

        ref = ref.where(filter.field, filter.comparison, filter.value);
      }
    }

    if (query.sorts && query.sorts.length) {
      for (const sort of query.sorts) {
        ref = ref.orderBy(sort.field, reverse ? reverseDirection(sort.direction) : sort.direction);
      }
    }

    if (query.lt && query.gt) {
      throw new Error('less than과 greater than은 함께 사용할 수 없습니다.');
    }

    if (query.lt) {
      ref = ref.startAfter(query.lt);
    }

    if (query.gt) {
      ref = ref.startAfter(query.gt);
    }

    if (query.limit > 0) {
      ref = ref.limit(query.limit);
    }

    return ref;
  }

  private makeListResponse(
    snaps: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>,
    totalCount: number,
    reverse = false,
    limit: number
  ): DbListResponse<E> {
    const docs = snaps.docs.map((snap) => this.convertSnapshotToEntity(snap));
    const count = snaps.size;
    const lastSnap = snaps.docs[count - 1];

    return {
      totalCount,
      count,
      size: limit,
      cursor: lastSnap ? lastSnap.id : null,
      items: reverse ? docs.reverse() : docs,
      lastDoc: snaps.docs[reverse ? 0 : snaps.size - 1]
    };
  }

  private makeFirestoreListResponse(
    snaps: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>,
    totalCount: number,
    reverse = false
  ): DbFirestoreListResponse<E> {
    const docs = snaps.docs.map(snap => this.convertSnapshotToEntity(snap));
    return {
      items: reverse ? docs.reverse() : docs,
      totalCount,
      count: snaps.size,
      firstDoc: snaps.docs[reverse ? snaps.size - 1 : 0],
      lastDoc: snaps.docs[reverse ? 0 : snaps.size - 1]
    };
  }

  private convertFirestoreDocumentOnSnapshotToObservable(
    ref: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
  ): ColdObservable<firebase.firestore.DocumentSnapshot> {
    return new Observable((subscriber) => {
      ref.onSnapshot(
        (next) => subscriber.next(next),
        (err) => subscriber.error(err),
        () => subscriber.complete()
      );
    });
  }

  private convertFirestoreCollectionOnSnapshotToObservable(
    ref: firebase.firestore.Query<firebase.firestore.DocumentData>
  ): Observable<firebase.firestore.QuerySnapshot> {
    return new Observable((subscriber) => {
      ref.onSnapshot(
        (next) => subscriber.next(next),
        (err) => subscriber.error(err),
        () => subscriber.complete()
      );
    });
  }

  private async addWithCount(id: string, entity: Partial<E>, path: string): Promise<void> {
    const pathWithId = `${path}/${id}`;

    await this.db.runTransaction(async (transaction) => {
      const countDoc = {
        total: firebaseAdapter.FieldValue.increment(1),
      };

      if (this.config.countFields) {
        for (const countField of this.config.countFields) {
          const value = resolvePath(entity, countField);

          if (value) {
            if (Array.isArray(value)) {
              for (const v of value) {
                countDoc[`${countField}=${v}`] = firebaseAdapter.FieldValue.increment(1);
              }
            } else {
              countDoc[`${countField}=${value}`] = firebaseAdapter.FieldValue.increment(1);
            }
          }
        }
      }

      const promises = [
        transaction.set(
          this.db.doc(pathWithId),
          this.config.recordTime
            ? {
                ...entity,
                createdAt: firebaseAdapter.FieldValue.serverTimestamp(),
                modifiedAt: firebaseAdapter.FieldValue.serverTimestamp(),
              }
            : entity
        ),
        transaction.set(this.db.doc(`counts/${path}`), countDoc, { merge: true }),
      ];

      await Promise.all(promises);
    });
  }

  private updateWithCount(id: string, update: Partial<E>, path: string): Promise<void> {
    return this.db.runTransaction(async (transaction) => {
      const countDoc = {};

      const ref = this.db.doc(`${path}/${id}`);

      if (this.config.countFields) {
        const snap = await transaction.get(ref);

        const entity = snap.data();

        for (const countField of this.config.countFields) {
          const value = resolvePath(entity, countField);
          const updateValue = update[countField] || resolvePath(update, countField);

          if (value && updateValue) {
            if (Array.isArray(value)) {
              for (const v of value) {
                if (updateValue.indexOf(v) === -1) {
                  countDoc[`${countField}=${v}`] = firebaseAdapter.FieldValue.increment(-1);
                }
              }
            } else {
              if (value !== updateValue) {
                countDoc[`${countField}=${value}`] = firebaseAdapter.FieldValue.increment(-1);
              }
            }
          } else if (value && updateValue === null) {
            countDoc[`${countField}=${value}`] = firebaseAdapter.FieldValue.increment(-1);
          }

          if (updateValue) {
            if (Array.isArray(updateValue)) {
              for (const v of updateValue) {
                if (!value || value.indexOf(v) === -1) {
                  countDoc[`${countField}=${v}`] = firebaseAdapter.FieldValue.increment(1);
                }
              }
            } else {
              if (value !== updateValue) {
                countDoc[`${countField}=${updateValue}`] = firebaseAdapter.FieldValue.increment(1);
              }
            }
          }
        }

        if (!isEmptyObject(countDoc)) {
          transaction.set(this.db.doc(`counts/${path}`), countDoc, { merge: true });
        }
      }

      transaction.update(
        ref,
        this.config.recordTime ? { ...update, modifiedAt: firebaseAdapter.FieldValue.serverTimestamp() } : update
      );
    });
  }

  private deleteWithCount(id: string, path: string): Promise<void> {
    return this.db.runTransaction(async (transaction) => {
      const countDoc = {
        total: firebaseAdapter.FieldValue.increment(-1),
      };

      const ref = this.db.doc(`${path}/${id}`);

      if (this.config.countFields) {
        const snap = await transaction.get(ref);

        const entity = snap.data();

        for (const countField of this.config.countFields) {
          const value = resolvePath(entity, countField);

          if (value) {
            if (Array.isArray(value)) {
              for (const v of value) {
                countDoc[`${countField}=${v}`] = firebaseAdapter.FieldValue.increment(-1);
              }
            } else {
              countDoc[`${countField}=${value}`] = firebaseAdapter.FieldValue.increment(-1);
            }
          }
        }
      }

      await Promise.all([
        transaction.delete(ref),
        transaction.set(this.db.doc(`counts/${path}`), countDoc, { merge: true }),
      ]);
    });
  }
}
